import "./common-control.scss";
import { AnimatePresence, motion } from "framer-motion";
import { StrategyVersion } from "../../../../interfaces/strategyInterfaces/Strategy";
import { CommonDashboardSelectButton } from "../CommonStrategySelectButton";
import { DateInterval } from "../../../../interfaces/DateInterval";
import { isEqual } from "../../../../utils/ObjectEqualsUtil";
import { Drawer } from "@mantine/core";
import { BacktestIntervalPicker } from "../CommonIntervalPicker";
import { useContext, useState } from "react";
import { IsBacktestingContext } from "../../../../pages/backtester-page/BacktesterPage";
import { BacktestButton } from "../../../buttons/backtest-button/BacktestButton";

import {
  GetBacktestStageRequestContext,
  SetBacktestStageRequestContext,
} from "../../../../pages/backtester-page/context/BacktestStageRequestContext";
interface Props {
  activeTheme: string;
  showBacktestButton: boolean;
  attachedStrategy?: StrategyVersion;
  dateInterval: DateInterval;
  YTDInterval: DateInterval;
  formatDateIntervalToLabel: (_dateInterval: DateInterval) => string;
  getCurrencyPreviewName: () => string;
  setNewDateInterval: (_dateInterval: DateInterval) => void;
}
export function BacktesterControl(props: React.PropsWithChildren<Props>) {
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);

  const backtestStageRequest = useContext(GetBacktestStageRequestContext);
  const setBacktestStageRequest = useContext(SetBacktestStageRequestContext);
  const isRunning = useContext(IsBacktestingContext);

  return (
    <AnimatePresence>
      <div className="common-control-column">
        <Drawer
          position="right"
          size={600}
          opened={drawerOpened}
          onClose={() => setDrawerOpened(false)}
        >
          <BacktestIntervalPicker
            previewCurrencyName={props.getCurrencyPreviewName()}
            formatDateIntervalToLabel={props.formatDateIntervalToLabel}
            dateInterval={props.dateInterval}
            setNewDateInterval={(newDateInterval) => {
              props.setNewDateInterval(newDateInterval);
              setDrawerOpened(false);
            }}
            activeTheme={props.activeTheme}
          />
        </Drawer>
        <div className="backtester-control-column-switch">
          <label
            className={"common-control-column-header"}
            onClick={() => {
              setBacktestStageRequest({
                ...backtestStageRequest,
                tournamentMode: false,
              });
            }}
          >
            Backtester
          </label>
        </div>

        {props.attachedStrategy && props.showBacktestButton && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
            className="backtester-control-center"
          >
            <BacktestButton
              isRunning={false}
              activeTheme={props.activeTheme}
              currencyNamesToBeTested={
                props.attachedStrategy.included_currencies
              }
            />
          </motion.div>
        )}

        <CommonDashboardSelectButton
          label={
            isEqual(props.dateInterval, props.YTDInterval)
              ? "Year to date"
              : props.formatDateIntervalToLabel(props.dateInterval)
          }
          labelClassName="interval-button-label"
          onClick={() => {
            setDrawerOpened(!drawerOpened);
          }}
          disabled={isRunning}
        />
      </div>
    </AnimatePresence>
  );
}
