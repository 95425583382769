import "./ratings.scss";
import { StrategyRating } from "../../../services/BlueprintService";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { useMemo } from "react";
import { getTheme } from "../../../utils/themeUtil";
import { GiBearFace, GiBull } from "react-icons/gi";
import { MixedMarketIcon } from "../../../style/custom-assets/MixedMarket";
import { Tooltip } from "@mantine/core";
import CountUp from "react-countup";
import { ImEyeMinus } from "react-icons/im";

interface Props {
  activeTheme: string;
  rating: StrategyRating | undefined | null;
  displayMode?: RatingsViewMode;
  iconSize?: number;
  style?: React.CSSProperties;
}
export function RatingRow(props: React.PropsWithChildren<Props>) {
  const displayMode = props.displayMode || "circle";
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);
  const iconSize = props.iconSize ?? 16;
  const strokeWidth = 6;
  const decimals = 2;

  const bearRating =
    props.rating?.overall_rating["-99"] &&
    props.rating?.overall_rating?.["-99"] * 100;
  const mixedRating =
    props.rating?.overall_rating["0"] &&
    props.rating?.overall_rating?.["0"] * 100;
  const bullRating =
    props.rating?.overall_rating["99"] &&
    props.rating?.overall_rating?.["99"] * 100;

  const bearTooltip = (
    <label>
      Bear market rating: <strong>{bearRating?.toFixed(decimals)}</strong>
    </label>
  );

  const mixedTooltip = (
    <label>
      Mixed market rating: <strong>{mixedRating?.toFixed(decimals)}</strong>
    </label>
  );

  const bullTooltip = (
    <label>
      Bull market rating: <strong>{bullRating?.toFixed(decimals)}</strong>
    </label>
  );

  return (
    <div className="ratings-container" style={props.style}>
      {props.rating === null ||
      props.rating === undefined ||
      !mixedRating ||
      !bearRating ||
      !bullRating ? (
        <Tooltip
          label="This strategy has not been rated yet"
          position="right"
          withArrow
        >
          <label
            className="dimmed-label"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <ImEyeMinus />
            Unrated
          </label>
        </Tooltip>
      ) : (
        <>
          {displayMode === "circle" && (
            <>
              {bearRating && (
                <CircularProgressbarWithChildren
                  className="rating-circle"
                  value={bearRating}
                  strokeWidth={strokeWidth}
                  styles={buildStyles({
                    strokeLinecap: theme.card,
                    trailColor: "transparent",
                    pathColor: theme.sellOrderStrokeHover,
                  })}
                  children={
                    <Tooltip
                      withArrow
                      position="right"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      label={bearTooltip}
                    >
                      <GiBearFace
                        size={iconSize}
                        color={theme.sellOrderStrokeHover}
                      />
                    </Tooltip>
                  }
                />
              )}

              {mixedRating && (
                <CircularProgressbarWithChildren
                  className="rating-circle"
                  value={mixedRating}
                  strokeWidth={strokeWidth}
                  styles={buildStyles({
                    strokeLinecap: theme.card,
                    trailColor: "transparent",
                    pathColor: theme.mediumWarningColor,
                  })}
                  children={
                    <Tooltip
                      withArrow
                      position="right"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      label={mixedTooltip}
                    >
                      <MixedMarketIcon
                        size={iconSize * 1.5}
                        color={theme.mediumWarningColor}
                      />
                    </Tooltip>
                  }
                />
              )}
              {bullRating && (
                <CircularProgressbarWithChildren
                  className="rating-circle"
                  value={bullRating}
                  strokeWidth={strokeWidth}
                  styles={buildStyles({
                    strokeLinecap: theme.card,
                    trailColor: "transparent",
                    pathColor: theme.buyOrderStrokeHover,
                  })}
                  children={
                    <Tooltip
                      withArrow
                      position="right"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      label={bullTooltip}
                    >
                      <GiBull
                        size={iconSize}
                        color={theme.buyOrderStrokeHover}
                      />
                    </Tooltip>
                  }
                />
              )}
            </>
          )}
          {(displayMode === "simple" || displayMode === "simple-count-up") && (
            <>
              {bearRating && (
                <Tooltip withArrow position="right" label={bearTooltip}>
                  {displayMode === "simple" ? (
                    <label className="rating-simple bear">
                      {bearRating.toFixed(0)}
                    </label>
                  ) : (
                    <CountUp
                      className="rating-simple bear"
                      start={0}
                      end={bearRating}
                      decimal="."
                      decimals={0}
                      duration={1}
                    />
                  )}
                </Tooltip>
              )}
              {mixedRating && (
                <Tooltip withArrow position="right" label={mixedTooltip}>
                  {displayMode === "simple" ? (
                    <label className="rating-simple mixed">
                      {mixedRating.toFixed(0)}
                    </label>
                  ) : (
                    <CountUp
                      className="rating-simple mixed"
                      start={0}
                      end={mixedRating}
                      decimal="."
                      decimals={0}
                      duration={1}
                    />
                  )}
                </Tooltip>
              )}
              {bullRating && (
                <Tooltip withArrow position="right" label={bullTooltip}>
                  {displayMode === "simple" ? (
                    <label className="rating-simple bull">
                      {bullRating.toFixed(0)}
                    </label>
                  ) : (
                    <CountUp
                      className="rating-simple bull"
                      start={0}
                      end={bullRating}
                      decimal="."
                      decimals={0}
                      duration={1}
                    />
                  )}
                </Tooltip>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export type RatingsViewMode = "circle" | "bar" | "simple" | "simple-count-up";
