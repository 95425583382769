import { StrategyVersion } from "../../interfaces/strategyInterfaces/Strategy";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { UserLimitationsContext } from "../../App";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toUTCTimestring } from "../../utils/FormattingUtils";
import { useAuth0 } from "@auth0/auth0-react";
import { StrategyManagerService } from "../../services/StrategyManagerService";
import { useQuery } from "react-query";
import { Modal, Popover } from "@mantine/core";
import { motion } from "framer-motion";
import { CommonVersionsSelect } from "../common/common-strategy-select/CommonVersionsSelect";
import { StrategyBodyPopup } from "../common/strategy/StrategyBodyPopup";
import { Currency } from "../../interfaces/Currency";
import { HiSave, HiSaveAs } from "react-icons/hi";
import { GiBackwardTime } from "react-icons/gi";
import { ImUndo } from "react-icons/im";
import {
  SelectedCaseContext,
  SetSelectedCaseContext,
  SetStrategyControlModalModeContext,
  StrategyControlModalModeContext,
} from "../../pages/strategy-page/StrategyPage";
import { StrategyVersionIcon } from "../common/identicon/StrategyVersionIcon";
import { RiExchangeBoxLine } from "react-icons/ri";
import { OrderProfileEditor } from "../common/order-profiles/OrderProfileEditor";

interface Props {
  activeTheme: string;
  strategy: StrategyVersion;
  updateable: boolean;
  updateLoading: boolean;
  updateStrategy: (overwrite_version_id?: string) => Promise<void>;
  restoreStrategy: () => void;
  addCase: (caseName: string) => void;
  deleteCase: (caseName: string) => void;
  availableCurrencies: Currency[];
  tradingCurrencies: Currency[];
}
export function StrategyControls(props: React.PropsWithChildren<Props>) {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const limitation = useContext(UserLimitationsContext);
  const selectedCase = useContext(SelectedCaseContext);
  const setSelectedCase = useContext(SetSelectedCaseContext);
  const strategyControlModalMode = useContext(StrategyControlModalModeContext);
  const setStrategyControlModalMode = useContext(SetStrategyControlModalModeContext);
  const iconSize = 20;


  const toBacktest = useCallback(() => {
    navigate(`/backtester`, {
      state: {
        strategyId: props.strategy.strategy_id,
        versionId: props.strategy.version_id,
        currencies: props.strategy.header.currencies,
        load: true,
      },
    });
  }, [
    navigate,
    props.strategy.strategy_id,
    props.strategy.version_id,
    props.strategy.header.currencies,
  ]);

  const fetchUserStrategies = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    // delay loading to prevent flickering
    const response = await StrategyManagerService.blueprint.getUserStrategies(
      token
    );
    return response.data;
  }, [getAccessTokenSilently]);

  const fetchLiveUserStrategies = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const response = await StrategyManagerService.blueprint.getLiveStrategies(
      token
    );
    return response.data;
  }, [getAccessTokenSilently]);
  const [popOverOpened, setPopOverOpened] = useState(false);

  const strategiesQuery = useQuery(
    ["Strategies", popOverOpened],
    fetchUserStrategies,
    {
      keepPreviousData: true,
      cacheTime: 60000,
      staleTime: 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const liveStrategiesQuery = useQuery(
    ["LiveStrategies", popOverOpened],
    fetchLiveUserStrategies,
    {
      keepPreviousData: true,
      cacheTime: 60000,
      staleTime: 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const strategyVersions =
    strategiesQuery.data?.payload?.[props.strategy.strategy_id];

  const liveVersionIds =
    liveStrategiesQuery.data?.payload?.map((version) => version.version_id) ??
    [];

  const liveStrategyIds = liveStrategiesQuery.data?.payload?.map(
    (strategy) => strategy.strategy_id
  );

  const [selectedVersionId, setSelectedVersionId] = useState<
    string | undefined
  >(undefined);
  const [casePopupMode, setCasePopupMode] = useState<"list" | "edit">("edit");
  const [casePopupOpened, setCasePopupOpened] = useState(false);
  const [versionOverwritePopupOpened, setVersionOverwritePopupOpened] =
    useState(false);

  const addCase = useCallback(
    (caseName: string) => {
      props.addCase(caseName);
      setCasePopupMode("edit");
    },
    [props.addCase, setCasePopupMode]
  );

  useEffect(() => {
    if (!selectedCase && casePopupMode === "edit") {
      setCasePopupMode("list");
    }
  }, [selectedCase, casePopupMode, setCasePopupMode]);

  return (
    <div className="strategy-controls-container">
      <div className="strategy-info-container">
        <StrategyBodyPopup
          versionPopoverChild={
            <Popover
              opened={popOverOpened}
              onClose={() => setPopOverOpened(false)}
              position="left"
              placement="end"
              classNames={{
                popover: "versions-popup-container",
                body: "versions-popup-body",
                wrapper: "versions-popup-wrapper",
                inner: "versions-popup-inner",
                arrow: "versions-popup-arrow",
                root: "versions-popup-root",
                target: "versions-popup-target",
              }}
              withArrow
              target={
                <CommonButton
                  onClick={() => setPopOverOpened(!popOverOpened)}
                  activeTheme={props.activeTheme}
                  keepWidth
                  label={
                    <div className="common-button-column">
                      <label className="strategy-name-label">
                        {props.strategy.name}
                      </label>
                      <label className="dimmed-label">
                        From: {toUTCTimestring(props.strategy.created_on, "1h")}
                      </label>
                    </div>
                  }
                  leftIcon={
                    <StrategyVersionIcon
                      activeTheme={props.activeTheme}
                      included_currencies={props.strategy.header.currencies}
                      strategy_id={props.strategy.strategy_id}
                      version_id={props.strategy.version_id}
                      disableMenu
                      hideLabel
                      size={40}
                    />
                  }
                  ellipsis
                />
              }
            >
              <motion.div className="versions">
                {strategyVersions && (
                  <CommonVersionsSelect
                    activeTheme={props.activeTheme}
                    versionItems={strategyVersions}
                    strategyId={props.strategy.strategy_id}
                    traderType={{ traderType: "papertrader" }}
                    selectedVersionId={selectedVersionId}
                    setSelectedVersionId={setSelectedVersionId}
                    refetch={strategiesQuery.refetch}
                    livetradingVersionIds={liveVersionIds}
                    attachStrategy={() => {}}
                    strategyName={props.strategy.name}
                    strategyIsLive={
                      liveStrategyIds?.includes(props.strategy.strategy_id) ??
                      false
                    }
                    limitations={limitation}
                    attachedVersionId={props.strategy.version_id}
                    strategyManagerMenu
                  />
                )}
              </motion.div>
            </Popover>
          }
          hideStrategyName
          activeTheme={props.activeTheme}
          opened={casePopupOpened}
          onClose={() => {
            return;
          }}
          strategy={props.strategy}
          goToCase={() => setCasePopupMode("edit")}
          popupMode={casePopupMode}
          deleteCase={(_caseName) => {
            props.deleteCase(_caseName);
            setCasePopupMode("list");
          }}
          selectCase={setSelectedCase}
          addCase={addCase}
          mainClick={() => {
            setCasePopupMode(selectedCase ? "edit" : "list");
            setCasePopupOpened(
              casePopupMode !== "edit" ? true : !casePopupOpened
            );
          }}
          listCasesClick={() => {
            if (casePopupMode !== "list") {
              setCasePopupMode("list");
              setCasePopupOpened(true);
              return;
            }
            setCasePopupMode("list");
            setCasePopupOpened(!casePopupOpened);
          }}
          handleClose={() => {
            setCasePopupOpened(false);
          }}
          availableCurrencies={props.availableCurrencies}
          handleDelete={() => setCasePopupOpened(false)} // TODO: implement delete case
          newCaseClick={() => {
            setCasePopupOpened(false);
          }}
          selectedCase={selectedCase}
        />
      </div>

      <div className="utility-buttons-container">
        <CommonButton
          activeTheme={props.activeTheme}
          label="Order Profiles"
          compact
          leftIcon={<RiExchangeBoxLine size={22} />}
          onClick={() => setStrategyControlModalMode("Order profiles")}
        />
      </div>

      <Modal
        opened={strategyControlModalMode !== undefined}
        onClose={() => setStrategyControlModalMode(undefined)}
        size={"xl"}
        centered
        zIndex={1000}
        title={<label>{strategyControlModalMode}</label>}
      >
        {strategyControlModalMode === "Order profiles" && (
          <OrderProfileEditor activeTheme={props.activeTheme} />
        )}
      </Modal>

      {limitation && (
        <div className="strategy-save-controls">
          {props.updateable ? (
            <Popover
              opened={versionOverwritePopupOpened}
              position="bottom"
              placement="start"
              classNames={{
                body: "strategy-control-flip-button-popover-body",
                wrapper: "strategy-control-flip-button-popover-wrapper",
                inner: "strategy-control-flip-button-popover-inner",
              }}
              onClose={() => setVersionOverwritePopupOpened(false)}
              transitionDuration={100}
              target={
                <div className="strategy-button-column">
                  {limitation &&
                    strategyVersions &&
                    strategyVersions.length < limitation.strategy_versions && (
                      <CommonButton
                        activeTheme={props.activeTheme}
                        label="Save"
                        disabled={props.tradingCurrencies.length === 0}
                        onClick={props.updateStrategy}
                        loading={props.updateLoading}
                        borderTheme="blue-accent"
                        customLoadingText="Saving"
                        compact={!props.updateLoading}
                        leftIcon={<HiSave size={iconSize} />}
                      />
                    )}

                  {strategyVersions &&
                    strategyVersions.length > 1 &&
                    !props.updateLoading && (
                      <CommonButton
                        disabled={
                          props.updateLoading ||
                          !props.updateable ||
                          props.tradingCurrencies.length === 0
                        }
                        onClick={() =>
                          setVersionOverwritePopupOpened(
                            !versionOverwritePopupOpened
                          )
                        }
                        compact
                        borderTheme="blue-accent"
                        activeTheme={props.activeTheme}
                        leftIcon={<HiSaveAs size={iconSize} />}
                        label="Overwrite"
                      />
                    )}
                  {!props.updateLoading && (
                    <CommonButton
                      borderTheme="gray-accent"
                      activeTheme={props.activeTheme}
                      label="Restore"
                      onClick={props.restoreStrategy}
                      compact
                      disabled={props.updateLoading}
                      leftIcon={<ImUndo size={iconSize} />}
                    />
                  )}
                </div>
              }
            >
              <>
                {strategyVersions && !props.updateLoading && (
                  <CommonVersionsSelect
                    activeTheme={props.activeTheme}
                    versionItems={strategyVersions}
                    strategyId={props.strategy.strategy_id}
                    traderType={{ traderType: "papertrader" }}
                    selectedVersionId={selectedVersionId}
                    setSelectedVersionId={setSelectedVersionId}
                    refetch={strategiesQuery.refetch}
                    livetradingVersionIds={liveVersionIds}
                    attachStrategy={() => {}}
                    strategyName={props.strategy.name}
                    strategyIsLive={
                      liveStrategyIds?.includes(props.strategy.strategy_id) ??
                      false
                    }
                    limitations={limitation}
                    attachedVersionId={props.strategy.version_id}
                    updateStrategy={props.updateStrategy}
                    strategyManagerMenu
                    closePopup={() => setVersionOverwritePopupOpened(false)}
                  />
                )}
              </>
            </Popover>
          ) : (
            <CommonButton
              activeTheme={props.activeTheme}
              disabled={props.strategy.body.cases.length === 0}
              label="Simulate"
              id="simulate-button"
              borderTheme="green-accent"
              onClick={toBacktest}
              leftIcon={<GiBackwardTime size={30} />}
            />
          )}
        </div>
      )}
    </div>
  );
}
