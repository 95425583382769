import { IChartApi, LineSeries, Time } from "lightweight-charts";
import { IOHLCData } from "../../financial-chart/iOHLCData";
import { BBAND } from "../../../../interfaces/tw-chart/TWChartSettings";
import { bb } from "indicatorts"; // Ensure this function exists
import { CustomChartAddon, LabelColorMap } from "../TWChart";

export function addBBSeries(
  chart: IChartApi,
  data: IOHLCData[],
  indicatorConfig: BBAND,
  theme: any = null,
  paneIdx: number = 0
): CustomChartAddon {
  // Create two series: one for Aroon Up and one for Aroon Down.

  const bbMiddleSeries = chart.addSeries(
    LineSeries,
    { priceScaleId: "right" },
    paneIdx
  );
  const bbUpperSeries = chart.addSeries(
    LineSeries,
    { priceScaleId: "right" },
    paneIdx
  );
  const bbLowerSeries = chart.addSeries(
    LineSeries,
    { priceScaleId: "right" },
    paneIdx
  );

  // Calculate Bollinger Bands from the closing prices using the provided config.
  // It is assumed that the bb() function returns an array of objects
  // with properties: { middle: number, upper: number, lower: number }
  const bbData = bb(
    data.map((item) => item.close),
    indicatorConfig.config
  );
  bbUpperSeries.setData(
    bbData.upper.map((val, index) => ({
      time: (data[index].ts / 1000) as Time,
      value: val,
    }))
  );

  bbMiddleSeries.setData(
    bbData.middle.map((val, index) => ({
      time: (data[index].ts / 1000) as Time,
      value: val,
    }))
  );
  bbLowerSeries.setData(
    bbData.lower.map((val, index) => ({
      time: (data[index].ts / 1000) as Time,
      value: val,
    }))
  );

  // Apply styling options to each series.
  bbMiddleSeries.applyOptions({
    color: indicatorConfig.middle || theme.text,
    lineWidth: 1,
    priceLineVisible: false,
  });

  bbUpperSeries.applyOptions({
    color: indicatorConfig.upper || theme.text,
    lineWidth: 1,
    priceLineVisible: false,
  });

  bbLowerSeries.applyOptions({
    color: indicatorConfig.lower || theme.text,
    lineWidth: 1,
    priceLineVisible: false,
  });

  const upperLabel = `BBAND_UPPER`;
  const middleLabel = `BBAND_MIDDLE`;
  const lowerLabel = `BBAND_LOWER`;

  const colorMap: LabelColorMap = {
    [upperLabel]: indicatorConfig.middle || theme.text,
    [middleLabel]: indicatorConfig.upper || theme.text,
    [lowerLabel]: indicatorConfig.lower || theme.text,
  };

  return {
    chartData: {
      [upperLabel]: bbMiddleSeries,
      [middleLabel]: bbUpperSeries,
      [lowerLabel]: bbLowerSeries,
    },
    colorMap: colorMap,
  };
}
