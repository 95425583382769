import "./code-fragments-overview.scss";
import { ApplicationIndicators } from "../../../../interfaces/Indicators";
import { motion } from "framer-motion";
import { FragmentType } from "../../../../utils/ParseCriteriaUtil";
import { CriteriaFragment } from "../../criteria-fragments/CriteriaFragment";
import { useCallback, useContext, useState } from "react";
import { IndicatorInfosContext } from "../../../../shared-service-contexts/IndicatorsInfoContext";
import { EditedCriteriaContext } from "../NewCriteriaEditor";
import { PTCodeFragmentsExpandedContainer } from "./PTCodeFragmentsExpandedContainer";

interface Props {
  activeTheme: string;
  applicationIndicators: ApplicationIndicators;
  criteriaType: string;
  allowedFragmentTypes: string[];
  focusedFragmentIndex: number | undefined;
  addFragment: (fragment: string) => void;
  candleSize: string;
  tradingCurrencies: string[];
  maxHeight?: number;
}

export function PTCodeFragmentsOverview(props: React.PropsWithChildren<Props>) {
  const editedCriteria = useContext(EditedCriteriaContext);
  const indicatorsInfo = useContext(IndicatorInfosContext);
  const patternRecognitionHelper =
    props.applicationIndicators.pt_indicator_helper;

  // check edited criteria by splitting on " ", and see if there is an EXACT match
  // if there is, then disable that criteria
  const criteriaFragmentIsAlreadyIncluded = useCallback(
    (criteriaFragment: string) => {
      const splitCriteria = criteriaFragment.split(" ");
      const match = editedCriteria
        ?.split(" ")
        .some((criteria) => splitCriteria.includes(criteria));
      return match;
    },
    [editedCriteria]
  );
  const [expandedNeutralPatterns, setExpandedNeutralPatterns] = useState<
    string[]
  >(
    patternRecognitionHelper
      ? Object.keys(patternRecognitionHelper.neutral_patterns)
      : []
  );
  const [expandedBullishPatterns, setExpandedBullishPatterns] = useState<
    string[]
  >([]);
  const [expandedBearishPatterns, setExpandedBearishPatterns] = useState<
    string[]
  >([]);

  const [showNeutralPatterns, setShowNeutralPatterns] = useState<boolean>(true);
  const [showBullishPatterns, setShowBullishPatterns] = useState<boolean>(true);
  const [showBearishPatterns, setShowBearishPatterns] = useState<boolean>(true);

  const sectionNeutralExpanded =
    expandedNeutralPatterns.length ===
    Object.keys(patternRecognitionHelper?.neutral_patterns ?? {}).length;
  const sectionBullishExpanded =
    expandedBullishPatterns.length ===
    Object.keys(patternRecognitionHelper?.bullish_patterns ?? {}).length;
  const sectionBearishExpanded =
    expandedBearishPatterns.length ===
    Object.keys(patternRecognitionHelper?.bearish_patterns ?? {}).length;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, maxHeight: props.maxHeight }}
      transition={{ duration: 0.5, delay: 0.3 }}
      exit={{ opacity: 0 }}
      className="code-fragments-overview-container"
    >
      <>
        {props.candleSize === "5m" ? (
          <label className="dimmed-label info">
            There are currently no Pattern tracking supported on candle size:{" "}
            {props.candleSize}
          </label>
        ) : (
          <>
            <label className="dimmed-label info">
              Use <strong>Pattern tracking</strong> to identify patterns in
              the price action that may indicate future price movements. The
              patterns are based on candlestick formations and are divided into
              three categories: neutral, bullish and bearish. Use{" "}
              <strong>CONFIRMED</strong> patterns to increase the accuracy of
              the pattern tracking.
            </label>
            {patternRecognitionHelper && indicatorsInfo && (
              <div className="code-fragment-pattern-outer-container neutral">
                <div
                  onClick={() => setShowNeutralPatterns(!showNeutralPatterns)}
                  className={
                    "code-fragment-pattern-outer-header-container" +
                    (showNeutralPatterns ? " expanded" : "") +
                    " neutral"
                  }
                >
                  <label className="code-fragment-pattern-outer-header-label">
                    Neutral patterns
                  </label>
                  <label
                    className="code-fragment-label-button"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      // if all are expanded, then collapse all by emptying the array
                      // else, expand all by adding all keys to the array, but without duplicates
                      setShowNeutralPatterns(true);
                      if (
                        expandedNeutralPatterns.length ===
                        Object.keys(patternRecognitionHelper.neutral_patterns)
                          .length
                      ) {
                        setExpandedNeutralPatterns([]);
                      } else {
                        setExpandedNeutralPatterns(
                          Object.keys(patternRecognitionHelper.neutral_patterns)
                        );
                      }
                    }}
                  >
                    {sectionNeutralExpanded ? "Collapse all" : "Expand all"}
                  </label>
                </div>
                {showNeutralPatterns &&
                  Object.keys(patternRecognitionHelper.neutral_patterns).map(
                    (key, idx) => {
                      const patternInfo =
                        patternRecognitionHelper.neutral_patterns[key];

                      const expanded = expandedNeutralPatterns.includes(key);

                      return (
                        <motion.div
                          key={key}
                          initial={{ opacity: 0, scaleY: 0 }}
                          animate={{
                            opacity: 1,
                            scaleY: 1,
                            height: expanded ? "200px" : "auto",
                          }}
                          transition={{ duration: 0.2, delay: idx * 0.04 }}
                          className={
                            "code-fragment-pattern-container" +
                            (expanded ? " expanded" : "")
                          }
                          onClick={() => {
                            if (expandedNeutralPatterns.includes(key)) {
                              setExpandedNeutralPatterns(
                                expandedNeutralPatterns.filter(
                                  (pattern) => pattern !== key
                                )
                              );
                            } else {
                              setExpandedNeutralPatterns([
                                ...expandedNeutralPatterns,
                                key,
                              ]);
                            }
                          }}
                        >
                          <div className="code-fragment-pattern-header-container">
                            <label className="dimmed-label">
                              {patternInfo.name}
                            </label>
                            <label className="hide-show-label">
                              {expanded ? "Hide" : "Show"} Details
                            </label>
                          </div>

                          {Object.keys(patternInfo.criterias).map(
                            (criteraFragment, index) => {
                              const fragmentType: FragmentType = {
                                fragment: criteraFragment,
                                type: "PATTERN",
                                className: "fragment-badge-pattern",
                              };

                              const enabled =
                                !criteriaFragmentIsAlreadyIncluded(
                                  criteraFragment
                                ) &&
                                props.allowedFragmentTypes.includes(
                                  fragmentType.type
                                );

                              return (
                                <div
                                  key={criteraFragment + "-" + index}
                                  className="code-fragment-container"
                                  id={enabled ? "" : "disabled-code-fragment"}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    if (!enabled) return;
                                    props.addFragment(criteraFragment);
                                  }}
                                >
                                  <CriteriaFragment
                                    indicators={
                                      props.applicationIndicators.indicators
                                    }
                                    activeTheme={props.activeTheme}
                                    fragmentType={fragmentType}
                                    index={index}
                                    small
                                  />
                                </div>
                              );
                            }
                          )}
                          {expanded && (
                            <PTCodeFragmentsExpandedContainer
                              activeTheme={props.activeTheme}
                              patternInfo={patternInfo}
                            />
                          )}
                        </motion.div>
                      );
                    }
                  )}
              </div>
            )}
            {patternRecognitionHelper && indicatorsInfo && (
              <div className="code-fragment-pattern-outer-container bullish">
                <div
                  onClick={() => setShowBullishPatterns(!showBullishPatterns)}
                  className={
                    "code-fragment-pattern-outer-header-container" +
                    (showBullishPatterns ? " expanded" : "") +
                    " bullish"
                  }
                >
                  <label className="code-fragment-pattern-outer-header-label">
                    Bullish patterns
                  </label>
                  <label
                    className="code-fragment-label-button"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      // if all are expanded, then collapse all by emptying the array
                      // else, expand all by adding all keys to the array, but without duplicates
                      setShowBullishPatterns(true);
                      if (
                        expandedBullishPatterns.length ===
                        Object.keys(patternRecognitionHelper.bullish_patterns)
                          .length
                      ) {
                        setExpandedBullishPatterns([]);
                      } else {
                        setExpandedBullishPatterns(
                          Object.keys(patternRecognitionHelper.bullish_patterns)
                        );
                      }
                    }}
                  >
                    {sectionBullishExpanded ? "Collapse all" : "Expand all"}
                  </label>
                </div>
                {showBullishPatterns &&
                  Object.keys(patternRecognitionHelper.bullish_patterns).map(
                    (key, idx) => {
                      const patternInfo =
                        patternRecognitionHelper.bullish_patterns[key];

                      const expanded = expandedBullishPatterns.includes(key);

                      return (
                        <motion.div
                          key={key}
                          initial={{ opacity: 0, scaleY: 0 }}
                          animate={{
                            opacity: 1,
                            scaleY: 1,
                            height: expanded ? "200px" : "auto",
                          }}
                          transition={{ duration: 0.2, delay: idx * 0.04 }}
                          className={
                            "code-fragment-pattern-container" +
                            (expanded ? " expanded" : "")
                          }
                          onClick={() => {
                            if (expandedBullishPatterns.includes(key)) {
                              setExpandedBullishPatterns(
                                expandedBullishPatterns.filter(
                                  (pattern) => pattern !== key
                                )
                              );
                            } else {
                              setExpandedBullishPatterns([
                                ...expandedBullishPatterns,
                                key,
                              ]);
                            }
                          }}
                        >
                          <div className="code-fragment-pattern-header-container">
                            <label className="dimmed-label">
                              {patternInfo.name}
                            </label>
                            <label className="hide-show-label">
                              {expanded ? "Hide" : "Show"} Details
                            </label>
                          </div>

                          {Object.keys(patternInfo.criterias).map(
                            (criteraFragment, index) => {
                              const fragmentType: FragmentType = {
                                fragment: criteraFragment,
                                type: "PATTERN",
                                className: "fragment-badge-pattern",
                              };

                              const enabled =
                                !criteriaFragmentIsAlreadyIncluded(
                                  criteraFragment
                                ) &&
                                props.allowedFragmentTypes.includes(
                                  fragmentType.type
                                );

                              return (
                                <div
                                  key={criteraFragment + "-" + index}
                                  className="code-fragment-container"
                                  id={enabled ? "" : "disabled-code-fragment"}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    if (!enabled) return;
                                    props.addFragment(criteraFragment);
                                  }}
                                >
                                  <CriteriaFragment
                                    indicators={
                                      props.applicationIndicators.indicators
                                    }
                                    activeTheme={props.activeTheme}
                                    fragmentType={fragmentType}
                                    index={index}
                                    small
                                  />
                                </div>
                              );
                            }
                          )}
                          {expanded && (
                            <PTCodeFragmentsExpandedContainer
                              activeTheme={props.activeTheme}
                              patternInfo={patternInfo}
                            />
                          )}
                        </motion.div>
                      );
                    }
                  )}
              </div>
            )}
            {patternRecognitionHelper && indicatorsInfo && (
              <div className="code-fragment-pattern-outer-container bearish">
                <div
                  onClick={() => setShowBearishPatterns(!showBearishPatterns)}
                  className={
                    "code-fragment-pattern-outer-header-container" +
                    (showBearishPatterns ? " expanded" : "") +
                    " bearish"
                  }
                >
                  <label className="code-fragment-pattern-outer-header-label">
                    Bearish patterns
                  </label>
                  <label
                    className="code-fragment-label-button"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      // if all are expanded, then collapse all by emptying the array
                      // else, expand all by adding all keys to the array, but without duplicates
                      setShowBearishPatterns(true);
                      if (
                        expandedBearishPatterns.length ===
                        Object.keys(patternRecognitionHelper.bearish_patterns)
                          .length
                      ) {
                        setExpandedBearishPatterns([]);
                      } else {
                        setExpandedBearishPatterns(
                          Object.keys(patternRecognitionHelper.bearish_patterns)
                        );
                      }
                    }}
                  >
                    {sectionBearishExpanded ? "Collapse all" : "Expand all"}
                  </label>
                </div>
                {showBearishPatterns &&
                  Object.keys(patternRecognitionHelper.bearish_patterns).map(
                    (key, idx) => {
                      const patternInfo =
                        patternRecognitionHelper.bearish_patterns[key];

                      const expanded = expandedBearishPatterns.includes(key);

                      return (
                        <motion.div
                          key={key}
                          initial={{ opacity: 0, scaleY: 0 }}
                          animate={{
                            opacity: 1,
                            scaleY: 1,
                            height: expanded ? "200px" : "auto",
                          }}
                          transition={{ duration: 0.2, delay: idx * 0.04 }}
                          className={
                            "code-fragment-pattern-container" +
                            (expanded ? " expanded" : "")
                          }
                          onClick={() => {
                            if (expandedBearishPatterns.includes(key)) {
                              setExpandedBearishPatterns(
                                expandedBearishPatterns.filter(
                                  (pattern) => pattern !== key
                                )
                              );
                            } else {
                              setExpandedBearishPatterns([
                                ...expandedBearishPatterns,
                                key,
                              ]);
                            }
                          }}
                        >
                          <div className="code-fragment-pattern-header-container">
                            <label className="dimmed-label">
                              {patternInfo.name}
                            </label>
                            <label className="hide-show-label">
                              {expanded ? "Hide" : "Show"} Details
                            </label>
                          </div>

                          {Object.keys(patternInfo.criterias).map(
                            (criteraFragment, index) => {
                              const fragmentType: FragmentType = {
                                fragment: criteraFragment,
                                type: "PATTERN",
                                className: "fragment-badge-pattern",
                              };

                              const enabled =
                                !criteriaFragmentIsAlreadyIncluded(
                                  criteraFragment
                                ) &&
                                props.allowedFragmentTypes.includes(
                                  fragmentType.type
                                );

                              return (
                                <div
                                  key={criteraFragment + "-" + index}
                                  className="code-fragment-container"
                                  id={enabled ? "" : "disabled-code-fragment"}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    if (!enabled) return;
                                    props.addFragment(criteraFragment);
                                  }}
                                >
                                  <CriteriaFragment
                                    indicators={
                                      props.applicationIndicators.indicators
                                    }
                                    activeTheme={props.activeTheme}
                                    fragmentType={fragmentType}
                                    index={index}
                                    small
                                  />
                                </div>
                              );
                            }
                          )}
                          {expanded && (
                            <PTCodeFragmentsExpandedContainer
                              activeTheme={props.activeTheme}
                              patternInfo={patternInfo}
                            />
                          )}
                        </motion.div>
                      );
                    }
                  )}
              </div>
            )}
          </>
        )}
      </>
    </motion.div>
  );
}
