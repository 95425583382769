import { motion } from "framer-motion";
import { FiArrowLeft, FiArrowUp } from "react-icons/fi";

interface Props {
  handleClose?: () => void;
  listCasesClick?: () => void;
  readonly?: boolean;
}
export function CasePopupHeaderControl(props: React.PropsWithChildren<Props>) {
  const iconSize = 22;
  return (
    <div className="case-popup-header-control-container">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.3 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        exit={{ opacity: 0 }}
        className="case-popup-header-control-close"
      >
        {props.listCasesClick && (
          <FiArrowLeft size={iconSize} onClick={props.listCasesClick} />
        )}
        {props.readonly && (
          <label style={{ marginLeft: "auto" }} className="dimmed-label">
            Read only
          </label>
        )}
        <FiArrowUp
          id="close-case-popup"
          style={{ marginLeft: "auto", marginRight: "5px" }}
          size={iconSize}
          onClick={props.handleClose}
        />
      </motion.div>
    </div>
  );
}
