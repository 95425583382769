import React, { useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceDot,
  Legend,
} from "recharts";
import {
  OrderProfile,
  OrderProfileAttempt,
} from "../../../interfaces/strategyInterfaces/Strategy";
import { getTheme } from "../../../utils/themeUtil";

interface Props {
  activeTheme: string;
  orderProfile: OrderProfile;
  profileMode: string;
  hideXAxis?: boolean;
  hideYAxis?: boolean;
  hideLabel?: boolean;
  bid?: number;
  ask?: number;
  atr?: number;
  attempt?: number;
}

export function OrderProfileChart(props: React.PropsWithChildren<Props>) {
  const { orderProfile } = props;
  const theme = useMemo(() => {
    return getTheme(props.activeTheme);
  }, [props.activeTheme]);

  // Hardcoded base prices and ATR value for demonstration
  const baseBid = props.bid ?? 100;
  const baseAsk = props.ask ?? 105;
  const atr = props.atr ?? 2;

  // Determine if this is a buy or sell profile (here, by checking the name)
  const isBuyProfile = props.profileMode === "entry";

  /**
   * Computes the y-coordinate for an attempt.
   *
   * For a buy profile:
   *   - "bid" attempts: baseBid - atr * multiplier
   *   - "ask" attempts: baseAsk - atr * multiplier
   *
   * For a sell profile:
   *   - "ask" attempts: baseAsk + atr * multiplier
   *   - "bid" attempts: baseBid + atr * multiplier
   *
   * Adjust this logic as needed.
   */
  function getAttemptY(
    field: string,
    multiplier: number,
    isBuy: boolean
  ): number {
    if (isBuy) {
      if (field === "bid") {
        return baseBid - atr * multiplier;
      } else if (field === "ask") {
        return baseAsk - atr * multiplier;
      }
    } else {
      if (field === "ask") {
        return baseAsk + atr * multiplier;
      } else if (field === "bid") {
        return baseBid + atr * multiplier;
      }
    }
    return field === "bid" ? baseBid : baseAsk;
  }

  const numAttempts = orderProfile.attempts;

  // Create chart data for the constant bid and ask lines.
  // We add padding on the x-axis by using points from 0 to numAttempts + 1.
  const chartData = Array.from({ length: numAttempts + 2 }, (_, i) => ({
    x: i,
    bid: baseBid,
    ask: baseAsk,
  }));

  // Generate attempt data (for annotations) and determine each attempt's y-value.
  // We place attempts at x = 1, 2, ... , numAttempts.
  const attemptData: any[] = [];
  for (let i = 0; i < numAttempts; i++) {
    const attemptKey = `attempt_${i + 1}`;
    const attempt = orderProfile[attemptKey] as OrderProfileAttempt;
    const x = i + 1;
    const y = getAttemptY(attempt.field, attempt.atr_multiplier, isBuyProfile);
    attemptData.push({
      x,
      y,
      attemptNumber: i + 1,
      field: attempt.field,
      multiplier: attempt.atr_multiplier,
    });
  }

  // Compute the y-axis domain based on all y-values from the lines and attempts.
  const allYValues = attemptData
    .map((item) => item.y)
    .concat([baseBid, baseAsk]);
  const minY = Math.min(...allYValues);
  const maxY = Math.max(...allYValues);
  // Add a 20% padding on top and bottom (or a minimum padding of 10 if the range is zero)
  const [opacity, setOpacity] = React.useState({
    bid: 1,
    ask: 1,
  });

  const handleMouseEnter = (o) => {
    const { dataKey } = o;

    setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
  };

  const handleMouseLeave = (o) => {
    const { dataKey } = o;

    setOpacity((op) => ({ ...op, [dataKey]: 1 }));
  };

  return (
    <div style={{ width: "100%", height: "100%", maxHeight: 250 }}>
      {!props.hideLabel && (
        <label className="dimmed-label">{props.orderProfile.name}</label>
      )}
      <ResponsiveContainer>
        <LineChart data={chartData}>
          {/*  <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis
            dataKey="x"
            type="number"
            tickLine={false}
            ticks={[1, 2, 3, 4, 5]}
            domain={[0, numAttempts + 1]}
            tickCount={2}
            hide={props.hideXAxis}
          />
          <YAxis
            domain={[minY - 5, maxY + 5]}
            tickCount={2}
            label={"Price"}
            hide={props.hideYAxis}
          />

          {/* Draw the constant bid and ask lines */}
          <Line
            type="monotone"
            dataKey="bid"
            stroke={theme.buyOrderAreaX}
            dot={false}
            strokeWidth={2}
            opacity={opacity.bid}
          />
          <Line
            type="monotone"
            dataKey="ask"
            stroke={theme.sellOrderAreaX}
            dot={false}
            strokeWidth={2}
            opacity={opacity.ask}
          />
          <Legend
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />

          {/* Annotate each attempt with a ReferenceDot */}
          {attemptData.map((attempt) => {
            const opacity = props.attempt !== undefined && attempt.attemptNumber !== props.attempt ? 0.3 : 1;
            return (
              <ReferenceDot
                key={`attempt-${attempt.attemptNumber}`}
                x={attempt.x}
                y={attempt.y}
                opacity={opacity}
                r={4}
                fill={
                  props.profileMode === "entry"
                    ? theme.buyOrderAreaY
                    : theme.sellOrderAreaY
                }
                stroke={
                  props.profileMode === "entry"
                    ? theme.buyOrderAreaX
                    : theme.sellOrderAreaX
                }
                strokeWidth={1}
                label={{
                  position: "top",
                  value: `#${attempt.attemptNumber}`,
                  fill: theme.text,
                  fontSize: 12,
                  fontFamily: "BalooBhaijaan2",
                }}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
