import { CandlestickSeries, IChartApi, Time } from "lightweight-charts";
import { IOHLCData } from "../financial-chart/iOHLCData";

export function addCandleSeries(
  chart: IChartApi,
  data: IOHLCData[],
  theme: any = null,
  paneIdx: number = 0
) {
  const candlestickSeries = chart.addSeries(
    CandlestickSeries,
    {
      upColor: theme.buyOrderAreaX,
      downColor: theme.sellOrderAreaX,
      borderVisible: false,
      wickUpColor: theme.buyOrderStrokeHover,
      wickDownColor: theme.sellOrderStrokeHover,
      priceLineVisible: false,
    },
    paneIdx
  );

  // 3) Convert data & set
  const formattedData = data.map((item) => ({
    time: (item.ts / 1000) as Time,
    open: item.open,
    high: item.high,
    low: item.low,
    close: item.close,
  }));
  candlestickSeries.setData(formattedData);
  return { ["candlestick"]: candlestickSeries };
}
