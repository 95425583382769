import {
  IChartApi,
  LineSeries,
  HistogramSeries,
  Time,
  PriceScaleMode,
} from "lightweight-charts";
import { IOHLCData } from "../../financial-chart/iOHLCData";
import { MACD } from "../../../../interfaces/tw-chart/TWChartSettings";
import { macd } from "indicatorts";
import { CustomChartAddon } from "../TWChart";

/**
 * Creates 3 sub-series for MACD:
 *  - A line for the MACD value
 *  - A line for the signal
 *  - A histogram (bar series) for the difference
 *
 * Returns an object keyed by descriptive labels so your legend can see them.
 * For example:
 * {
 *   "MACD(12,26,9)": <LineSeries ref>,
 *   "Signal(12,26,9)": <LineSeries ref>,
 *   "MACD-Hist(12,26,9)": <HistogramSeries ref>
 * }
 */
export function addMACDSeries(
  chart: IChartApi,
  data: IOHLCData[],
  indicatorConfig: MACD,
  theme: any = null,
  paneIdx: number = 0
): CustomChartAddon {
  // 2) Calculate MACD data from 'indicatorts'.
  //    The result is an array of objects { MACD, signal, histogram }.
  const result = macd(
    data.map((d) => d.close),
    indicatorConfig.config
  );

  // 3) Build some label strings for your legend

  // 4) Add the 3 series
  const macdLine = chart.addSeries(
    LineSeries,
    {
      priceScaleId: "right", // put it on your custom scale
      color: indicatorConfig.macdLine,
      lineWidth: 1,
      priceLineVisible: false,
    },
    paneIdx
  );

  // Once created, do:
  macdLine.priceScale().applyOptions({
    visible: true, // show the labels on the right (or left)
    borderVisible: true, // show the scale border
    autoScale: true, // allow the chart to scale automatically
    ticksVisible: true, // show the scale ticks
    borderColor: theme.cardInteriorAlt, // color of the scale border
    scaleMargins: {
      top: 0.2,
      bottom: 0.1,
    },
  });

  const signalLine = chart.addSeries(
    LineSeries,
    {
      priceScaleId: "right", // put it on your custom scale,
      color: indicatorConfig.signalLine,
      lineWidth: 1,
      priceLineVisible: false,
    },
    paneIdx
  );

  const histogram = chart.addSeries(
    HistogramSeries,
    {
      priceScaleId: "right", // put it on your custom scale,
      priceLineVisible: false,
    },
    paneIdx
  );

  // 5) Set data for each sub-series
  macdLine.setData(
    result.macdLine.map((val, i) => ({
      time: (data[i].ts / 1000) as Time,
      value: val,
    }))
  );

  signalLine.setData(
    result.signalLine.map((val, i) => ({
      time: (data[i].ts / 1000) as Time,
      value: val,
    }))
  );

  // create a histogram series with the data
  const histData = result.macdLine.map((val, i) => ({
    time: (data[i].ts / 1000) as Time,
    value: val - result.signalLine[i],
  }));

  histogram.setData(
    histData.map((item, index) => {
      const prevItem = histData[index - 1];
      const color =
        index > 0
          ? item.value > 0
            ? item.value > prevItem.value
              ? theme.buyOrderAreaX
              : theme.buyOrderAreaY
            : item.value > prevItem.value
            ? theme.sellOrderAreaY
            : theme.sellOrderAreaX
          : theme.cardInteriorAlt;
      return {
        time: item.time,
        value: item.value,
        color: color,
      };
    })
  );

  // 6) Return an object keyed by descriptive labels
  //    so your crosshair-move logic can pick them up easily.
  const macdLabel = `MACD`;
  const sigLabel = `SIGNAL`;
  const histLabel = `HIST`;
  return {
    chartData: {
      [macdLabel]: macdLine,
      [sigLabel]: signalLine,
      [histLabel]: histogram,
    },
    colorMap: {
      [macdLabel]: indicatorConfig.macdLine,
      [sigLabel]: indicatorConfig.signalLine,
    },
  };
}
