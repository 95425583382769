import {
  AroonConfig,
  ATRConfig,
  BBConfig,
  EMAConfig,
  MACDConfig,
  ROCConfig,
  RSIConfig,
  StochConfig,
} from "indicatorts";

export interface TWChartSettings {
  main: TWMainChartSettings;
  paneSettings: TWPaneSettings;
  taIndicators?: TAIndicator[];
}

export interface TWMainChartSettings {
  grid: boolean;
  volume: boolean;
}

export interface TWPaneSettings {
  [id: string]: TWPaneSetting;
}

/**
 * Settings for an individual pane in the chart.
 * You can specify an ID, a title (if desired), a fixed height (or ratio), and an order.
 */
export interface TWPaneSetting {
  id: string; // unique identifier for the pane
  order: number; // order in the chart (lower numbers are higher up)
  title?: string; // optional pane title (e.g. "MACD", "ATR", etc.)
  heightFactor: number; // either a fixed pixel height or a ratio value (your implementation may vary)
}

// 1) Define a generic base interface
interface BaseIndicator<TType extends string, TConfig> {
  /**
   * A string ID you can auto-generate or store per indicator instance,
   * if you need a unique ID for each one
   */
  id: string;
  /**
   * Each child will specify the actual literal (e.g. "EMA", "RSI")
   */
  type: TType;

  /**
   * The specific config type for each indicator
   */
  config: TConfig;

  /**
   * If true, this indicator should be drawn in a separate pane
   * rather than overlaying on the main chart
   */
  separatePane: boolean;
}

export const genericFields = ["id", "type", "config", "separatePane"];

/**
 * 2) Extend for each indicator
 * - Notice each child "locks" the type field
 *   and the config shape
 */

// Example for EMA
export interface EMA extends BaseIndicator<"EMA", EMAConfig> {
  color: string;
}

// RSI
export interface RSI extends BaseIndicator<"RSI", RSIConfig> {
  color: string;
}

// BBAND
export interface BBAND extends BaseIndicator<"BBAND", BBConfig> {
  upper: string;
  middle: string;
  lower: string;
}

// Aroon
export interface AROON extends BaseIndicator<"AROON", AroonConfig> {
  up: string;
  down: string;
}

// ATR
export interface ATR extends BaseIndicator<"ATR", ATRConfig> {
  /*   trLine: string;
  atrLine: string; */
  color: string;
}

// BOP
export interface BOP extends BaseIndicator<"BOP", {}> {
  color: string;
}

// MACD
export interface MACD extends BaseIndicator<"MACD", MACDConfig> {
  signalLine: string;
  macdLine: string;
}
export interface ROC extends BaseIndicator<"ROC", ROCConfig> {
  color: string;
}
export interface STOCHASTIC
  extends BaseIndicator<"STOCHASTIC", StochConfig> {
  kLine: string;
  dLine: string;
}

// 3) Union type includes all
export type TAIndicator =
  | EMA
  | RSI
  | BBAND
  | AROON
  | ATR
  | BOP
  | MACD
  | ROC
  | STOCHASTIC;

export const TAIndicators = [
  "EMA",
  "RSI",
  "BBAND",
  "AROON",
  "ATR",
  "BOP",
  "MACD",
  "ROC",
  "STOCHASTIC",
];

export const TaIndicatorsSupportOnChartUpperCase = TAIndicators.map((indicator) =>
  indicator.toUpperCase()
);