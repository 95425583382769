import { useCallback, useState, useMemo, useRef } from "react";
import { LivetraderUserLogItem } from "../../../interfaces/livetrader/LivewalletReport";
import { toTimestring } from "../../../utils/FormattingUtils";
import "./livetrader-logs.scss";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { MdErrorOutline } from "react-icons/md";
import { Modal } from "@mantine/core";
import { LivetraderErrorDetailsWindow } from "./LivetraderErrorDetailsWindow";
import { useAuth0 } from "@auth0/auth0-react";
import { UsermanagerService } from "../../../services/UserManagerService";
import { LivetraderService } from "../../../services/LivetraderService";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";
import { LiaTimesSolid } from "react-icons/lia";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";

interface Props {
  activeTheme: string;
  livetraderLogs: LivetraderUserLogItem[] | undefined;
  refetchLogs: () => void;
  detached?: boolean;
}

interface RowItemData {
  logs: LivetraderUserLogItem[];
  sortMethod: "Oldest" | "Newest";
  activeTheme: string;
  onSelectLog: (log: LivetraderUserLogItem) => void;
  onDeleteLog: (livewalletId: string, logId: string) => Promise<void>;
}

export function LivetraderLogs(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const [sortMethod, setSortMethod] = useState<"Oldest" | "Newest">("Newest");
  const [selectedLivetraderLog, setSelectedLivetraderLog] = useState<
    LivetraderUserLogItem | undefined
  >();
  const [submittingBug, setSubmittingBug] = useState(false);

  const listRef = useRef<FixedSizeList>(null);

  const sortedLogs = useMemo(() => {
    if (!props.livetraderLogs || props.livetraderLogs.length === 0) return [];
    return [...props.livetraderLogs].sort((a, b) => {
      if (sortMethod === "Oldest") {
        return a.timestamp - b.timestamp;
      } else {
        return b.timestamp - a.timestamp;
      }
    });
  }, [props.livetraderLogs, sortMethod]);

  const submitLivetraderBug = useCallback(async () => {
    if (!selectedLivetraderLog) return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setSubmittingBug(true);
      await UsermanagerService.submitLivetraderBugReport(
        token,
        selectedLivetraderLog.id
      );
      setSelectedLivetraderLog({
        ...selectedLivetraderLog,
        bug_submitted: true,
      });
      props.refetchLogs();
    } finally {
      setSubmittingBug(false);
    }
  }, [selectedLivetraderLog, getAccessTokenSilently, props.refetchLogs]);

  const handleDeleteLog = useCallback(
    async (livewalletId: string, logId: string) => {
      const token = await getAccessTokenSilently();
      if (!token) return;
      await LivetraderService.deleteLivetraderLog(token, livewalletId, logId);
      props.refetchLogs();
    },
    [getAccessTokenSilently, props.refetchLogs]
  );

  const RowItem = useCallback(
    ({ index, style, data }: ListChildComponentProps<RowItemData>) => {
      const log = data.logs[index];
      if (!log) return null;

      return (
        <div style={style} className={"log-item " + log.type}>
          <div className="log-item-header">
            <label style={{ marginLeft: "5px" }} className="dimmed-label">
              {toTimestring(log.timestamp)}
            </label>
            {log.type === "error" ? (
              <CommonButton
                style={{
                  marginLeft: "auto",
                  marginRight: "5px",
                }}
                compact
                leftIcon={<MdErrorOutline />}
                activeTheme={data.activeTheme}
                label="Error details"
                borderTheme="red-accent"
                onClick={() => data.onSelectLog(log)}
              />
            ) : (
              <label
                style={{
                  marginLeft: "auto",
                  marginRight: "5px",
                }}
              >
                {log.type}
              </label>
            )}
            <CommonIconButton
              flat
              icon={<LiaTimesSolid />}
              onClick={() => data.onDeleteLog(log.livewallet_id, log.id)}
              activeTheme={data.activeTheme}
            />
          </div>
          <label className="log-message">{log.message}</label>
        </div>
      );
    },
    []
  );

  // Example function to demonstrate usage of the listRef
  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollToItem(0, "start");
    }
  };

  return (
    <div className={"livetrader-logs" + (props.detached ? " detached" : "")}>
      <div className="livetrader-logs-header">
        <label
          className="sort-method-label"
          onClick={() => {
            setSortMethod(sortMethod === "Oldest" ? "Newest" : "Oldest");
          }}
        >
          Sort by: {sortMethod}
        </label>
        <label className="dimmed-label">
          Logs will only be available for the last 48 hours
        </label>
      </div>
      {/* Example button to scroll the list to the top */}
      {sortedLogs.length > 10 && (
        <CommonButton
          activeTheme={props.activeTheme}
          compact
          label="Scroll to top"
          onClick={scrollToTop}
          style={{ marginBottom: "10px" }}
        />
      )}
      <div className="livetrader-log-container">
        {sortedLogs && sortedLogs.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                ref={listRef}
                height={height}
                width={width}
                className="log-list"
                itemCount={sortedLogs.length}
                itemSize={150} // Adjust this according to your log item's height
                itemData={{
                  logs: sortedLogs,
                  sortMethod,
                  activeTheme: props.activeTheme,
                  onSelectLog: (log: LivetraderUserLogItem) =>
                    setSelectedLivetraderLog(log),
                  onDeleteLog: handleDeleteLog,
                }}
                overscanCount={5}
              >
                {RowItem}
              </FixedSizeList>
            )}
          </AutoSizer>
        ) : (
          <label className="dimmed-label">No logs available</label>
        )}
      </div>
      <Modal
        title="Error details"
        opened={selectedLivetraderLog !== undefined}
        onClose={() => setSelectedLivetraderLog(undefined)}
        centered
        size={"lg"}
      >
        {selectedLivetraderLog && (
          <LivetraderErrorDetailsWindow
            activeTheme={props.activeTheme}
            logItem={selectedLivetraderLog}
            submittingBug={submittingBug}
            submitBug={submitLivetraderBug}
          />
        )}
      </Modal>
    </div>
  );
}
