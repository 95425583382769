import "./new-case-editor.scss";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SetEditModeContext } from "./CaseControl";
import { ApplicationIndicatorsContext } from "../../../shared-service-contexts/IndicatorContext";
import { ParsedCriteria } from "../ParsedCriteria";
import {
  FragmentType,
  getFragmentType,
} from "../../../utils/ParseCriteriaUtil";
import { TACodeFragmentsOverview } from "./code-fragments-overviews/TACodeFragmentsOverview";
import { FiArrowLeft, FiCheck, FiX } from "react-icons/fi";
import { AiOutlineUndo } from "react-icons/ai";
import { Tooltip } from "@mantine/core";
import { IndicatorInfosContext } from "../../../shared-service-contexts/IndicatorsInfoContext";
import { motion } from "framer-motion";
import { AICodeFragmentsOverview } from "./code-fragments-overviews/AICodeFragmentsOverview";
import { PTCodeFragmentsOverview } from "./code-fragments-overviews/PTCodeFragmentsOverview";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import {
  CalculationProfile,
  CalculationProfileMenu,
} from "../CalculationProfileMenu";

interface Props {
  activeTheme: string;
  tradingCurrencies: string[];
  criteria?: string;
  candleSize: string;
  criteriaType: string;
  handleReplaceCriteria: (newCriteria: string) => void;
  handleAddCriteria: (newCriteria: string) => void;
  removeCurrency: (currency: string) => void;
  maxHeight?: number;
}
export function NewCriteriaEditor(props: React.PropsWithChildren<Props>) {
  const setEditMode = useContext(SetEditModeContext);
  const applicationIndicators = useContext(ApplicationIndicatorsContext);

  const patternCriteriaMap = useMemo(() => {
    return applicationIndicators?.pt_translations ?? {};
  }, [applicationIndicators]);

  const indicatorsInfo = useContext(IndicatorInfosContext);
  const [calculationProfile, setCalculationProfile] =
    useState<CalculationProfile>("TA");

  const [editedCriteria, setEditedCriteria] = useState<string>(
    props.criteria?.trim() ?? ""
  );

  const [focusedFragmentIndex, setFocusedFragmentIndex] = useState<
    number | undefined
  >();

  const [allowedCodeFragments, setAllowedCodeFragments] = useState<string[]>(
    []
  );
  const [prependConnector, setPrependConnector] = useState<boolean>(false);

  const handleFragmentClick = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      fragmentType?: FragmentType,
      index?: number
    ) => {
      e.stopPropagation();
      e.preventDefault();
      const criteriaFragments = editedCriteria.split(" ");
      if (e.type === "contextmenu") {
        if (index !== undefined) {
          if (criteriaFragments.length === 1) {
            setEditedCriteria("");
            return;
          }
          if (
            index > 1 &&
            applicationIndicators &&
            fragmentType?.type !== "CONNECTOR"
          ) {
            // check if we've got a direction and an operator before the fragment
            const previousFragment = criteriaFragments[index - 1];
            const previousFragmentType = getFragmentType(
              previousFragment,
              applicationIndicators,
              patternCriteriaMap
            );

            if (fragmentType?.type === "VARIABLE") {
              criteriaFragments.splice(index, criteriaFragments.length - 1);
              setEditedCriteria(criteriaFragments.join(" ").trim());
              return;
            }
            if (fragmentType?.type === "OPERATOR") {
              if (
                ["DIRECTION", "AI_VARIABLE"].includes(previousFragmentType.type)
              ) {
                criteriaFragments.splice(index - 1, criteriaFragments.length);
                setEditedCriteria(criteriaFragments.join(" ").trim());
                return;
              } else {
                criteriaFragments.splice(index, criteriaFragments.length - 1);
                setEditedCriteria(criteriaFragments.join(" ").trim());
                return;
              }
            }
            if (previousFragmentType.type === "OPERATOR") {
              const secondLastFragment = criteriaFragments[index - 2];
              const secondLastFragmentType = getFragmentType(
                secondLastFragment,
                applicationIndicators,
                patternCriteriaMap
              );
              if (
                ["AI_VARIABLE", "DIRECTION"].includes(
                  secondLastFragmentType.type
                )
              ) {
                criteriaFragments.splice(index - 2, criteriaFragments.length);
                setEditedCriteria(criteriaFragments.join(" ").trim());
                return;
              }
            } else if (
              ["AI_VARIABLE", "DIRECTION"].includes(previousFragmentType.type)
            ) {
              criteriaFragments.splice(index - 1, criteriaFragments.length);
              setEditedCriteria(criteriaFragments.join(" ").trim());
              return;
            }
          }
          criteriaFragments.splice(index, criteriaFragments.length);
          setEditedCriteria(criteriaFragments.join(" ").trim());
        }
        setFocusedFragmentIndex(undefined);
      } else {
        if (
          index &&
          index >= 1 &&
          applicationIndicators &&
          fragmentType?.type !== "CONNECTOR"
        ) {
          // check if we've got a direction and an operator before the fragment

          if (fragmentType?.type === "OPERATOR") {
            const previousFragment = criteriaFragments[index - 1];
            const previousFragmentType = getFragmentType(
              previousFragment,
              applicationIndicators,
              patternCriteriaMap
            );
            if (["DIRECTION"].includes(previousFragmentType.type)) {
              setFocusedFragmentIndex(index - 1);
              setAllowedCodeFragments(["DIRECTION"]);
              return;
            }
            if (["AI_VARIABLE"].includes(previousFragmentType.type)) {
              setFocusedFragmentIndex(index - 1);
              setAllowedCodeFragments(["AI_VARIABLE"]);
              return;
            }
          }
          if (fragmentType?.type === "AI_VARIABLE") {
            const previousFragment = criteriaFragments[index - 1];
            const previousFragmentType = getFragmentType(
              previousFragment,
              applicationIndicators,
              patternCriteriaMap
            );
            if (["OPERATOR"].includes(previousFragmentType.type)) {
              setFocusedFragmentIndex(index - 2);
              setAllowedCodeFragments(["AI_VARIABLE"]);
              return;
            }
          }
          if (
            fragmentType?.type === "DIRECTION" ||
            fragmentType?.type === "VALUE"
          ) {
            const secondLastFragment = criteriaFragments[index - 2];
            const secondLastFragmentType = getFragmentType(
              secondLastFragment,
              applicationIndicators,
              patternCriteriaMap
            );
            if (["DIRECTION"].includes(secondLastFragmentType.type)) {
              setFocusedFragmentIndex(index - 2);
              setAllowedCodeFragments(["DIRECTION"]);
              return;
            }
          }
        }

        setFocusedFragmentIndex(
          fragmentType && fragmentType.type === "CONNECTOR" ? undefined : index
        );
      }
    },
    [
      patternCriteriaMap,
      editedCriteria,
      setFocusedFragmentIndex,
      setEditedCriteria,
      applicationIndicators,
      setAllowedCodeFragments,
    ]
  );

  useEffect(() => {
    if (!applicationIndicators) return;
    const editedCriteriaFragments =
      editedCriteria.trim().length > 0 ? editedCriteria.trim().split(" ") : [];
    const length = editedCriteriaFragments.length;
    if (length === 0) {
      setEditedCriteria("");
      setAllowedCodeFragments([
        "VARIABLE",
        "VALUE",
        "DIRECTION",
        "AI_VARIABLE",
        "PATTERN",
      ]);
      setPrependConnector(false);
      return;
    }
    if (length === 1) {
      const lastFragment = editedCriteriaFragments[length - 1];
      const lastFragmentType: FragmentType = getFragmentType(
        lastFragment,
        applicationIndicators,
        patternCriteriaMap
      );
      if (["PATTERN", "AI_VARIABLE"].includes(lastFragmentType.type)) {
        setAllowedCodeFragments([
          "VARIABLE",
          "VALUE",
          "DIRECTION",
          "AI_VARIABLE",
          "PATTERN",
        ]);
        setPrependConnector(true);
        return;
      }
      if (["DIRECTION"].includes(lastFragmentType.type)) {
        setEditedCriteria("");
        setAllowedCodeFragments([
          "VARIABLE",
          "VALUE",
          "DIRECTION",
          "AI_VARIABLE",
          "PATTERN",
        ]);
        setPrependConnector(false);
        return;
      } else {
        setAllowedCodeFragments(["OPERATOR"]);
        setPrependConnector(false);
        return;
      }
    }
    if (focusedFragmentIndex || focusedFragmentIndex === 0) {
      const focusedFragment = editedCriteriaFragments[focusedFragmentIndex];
      const focusedFragmentType: FragmentType = getFragmentType(
        focusedFragment,
        applicationIndicators,
        patternCriteriaMap
      );
      if (["CANDLE", "VARIABLE", "VALUE"].includes(focusedFragmentType.type)) {
        setAllowedCodeFragments(["CANDLE", "VARIABLE", "VALUE"]);
      } else {
        setAllowedCodeFragments([focusedFragmentType.type]);
      }
      return;
    }
    setPrependConnector(false);
    if (length > 1) {
      const lastFragment = editedCriteriaFragments[length - 1];
      const lastFragmentType: FragmentType = getFragmentType(
        lastFragment,
        applicationIndicators,
        patternCriteriaMap
      );
      const secondLastFragment = editedCriteriaFragments[length - 2];
      const secondLastFragmentType: FragmentType = getFragmentType(
        secondLastFragment,
        applicationIndicators,
        patternCriteriaMap
      );
      if (["CONNECTOR"].includes(lastFragmentType.type)) {
        setAllowedCodeFragments([
          "VARIABLE",
          "VALUE",
          "DIRECTION",
          "AI_VARIABLE",
        ]);
        const trimmedCriteria = editedCriteriaFragments.splice(0, length - 1);
        setEditedCriteria(trimmedCriteria.join(" ").trim());
        return;
      }
      if (["PATTERN", "AI_VARIABLE"].includes(lastFragmentType.type)) {
        setAllowedCodeFragments([
          "VARIABLE",
          "VALUE",
          "DIRECTION",
          "AI_VARIABLE",
          "PATTERN",
        ]);
        setPrependConnector(true);
        return;
      }

      if (
        ["VARIABLE", "VALUE", "CANDLE"].includes(lastFragmentType.type) &&
        ["OPERATOR"].includes(secondLastFragmentType.type)
      ) {
        setAllowedCodeFragments([
          "VARIABLE",
          "VALUE",
          "DIRECTION",
          "AI_VARIABLE",
          "PATTERN",
        ]);
        setPrependConnector(true);
        return;
      }
      if (
        ["VARIABLE", "VALUE", "DIRECTION", "CANDLE"].includes(
          lastFragmentType.type
        ) &&
        ["CONNECTOR"].includes(secondLastFragmentType.type)
      ) {
        setAllowedCodeFragments(["OPERATOR"]);
        return;
      }
      if (["OPERATOR"].includes(lastFragmentType.type)) {
        if (["DIRECTION"].includes(secondLastFragmentType.type)) {
          setAllowedCodeFragments(["DIRECTION"]);
          return;
        }
        setAllowedCodeFragments(["VARIABLE", "VALUE"]);
        return;
      }
    } else if (length === 1) {
      const lastFragment = editedCriteriaFragments[length - 1];
      const lastFragmentType: FragmentType = getFragmentType(
        lastFragment,
        applicationIndicators,
        patternCriteriaMap
      );
      if (["VARIABLE", "VALUE"].includes(lastFragmentType.type)) {
        setAllowedCodeFragments(["OPERATOR"]);
        return;
      }
    } else {
      setAllowedCodeFragments(["VARIABLE", "VALUE"]);
      return;
    }
  }, [
    editedCriteria,
    setAllowedCodeFragments,
    applicationIndicators,
    focusedFragmentIndex,
    setPrependConnector,
    patternCriteriaMap,
  ]);

  /* Here is the explanation for the code above:
1. The useEffect hook will run whenever the editedCriteria, setAllowedCodeFragments, indicators, focusedFragmentIndex and setPrependConnector variables change. 
2. If the indicators variable is null or undefined, the function will return. 
3. The editedCriteria variable is the string that is currently being edited. 
4. We first check if the editedCriteria string is empty. If it is, we set the allowedCodeFragments variable to be the initial allowed code fragments and return. 
5. If the editedCriteria string contains only one word, we check if the last word is either "DIRECTION", "AI_VARIABLE" or "PATTERN". If it is, we set the allowedCodeFragments variable to be the initial allowed code fragments and return. 
6. If the editedCriteria string contains only one word and the last word is not "DIRECTION" or "AI_VARIABLE", we set the allowedCodeFragments variable to be an array containing only "OPERATOR" and return. 
7. If the focusedFragmentIndex variable is not null or undefined, we check if the word at the index of the focusedFragmentIndex variable is either "CANDLE", "VARIABLE" or "VALUE". If it is, we set the allowedCodeFragments variable to be an array containing only "CANDLE", "VARIABLE" or "VALUE". Otherwise, we set the allowedCodeFragments variable to be an array containing only the type of the word at the index of the focusedFragmentIndex variable. 
8. If the focusedFragmentIndex variable is null or undefined, we set the prependConnector variable to be false. 
9. If the editedCriteria string contains more than one word, we check if the last word is "CONNECTOR". If it is, we set the allowedCodeFragments variable to be an array containing only "VARIABLE", "VALUE", "DIRECTION" and "AI_VARIABLE" and remove the last word from the editedCriteria string. 
10. If the editedCriteria string contains more than one word and the last word is not "CONNECTOR", we check if the last word is "VARIABLE", "VALUE", "AI_VARIABLE" or "CANDLE" and the second last word is "OPERATOR". If it is, we set the allowedCodeFragments variable to be an array containing only "VARIABLE", "VALUE", "DIRECTION" and "AI_VARIABLE" and set the prependConnector variable to be true. 
11. If the editedCriteria string contains more than one word and the last word is "VARIABLE", "VALUE", "AI_VARIABLE" or "CANDLE" and the second last word is "CONNECTOR", we set the allowedCodeFragments variable to be an array containing only "OPERATOR". */

  const handleAddFragment = useCallback(
    (fragment: string) => {
      if (focusedFragmentIndex || focusedFragmentIndex === 0) {
        const criteriaFragments = editedCriteria.split(" ");
        if (criteriaFragments.length > 1 && applicationIndicators) {
          const selectedFragment = criteriaFragments[focusedFragmentIndex];
          const selectedFragmentType = getFragmentType(
            selectedFragment,
            applicationIndicators,
            patternCriteriaMap
          );
          if (
            ["AI_VARIABLE", "DIRECTION"].includes(selectedFragmentType.type)
          ) {
            const fragments = fragment.split(" ");
            for (let i = 0; i < fragments.length; i++) {
              criteriaFragments[focusedFragmentIndex + i] = fragments[i];
            }
            setEditedCriteria(criteriaFragments.join(" "));
            setFocusedFragmentIndex(undefined);
            return;
          }
          /* const nextFragmentType = getFragmentType(
            fragments[],
            indicators
          ); */
        }
        criteriaFragments[focusedFragmentIndex] = fragment;
        setEditedCriteria(criteriaFragments.join(" "));
        setFocusedFragmentIndex(undefined);
        return;
      }
      const fragments = fragment.split(" ");
      const editedCriteriaFragments = editedCriteria.split(" ");
      if (prependConnector) editedCriteriaFragments.push(";");
      fragments.forEach((_fragment) => {
        editedCriteriaFragments.push(_fragment);
      });
      setEditedCriteria(editedCriteriaFragments.join(" "));
      setFocusedFragmentIndex(undefined);
    },
    [
      patternCriteriaMap,
      editedCriteria,
      setEditedCriteria,
      prependConnector,
      setFocusedFragmentIndex,
      focusedFragmentIndex,
      applicationIndicators,
    ]
  );

  const checkIfValid = useCallback(() => {
    if (!editedCriteria) return false;
    if (!applicationIndicators) return false;
    const criteriaFragments = editedCriteria.split(" ");
    if (criteriaFragments.length < 1) return false;
    const lastCriteriaFragment =
      criteriaFragments[criteriaFragments.length - 1];
    const lastCriteriaFragmentType = getFragmentType(
      lastCriteriaFragment,
      applicationIndicators,
      patternCriteriaMap
    );
    if (["PATTERN", "AI_VARIABLE"].includes(lastCriteriaFragmentType.type)) {
      return true;
    }
    if (["OPERATOR", "CONNECTOR"].includes(lastCriteriaFragmentType.type)) {
      return false;
    }
    if (criteriaFragments.length > 1) {
      const secondLastFragment =
        criteriaFragments[criteriaFragments.length - 2];
      const secondLastFragmentType = getFragmentType(
        secondLastFragment,
        applicationIndicators,
        patternCriteriaMap
      );

      return (
        ["OPERATOR"].includes(secondLastFragmentType.type) &&
        props.criteria !== editedCriteria
      );
    }
    return false;
  }, [
    patternCriteriaMap,
    editedCriteria,
    applicationIndicators,
    props.criteria,
  ]);

  const editedCriteriaIsValid = checkIfValid();

  const getCriteriaExplainer = (_candleSize: string) => {
    switch (_candleSize) {
      case "1D":
        return "This criteria will be checked once a day";
      case "1h":
        return "This criteria will be checked once an hour";
      case "5m":
        return "This criteria will be checked once every 5 minutes";
      default:
        return "";
    }
  };

  return (
    <div className={`new-case-editor-container`}>
      <div className="case-editor-header">
        <div className={`case-editor-label-container`}>
          <CommonButton
            compact
            activeTheme={props.activeTheme}
            onClick={() => setEditMode()}
            leftIcon={<FiArrowLeft />}
            label={`${props.candleSize} ${props.criteriaType} criteria`}
            borderTheme={
              props.criteriaType === "entry" ? "green-accent" : "red-accent"
            }
          />

          <motion.label
            initial={{ opacity: 0, x: -15 }}
            animate={{ opacity: 0.5, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="dimmed-label"
          >
            {getCriteriaExplainer(props.candleSize ?? "1D")}
          </motion.label>
        </div>
        <div className="case-editor-header-menu">
          <CalculationProfileMenu
            setSelectedCalculationProfile={setCalculationProfile}
            selectedCalculationProfile={calculationProfile}
            activeTheme={props.activeTheme}
          />
        </div>
      </div>
      <EditedCriteriaContext.Provider value={editedCriteria}>
        <div className="case-editor-indicators-container">
          {applicationIndicators && calculationProfile === "TA" ? (
            <TACodeFragmentsOverview
              criteriaType={props.criteriaType ?? "entry"}
              activeTheme={props.activeTheme}
              applicationIndicators={applicationIndicators}
              allowedFragmentTypes={allowedCodeFragments}
              addFragment={handleAddFragment}
              focusedFragmentIndex={focusedFragmentIndex}
              candleSize={props.candleSize}
              tradingCurrencies={props.tradingCurrencies}
              maxHeight={props.maxHeight}
            />
          ) : undefined}
          {applicationIndicators && calculationProfile === "AI" ? (
            <AICodeFragmentsOverview
              criteriaType={props.criteriaType ?? "entry"}
              activeTheme={props.activeTheme}
              applicationIndicators={applicationIndicators}
              allowedFragmentTypes={allowedCodeFragments}
              addFragment={handleAddFragment}
              focusedFragmentIndex={focusedFragmentIndex}
              candleSize={props.candleSize}
              tradingCurrencies={props.tradingCurrencies}
              removeCurrency={props.removeCurrency}
              maxHeight={props.maxHeight}
            />
          ) : undefined}
          {applicationIndicators && calculationProfile === "PT" ? (
            <PTCodeFragmentsOverview
              criteriaType={props.criteriaType ?? "entry"}
              activeTheme={props.activeTheme}
              applicationIndicators={applicationIndicators}
              allowedFragmentTypes={allowedCodeFragments}
              addFragment={handleAddFragment}
              focusedFragmentIndex={focusedFragmentIndex}
              candleSize={props.candleSize}
              tradingCurrencies={props.tradingCurrencies}
              maxHeight={props.maxHeight}
            />
          ) : undefined}
        </div>
      </EditedCriteriaContext.Provider>
      <div className="case-editor-result-container">
        {editedCriteria && (
          <ParsedCriteria
            activeTheme={props.activeTheme}
            criteria={editedCriteria}
            onClick={handleFragmentClick}
            focusedIndex={focusedFragmentIndex}
            indicatorsInfo={indicatorsInfo}
            editor
          />
        )}
      </div>
      <div className="bottom-container">
        <div className="bottom-left">
          <label className="dimmed-label">Click to focus and replace</label>
          <label className="dimmed-label">Right-click to delete</label>
        </div>
        <div className="bottom-right">
          <Tooltip label="Exit without saving">
            <div
              className={"new-criteria-editor-button exit-button"}
              onClick={() => setEditMode()}
            >
              <FiX size={33} />
            </div>
          </Tooltip>
          <Tooltip label="Undo">
            <div
              className={"new-criteria-editor-button undo-button"}
              id={
                props.criteria && props.criteria === editedCriteria
                  ? "new-criteria-editor-button-disabled"
                  : ""
              }
              onClick={() => {
                if (props.criteria && props.criteria === editedCriteria) return;
                setEditedCriteria(props.criteria ?? "");
              }}
            >
              <AiOutlineUndo size={33} />
            </div>
          </Tooltip>
          <Tooltip label="Save">
            <div
              className="new-criteria-editor-button"
              id={
                editedCriteriaIsValid
                  ? ""
                  : "new-criteria-editor-button-disabled"
              }
              onClick={(e: any) => {
                e.stopPropagation();
                if (!editedCriteriaIsValid) return;
                !props.criteria
                  ? props.handleAddCriteria(editedCriteria)
                  : props.handleReplaceCriteria(editedCriteria);
              }}
            >
              <FiCheck size={33} />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export const EditedCriteriaContext = createContext<string>("");
