import { getTheme } from "../../utils/themeUtil";
import "./neoton-spinner.scss";

interface Props {
  activeTheme: string;
  gray?: boolean;
  dark?: boolean;
  small?: boolean;
  style?: React.CSSProperties;
}
export function NeotonSpinner(props: React.PropsWithChildren<Props>) {
  const theme = getTheme(props.activeTheme);
  const root = document.documentElement;

  root?.style.setProperty(
    "--loader-color",
    props.dark ? theme.background2 : theme.neoton
  );
  return (
    <div
      className="circle-loader-container"
      style={props.style}
      id={props.gray ? "gray" : ""}
    >
      <div className={"loader-18" + (props.small ? " small" : "")}></div>
    </div>
  );
}
