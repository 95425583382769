import { MdPriceCheck } from "react-icons/md";
import { StrategyVersion } from "../interfaces/strategyInterfaces/Strategy";
import { ApplicationSettings } from "../types/ApplicationSettings";
import { FaNewspaper, FaRankingStar } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import { IoCodeSlashSharp } from "react-icons/io5";
import { RiExchangeDollarLine } from "react-icons/ri";
import { BsYoutube } from "react-icons/bs";
import { TWChartSettings } from "../interfaces/tw-chart/TWChartSettings";

/**
 *
 *
 * @param {string} storageName
 * @return {*}  {(string | null)}
 */
function readFromLocalStorage(storageName: string): string | null {
  const item = localStorage.getItem(storageName);
  if (item === null) {
    // only log when in development
    if (process.env.NODE_ENV === "development")
      console.debug(`Item ${storageName} does not exist in local storage`);
  }
  return item;
}

/**
 * Returns cached payload from Application Storage
 *
 * @param {string} storageName
 * @return {*}  {*}
 */
export const getCachedPayload = (storageName: string): any => {
  const cachedData: string | null = readFromLocalStorage(storageName);
  if (cachedData) {
    return JSON.parse(cachedData)["payload"];
  }
};

// holds all of the card ids
export const homepageCardIds: { [key: string]: string } = {
  news: "General",
  membership: "Account",
  tickers: "Tickers",
  get_started: "Tutorials",
  strategies: "Strategies",
  papertrader_leaderboard: "Papertrader leaderboard",
  trading_accounts: "Livetrading",
};

export const homepageCardIcons: { [key: string]: React.ReactNode } = {
  news: <FaNewspaper />,
  membership: <FaUserAlt />,
  tickers: <MdPriceCheck />,
  get_started: <BsYoutube />,
  strategies: <IoCodeSlashSharp />,
  papertrader_leaderboard: <FaRankingStar />,
  trading_accounts: <RiExchangeDollarLine />,
};
export const noRolesHomepageCardIds: { [key: string]: string } = {
  news: "General",
  membership: "Account",
  tickers: "Tickers",
  get_started: "Tutorials",
};

/**
 *
 *
 * @return {*}  {ApplicationSettings}
 */
export const tryGetApplicationSettings = (
  user_id?: string
): ApplicationSettings => {
  const applicationSettingsKey = user_id
    ? `ApplicationSettings_${user_id}`
    : "ApplicationSettings";
  const storedApplicationSettings = readFromLocalStorage(
    applicationSettingsKey
  );
  if (storedApplicationSettings) {
    const _applicationSettings = JSON.parse(
      storedApplicationSettings
    ) as ApplicationSettings;
    if (_applicationSettings.dashboardLayout === undefined) {
      _applicationSettings.dashboardLayout = {
        left: ["news", "membership", "tickers"],
        middle: ["get_started", "strategies"],
        right: ["papertrader_leaderboard", "trading_accounts"],
      };
    }
    if (_applicationSettings.dashboardLayoutIsLocked === undefined) {
      _applicationSettings.dashboardLayoutIsLocked = false;
    }
    if (_applicationSettings.particlesSettings === undefined) {
      _applicationSettings.particlesSettings = {
        amount: 50,
        enabled: false,
      };
    }
    if (_applicationSettings.enableTutorial === undefined) {
      _applicationSettings.enableTutorial = true;
    }
    return _applicationSettings;
  }
  const applicationSettings: ApplicationSettings = {
    theme: "dark",
    particlesSettings: {
      amount: 0,
      enabled: false,
    },
    dashboardLayout: {
      left: ["news", "membership", "tickers"],
      middle: ["get_started", "strategies"],
      right: ["papertrader_leaderboard", "trading_accounts"],
    },
    dashboardLayoutIsLocked: false,
    enableTutorial: true,
  };
  localStorage.setItem(
    applicationSettingsKey,
    JSON.stringify(applicationSettings)
  );
  return applicationSettings;
};

/**
 *
 *
 * @param {ApplicationSettings} applicationSettings
 */
export const persistApplicationSettings = (
  applicationSettings: ApplicationSettings
): void => {
  localStorage.setItem(
    "ApplicationSettings",
    JSON.stringify(applicationSettings)
  );
};

export const persistTWChartSettings = (
  twChartSettings: TWChartSettings
): void => {
  localStorage.setItem("TWChartSettings", JSON.stringify(twChartSettings));
};

export const getDefaultTWChartSettings = (): TWChartSettings => {
  const twChartSettings: TWChartSettings = {
    main: {
      grid: true,
      volume: true,
    },
    paneSettings: {
      main: {
        id: "main",
        title: "Main",
        heightFactor: 100,
        order: 0,
      },
    },
  };
  return twChartSettings;
}

export const tryGetStoredTWChartSettings = (): TWChartSettings => {
  const storedTWChartSettings = readFromLocalStorage("TWChartSettings");
  if (!storedTWChartSettings) {
    const twChartSettings: TWChartSettings = getDefaultTWChartSettings();
    localStorage.setItem("TWChartSettings", JSON.stringify(twChartSettings));
    return twChartSettings;
  }
  const _TWChartSettings = JSON.parse(storedTWChartSettings) as TWChartSettings;
  // sanitize the data
  if (!_TWChartSettings.main) {
    _TWChartSettings.main = {
      grid: true,
      volume: true,
    };
  }
  if (!_TWChartSettings.paneSettings) {
    _TWChartSettings.paneSettings = {
      main: {
        id: "main",
        title: "Main",
        heightFactor: 100,
        order: 0,
      },
    };
  }
  // look through each indicator and ensure that the config is present and the values are numeric - remove any non-numeric values
  if (_TWChartSettings.taIndicators) {
    _TWChartSettings.taIndicators.forEach((indicator) => {
      if (!indicator.config) {
        indicator.config = {};
      }
      Object.keys(indicator.config).forEach((key) => {
        if (isNaN(indicator.config[key])) {
          delete indicator.config[key];
        }
      });
    });
  }
  localStorage.setItem("TWChartSettings", JSON.stringify(_TWChartSettings));
  return _TWChartSettings;
};

export const tryGetStoredStrategy = (): StrategyVersion | undefined => {
  const storedStrategy = readFromLocalStorage("Strategy");
  if (!storedStrategy) {
    localStorage.removeItem("Strategy");
    return;
  }
  return JSON.parse(storedStrategy) as StrategyVersion;
};
export const tryGetUnsavedStrategy = (): StrategyVersion | undefined => {
  const storedStrategy = readFromLocalStorage("unsavedStrategy");
  if (!storedStrategy) {
    localStorage.removeItem("unsavedStrategy");
    return;
  }
  return JSON.parse(storedStrategy) as StrategyVersion;
};

export const tryDeleteUnsavedStrategy = (): void => {
  localStorage.removeItem("unsavedStrategy");
};
