import { useCallback, useContext, useState } from "react";
import { ApplicationIndicators } from "../../../../interfaces/Indicators";
import "./code-fragments-overview.scss";
import { CriteriaFragment } from "../../criteria-fragments/CriteriaFragment";
import { Popover } from "@mantine/core";
import { FragmentType } from "../../../../utils/ParseCriteriaUtil";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import { motion } from "framer-motion";
import {
  ensurePaneSettingsCorrectness,
  getDefaultHeightForIndicator,
  getDefaultTAIndicators,
  getTaIndicatorLongName,
} from "../../../../utils/TAIndicatorUtil";
import { IndicatorControlButton } from "../../../chart-components/trading-view-chart/IndicatorControlButton";
import {
  TAIndicator,
  TAIndicators,
  TaIndicatorsSupportOnChartUpperCase,
} from "../../../../interfaces/tw-chart/TWChartSettings";
import { MdSsidChart } from "react-icons/md";
import {
  SetTWChartSettingsContext,
  TWChartSettingsContext,
} from "../../../../pages/strategy-page/StrategyPage";

interface Props {
  activeTheme: string;
  applicationIndicators: ApplicationIndicators;
  criteriaType: string;
  allowedFragmentTypes: string[];
  focusedFragmentIndex: number | undefined;
  addFragment: (fragment: string) => void;
  candleSize: string;
  tradingCurrencies: string[];
  maxHeight?: number;
}
export function TACodeFragmentsOverview(props: React.PropsWithChildren<Props>) {
  const [customValuePopoverOpened, setCustomValuePopoverOpened] =
    useState(false);
  const [customValueInput, setCustomValueInput] = useState<number>();
  const [directionsPopoverOpened, setDirectionsPopoverOpened] = useState(false);
  const [directionsIndicator, setDirectionsIndicator] = useState<string>();
  const twChartSettings = useContext(TWChartSettingsContext);
  const setTwChartSettings = useContext(SetTWChartSettingsContext);

  const simpleOperators = [
    { directionsCompatible: true, value: "=", explanation: "equals" },
    { directionsCompatible: false, value: ">", explanation: "greater than" },
    { directionsCompatible: false, value: "<", explanation: "less than" },
    {
      directionsCompatible: false,
      value: ">=",
      explanation: "greater than or equal to",
    },
    {
      directionsCompatible: false,
      value: "<=",
      explanation: "less than or equal to",
    },
  ];

  const advancedOperators = [
    { directionsCompatible: false, value: "*", explanation: "times" },
    { directionsCompatible: false, value: "/", explanation: "divided by" },
    { directionsCompatible: false, value: "+", explanation: "plus" },
    { directionsCompatible: false, value: "-", explanation: "minus" },
  ];

  const valueFragmentType: FragmentType = {
    className: "fragment-badge-number",
    fragment: "numeric value",
    type: "VALUE",
  };

  const candles = ["OPEN", "HIGH", "LOW", "CLOSE"];

  const handleAddIndicatorToChart = useCallback(
    (indicatorKey: string) => {
      if (!TaIndicatorsSupportOnChartUpperCase.includes(indicatorKey)) return;
      const indicatorType: TAIndicator["type"] =
        indicatorKey as TAIndicator["type"];
      const defaultTAIndicators = getDefaultTAIndicators(indicatorType);
      const newTaIndicators = [...(twChartSettings?.taIndicators ?? [])];
      const existingTaIndicatorIds = newTaIndicators.map(
        (indicator) => indicator.id
      );
      let paneSettings = twChartSettings?.paneSettings || {};

      defaultTAIndicators.forEach((taIndicator) => {
        if (existingTaIndicatorIds.includes(taIndicator.id)) return;
        newTaIndicators.push(taIndicator);
        if (taIndicator.separatePane) {
          // If there's no pane setting with the indicator's id, create one
          if (!paneSettings[taIndicator.id]) {
            paneSettings[taIndicator.id] = {
              id: taIndicator.id,
              title: getTaIndicatorLongName(taIndicator.type),
              heightFactor: getDefaultHeightForIndicator(taIndicator),
              order: Object.keys(paneSettings).length, // lower order means higher up
            };
          }
        }
      });
      setTwChartSettings?.((prev) => ({
        ...prev,
        taIndicators: newTaIndicators,
        paneSettings: ensurePaneSettingsCorrectness(paneSettings),
      }));
    },
    [twChartSettings, setTwChartSettings]
  );

  const addCustomInputValue = useCallback(() => {
    if (customValueInput) {
      props.addFragment(customValueInput.toString());
      setCustomValueInput(undefined);
      setCustomValuePopoverOpened(false);
    }
  }, [
    props,
    customValueInput,
    setCustomValueInput,
    setCustomValuePopoverOpened,
  ]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        event.key === "Enter" &&
        customValuePopoverOpened &&
        customValueInput
      ) {
        addCustomInputValue();
      }
    },
    [customValuePopoverOpened, customValueInput, addCustomInputValue]
  );

  const holdingFragments: FragmentType[] = [
    {
      fragment: "PROFIT_PCT",
      type: "VARIABLE",
      className: "fragment-badge-candle",
    },
    {
      fragment: "BUY_PRICE",
      type: "VARIABLE",
      className: "fragment-badge-candle",
    },
    {
      fragment: "PEAK_PRICE",
      type: "VARIABLE",
      className: "fragment-badge-candle",
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, maxHeight: props.maxHeight }}
      transition={{ duration: 0.5, delay: 0.3 }}
      exit={{ opacity: 0 }}
      className="code-fragments-overview-container"
    >
      {Object.keys(props.applicationIndicators.ta_categories).map(
        (category) => {
          const categoryIndicators =
            props.applicationIndicators.ta_categories[category];
          if (categoryIndicators.length === 0) return null;
          return (
            <div key={category} className="code-fragments-container">
              <label
                style={{
                  textTransform: "capitalize",
                }}
              >
                {category} indicators
              </label>
              {Object.keys(
                props.applicationIndicators.indicators.ta_indicators
              ).map((indicator, index) => {
                if (!categoryIndicators.includes(indicator)) return null;
                return (
                  <div
                    key={indicator + "-" + index}
                    className="code-fragment-row-container"
                    style={{
                      paddingTop: 10,
                    }}
                  >
                    <IndicatorControlButton
                      activeTheme={props.activeTheme}
                      indicatorLabel={indicator}
                      handleSelect={() => handleAddIndicatorToChart(indicator)}
                      disabled={
                        !TaIndicatorsSupportOnChartUpperCase.includes(indicator)
                      }
                      leftChild={<MdSsidChart />}
                    />

                    {props.applicationIndicators.indicators.ta_indicators[
                      indicator
                    ].map((indicatorFragment, index) => {
                      const fragmentType: FragmentType = {
                        fragment: indicatorFragment,
                        type: "VARIABLE",
                        className: "fragment-badge-variable-ta",
                      };
                      const directionsFragmentType: FragmentType = {
                        fragment: `_DIRECTIONS`,
                        type: "DIRECTION",
                        className: "fragment-badge-variable-ta",
                      };
                      return (
                        <div
                          className="ta-indicator-code-pair"
                          key={indicatorFragment + "-" + index}
                        >
                          <div
                            key={indicatorFragment + "-" + index}
                            className="code-fragment-container"
                            id={
                              props.allowedFragmentTypes.includes(
                                fragmentType.type
                              )
                                ? ""
                                : "disabled-code-fragment"
                            }
                            onClick={(e: any) => {
                              e.stopPropagation();
                              if (
                                !props.allowedFragmentTypes.includes(
                                  fragmentType.type
                                )
                              )
                                return;
                              props.addFragment(fragmentType.fragment);
                            }}
                          >
                            <CriteriaFragment
                              indicators={
                                props.applicationIndicators.indicators
                              }
                              activeTheme={props.activeTheme}
                              fragmentType={fragmentType}
                              index={index}
                              small
                            />
                          </div>
                          <FiArrowRight size={10} />
                          <Popover
                            withArrow
                            classNames={{
                              body: "directions-popup-body",
                              wrapper: "directions-popup-wrapper",
                              inner: "directions-popup-inner",
                              arrow: "directions-popup-inner",
                            }}
                            transition={"scale-y"}
                            position="top"
                            opened={
                              directionsPopoverOpened &&
                              directionsIndicator === fragmentType.fragment
                            }
                            onClose={() => {
                              setDirectionsIndicator(undefined);
                              setDirectionsPopoverOpened(false);
                            }}
                            target={
                              <div
                                key={
                                  indicatorFragment + "-" + index + "-direction"
                                }
                                className="code-fragment-container"
                                id={
                                  props.allowedFragmentTypes.includes(
                                    directionsFragmentType.type
                                  )
                                    ? ""
                                    : "disabled-code-fragment"
                                }
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  if (
                                    !props.allowedFragmentTypes.includes(
                                      directionsFragmentType.type
                                    )
                                  )
                                    return;
                                  setDirectionsIndicator(fragmentType.fragment);
                                  setDirectionsPopoverOpened(true);
                                }}
                              >
                                <CriteriaFragment
                                  indicators={
                                    props.applicationIndicators.indicators
                                  }
                                  activeTheme={props.activeTheme}
                                  fragmentType={directionsFragmentType}
                                  index={index}
                                  small
                                />
                              </div>
                            }
                          >
                            <div
                              className="code-fragment-container"
                              id={
                                props.allowedFragmentTypes.includes(
                                  directionsFragmentType.type
                                )
                                  ? ""
                                  : "disabled-code-fragment"
                              }
                              onClick={(e: any) => {
                                e.stopPropagation();
                                if (
                                  !props.allowedFragmentTypes.includes(
                                    directionsFragmentType.type
                                  )
                                )
                                  return;
                                props.addFragment(
                                  `${
                                    fragmentType.fragment +
                                    directionsFragmentType.fragment
                                  } = 1.0`
                                );
                                setDirectionsPopoverOpened(false);
                              }}
                            >
                              <CriteriaFragment
                                indicators={
                                  props.applicationIndicators.indicators
                                }
                                activeTheme={props.activeTheme}
                                fragmentType={{
                                  ...directionsFragmentType,
                                  fragment: "UP",
                                }}
                                index={index}
                                small
                              />
                            </div>
                            <div
                              className="code-fragment-container"
                              id={
                                props.allowedFragmentTypes.includes(
                                  directionsFragmentType.type
                                )
                                  ? ""
                                  : "disabled-code-fragment"
                              }
                              onClick={(e: any) => {
                                e.stopPropagation();
                                if (
                                  !props.allowedFragmentTypes.includes(
                                    directionsFragmentType.type
                                  )
                                )
                                  return;
                                props.addFragment(
                                  `${
                                    fragmentType.fragment +
                                    directionsFragmentType.fragment
                                  } = -1.0`
                                );
                                setDirectionsPopoverOpened(false);
                              }}
                            >
                              <CriteriaFragment
                                indicators={
                                  props.applicationIndicators.indicators
                                }
                                activeTheme={props.activeTheme}
                                fragmentType={{
                                  ...directionsFragmentType,
                                  fragment: "DOWN",
                                }}
                                index={index}
                                small
                              />
                            </div>
                          </Popover>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        }
      )}

      <div className="top-right-container">
        <label>General</label>
        <br />
        <label className="dimmed-label">Prices</label>
        <div className="code-fragments-container">
          {candles.map((candle, index) => {
            const fragmentType: FragmentType = {
              fragment: candle,
              type: "VARIABLE",
              className: "fragment-badge-candle",
            };
            return (
              <div
                key={candle + "-" + index}
                className="code-fragment-container"
                id={
                  props.allowedFragmentTypes.includes(fragmentType.type)
                    ? ""
                    : "disabled-code-fragment"
                }
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (!props.allowedFragmentTypes.includes(fragmentType.type))
                    return;
                  props.addFragment(fragmentType.fragment);
                }}
              >
                <CriteriaFragment
                  indicators={props.applicationIndicators.indicators}
                  activeTheme={props.activeTheme}
                  fragmentType={fragmentType}
                  index={index}
                  small
                />
              </div>
            );
          })}
        </div>
        {props.criteriaType === "exit" && (
          <>
            <label className="dimmed-label">Holding specific variables</label>

            {holdingFragments.map((fragmentType, index) => {
              return (
                <div
                  key={fragmentType.fragment + "-" + index}
                  className="code-fragment-container"
                  id={
                    props.allowedFragmentTypes.includes(fragmentType.type)
                      ? ""
                      : "disabled-code-fragment"
                  }
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (!props.allowedFragmentTypes.includes(fragmentType.type))
                      return;
                    props.addFragment(fragmentType.fragment);
                  }}
                >
                  <CriteriaFragment
                    indicators={props.applicationIndicators.indicators}
                    activeTheme={props.activeTheme}
                    fragmentType={fragmentType}
                    index={index}
                    large
                  />
                </div>
              );
            })}
          </>
        )}
        <label className="dimmed-label">Operators</label>
        <div className="code-fragments-container">
          {simpleOperators.map((operator, index) => {
            const fragmentType: FragmentType = {
              fragment: operator.value,
              type: "OPERATOR",
              className: "fragment-badge-operator",
            };
            return (
              <div
                key={operator.value + "-" + index}
                className="code-fragment-container"
                id={
                  props.allowedFragmentTypes.includes(fragmentType.type)
                    ? ""
                    : "disabled-code-fragment"
                }
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (!props.allowedFragmentTypes.includes(fragmentType.type))
                    return;
                  props.addFragment(fragmentType.fragment);
                }}
              >
                <CriteriaFragment
                  indicators={props.applicationIndicators.indicators}
                  activeTheme={props.activeTheme}
                  fragmentType={fragmentType}
                  index={index}
                  large
                />
              </div>
            );
          })}
        </div>
        <div className="code-fragments-container">
          {advancedOperators.map((operator, index) => {
            const fragmentType: FragmentType = {
              fragment: operator.value,
              type: "ADVANCED_OPERATOR",
              className: "fragment-badge-operator",
            };
            return (
              <div
                key={operator.value + "-" + index}
                className="code-fragment-container"
                id={
                  props.allowedFragmentTypes.includes(fragmentType.type)
                    ? ""
                    : "disabled-code-fragment"
                }
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (!props.allowedFragmentTypes.includes(fragmentType.type))
                    return;
                  props.addFragment(fragmentType.fragment);
                }}
              >
                <CriteriaFragment
                  indicators={props.applicationIndicators.indicators}
                  activeTheme={props.activeTheme}
                  fragmentType={fragmentType}
                  index={index}
                  large
                />
              </div>
            );
          })}
        </div>
        <div className="code-fragments-container">
          <Popover
            opened={customValuePopoverOpened}
            withArrow
            classNames={{
              inner: "directions-popup-inner",
              arrow: "directions-popup-inner",
            }}
            width={150}
            position="right"
            onClose={() => setCustomValuePopoverOpened(false)}
            target={
              <div
                className="code-fragment-container"
                id={
                  props.allowedFragmentTypes.includes(valueFragmentType.type)
                    ? ""
                    : "disabled-code-fragment"
                }
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (
                    !props.allowedFragmentTypes.includes(valueFragmentType.type)
                  )
                    return;
                  setCustomValuePopoverOpened(true);
                }}
              >
                <CriteriaFragment
                  indicators={props.applicationIndicators.indicators}
                  activeTheme={props.activeTheme}
                  fragmentType={valueFragmentType}
                  index={0}
                  large
                />
              </div>
            }
          >
            <div className="custom-number-input-container">
              <input
                maxLength={13}
                value={customValueInput}
                type="number"
                className="custom-number-input"
                onChange={(e: any) => {
                  setCustomValueInput(e.target.value);
                }}
                onKeyDown={handleKeyPress}
              />
              {customValueInput && (
                <FiCheck onClick={addCustomInputValue} size={20} />
              )}
            </div>
          </Popover>
        </div>
      </div>
    </motion.div>
  );
}
