import { Currency } from "../interfaces/Currency";

export const getCurrencyUrl = (currency: Currency): string => {
  return `https://neoton-media.azureedge.net/currency-assets/${currency.slug}.png`;
};

/**
 * Generates the URL for a GIF file stored in the Azure CDN.
 *
 * @param {string} gifName - The name of the GIF file.
 * @returns {string} The full URL to the GIF file in the Azure CDN.
 */
export const getGifUrl = (gifName: string): string => {
  return `https://neoton-media.azureedge.net/currency-assets/${gifName}`;
};

export const getProfilePictureUrl = (userHash: string): string => {
  return `https://neoton-media.azureedge.net/profile-pics/${userHash}.jpg`;
};

export const getExchangeLogoUrl = (
  exchange_id: string,
  activeTheme: string = "dark"
) => {
  const theme = activeTheme === "light" ? "light" : "dark"; // night mode is also using dark theme
  const neotonPrefix = "NTexchange";
  return `https://neoton-media.azureedge.net/currency-assets/${neotonPrefix}-${exchange_id}-${theme}.png`;
};

export const getBaseCurrencyUrl = (
  baseCurrency: BaseCurrencyType | undefined | null
) => {
  if (!baseCurrency || baseCurrency === null) return "";
  switch (baseCurrency) {
    case "USD":
      return `https://neoton-media.azureedge.net/currency-assets/fiat-USD.png`;
    case "USDT":
      return `https://neoton-media.azureedge.net/currency-assets/tether.png`;
    case "USDC":
      return `https://neoton-media.azureedge.net/currency-assets/usd-coin.png`;
    default:
      return "";
  }
};

export const getStarterProfilePicUrl = (name: string) => {
  return `https://neoton-media.azureedge.net/starter-profile-pics/${name}`;
};

export const getAchievementUrl = (name: string) => {
  return `https://neoton-media.azureedge.net/achievement-icons/${name}.png`;
};

export type BaseCurrencyType = "USD" | "USDT" | "USDC";
