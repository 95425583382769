import "./wallet-value-chart-no-data.scss";
import { useCallback, useMemo, useState } from "react";
import {
  Legend,
  ResponsiveContainer,
  TooltipProps,
  Tooltip,
  XAxis,
  YAxis,
  AreaChart,
  Area,
} from "recharts";
import { CommonWalletValueSnapshots } from "../../../../interfaces/papertrader/PaperwalletPreview";
import { toUTCTimestring } from "../../../../utils/FormattingUtils";
import { getTheme } from "../../../../utils/themeUtil";
import { MarketStatistics } from "../../../../interfaces/common/MarketStatisticsPayload";

interface Props {
  activeTheme: string;
  currencyPair?: string;
  isWinning: boolean;
  walletValueSnapshots: CommonWalletValueSnapshots | undefined;
  marketStatistics: MarketStatistics | undefined;
  compact?: boolean;
  animate?: boolean;
  animationDuration?: number;
  disableTooltip?: boolean;
  heightOverride?: number | string;
  candleSize?: string;
  colorLess?: boolean;
  chartWidth?: number;
  chartHeight?: number;
  nonResponsive?: boolean;
  syncStart?: boolean
}
export function WalletValueChart(props: React.PropsWithChildren<Props>) {
  const [marketVisible, setMarketVisible] = useState(true);
  const [walletVisible, setWalletVisible] = useState(true);

  const theme = getTheme(props.activeTheme);

  const timestamps = props.walletValueSnapshots?.timestamps;

  const createWalletValueSeries = (
    _walletValueSnapshots: CommonWalletValueSnapshots | undefined,
    marketData:
      | {
          [key: string]: number;
        }
      | undefined
  ) => {
    const series: any[] = [];
    if (timestamps && _walletValueSnapshots) {
      for (let i = 0; i < timestamps.length; i++) {
        const timestamp = parseInt(timestamps[i]);
        const timeString = toUTCTimestring(timestamp, props.candleSize ?? "1D");
        series.push({
          x: timeString,
          Wallet: _walletValueSnapshots.valueSnapshots[i],
          Market: marketData ? marketData[timeString] : undefined,
        });
      }
    }
    if (!_walletValueSnapshots && props.marketStatistics) {
      for (let i = 0; i < props.marketStatistics.timestamps.length; i++) {
        const timeString = toUTCTimestring(
          props.marketStatistics.timestamps[i],
          props.candleSize ?? "1D"
        );
        series.push({
          x: timeString,
          Wallet: undefined,
          Market: props.marketStatistics.valueSnapshots[i],
        });
      }
    }
    // if sync start is enabled, the and we have both the wallet and market data we need to set the first point of the market data to the first point of the wallet data
    if (props.syncStart && series.length > 0) {
      series[0].Market = series[0].Wallet;
    }
    return series;
  };

  const createMarketStatisticsMap = (_marketStatistics: MarketStatistics) => {
    let map: { [key: string]: number } = {};
    const _timestamps = _marketStatistics.timestamps;
    const _marketValues = _marketStatistics.valueSnapshots;
    for (let i = 0; i < _timestamps.length; i++) {
      const timestamp = _timestamps[i];
      map[toUTCTimestring(timestamp, props.candleSize ?? "1D")] =
        _marketValues[i];
    }
    return map;
  };

  const marketData = props.marketStatistics
    ? createMarketStatisticsMap(props.marketStatistics)
    : undefined;

  const dataSeries = createWalletValueSeries(
    props.walletValueSnapshots,
    marketData
  );

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<number, string>) => {
    if (active && label && payload) {
      return (
        <div className="custom-tooltip">
          <div className="price-and-date-container">
            <label>{label}</label>
            {walletVisible && props.walletValueSnapshots ? (
              <label color={"dimmed"}>
                Wallet: <strong>{payload?.[0]?.value?.toFixed(2)} $</strong>
              </label>
            ) : undefined}

            {marketVisible && marketData ? (
              <label color={"dimmed"}>
                Market:{" "}
                <strong>
                  {payload?.[
                    props.walletValueSnapshots ? 1 : 0
                  ]?.value?.toFixed(2)}{" "}
                  $
                </strong>
              </label>
            ) : undefined}
          </div>
        </div>
      );
    }

    return null;
  };

  const calculateVariance = (values) => {
    const mean = values.reduce((acc, val) => acc + val, 0) / values.length;
    return (
      values.reduce((acc, val) => acc + (val - mean) ** 2, 0) / values.length
    );
  };

  const minY = useMemo(() => {
    if (!dataSeries) return 0;
    const values = dataSeries
      .map((d) => d.Wallet ?? d.Market)
      .filter((val) => val !== undefined);
    return Math.min(...values);
  }, [dataSeries]);

  const maxY = useMemo(() => {
    if (!dataSeries) return 0;
    const values = dataSeries
      .map((d) => d.Wallet ?? d.Market)
      .filter((val) => val !== undefined);
    return Math.max(...values);
  }, [dataSeries]);

  const variance = useMemo(() => {
    const values = dataSeries
      .map((d) => d.Wallet ?? d.Market)
      .filter((val) => val !== undefined);
    return calculateVariance(values);
  }, [dataSeries]);

  const padding = useMemo(() => {
    // Adjust padding based on variance
    return variance < 0.01 ? 0.05 : (maxY - minY) * 0.1;
  }, [minY, maxY, variance]);

  const paddedMinY = useMemo(() => minY - padding, [minY, padding]);
  const paddedMaxY = useMemo(() => maxY + padding, [maxY, padding]);

  const gradientId = useMemo(() => {
    return props.colorLess
      ? "colorLessGradient"
      : props.isWinning
      ? "winningGradient"
      : "losingGradient";
  }, [props.colorLess, props.isWinning]);

  const fillColor = useMemo(() => {
    return props.colorLess
      ? theme.cardInterior
      : props.isWinning
      ? theme.buyOrderStrokeHover
      : theme.sellOrderAreaX;
  }, [
    props.colorLess,
    props.isWinning,
    theme.cardInterior,
    theme.buyOrderStrokeHover,
    theme.sellOrderAreaX,
  ]);

  const renderAreaChart = useCallback(() => {
    return (
      <AreaChart
        width={props.chartWidth ?? 400}
        height={props.chartHeight}
        data={dataSeries}
      >
        {paddedMinY && paddedMaxY && (
          <YAxis domain={[paddedMinY, paddedMaxY]} hide />
        )}
        <XAxis dataKey="x" hide />
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={fillColor} stopOpacity={0.5} />
            <stop offset="80%" stopColor={fillColor} stopOpacity={0} />
          </linearGradient>
        </defs>
        {walletVisible && (
          <Area
            type="monotone"
            dataKey="Wallet"
            stroke={
              props.colorLess
                ? theme.cardInterior
                : props.isWinning
                ? theme.buyOrderStrokeHover
                : theme.sellOrderStrokeHover
            }
            fill={`url(#${gradientId})`} // Use the gradient here
            dot={false}
            activeDot={false}
            strokeWidth={2}
          />
        )}
        {marketVisible && (
          <Area
            type="monotone"
            dataKey="Market"
            stroke={theme.marketReturnsLine}
            strokeDasharray="6 6"
            fill={"transparent"}
            strokeWidth={1}
            dot={false}
            activeDot={false}
            isAnimationActive={props.animate ?? false}
            animationDuration={props.animationDuration ?? 1000}
          />
        )}

        {!props.disableTooltip && <Tooltip content={<CustomTooltip />} />}
        {!props.compact && (
          <Legend
            onClick={(e) =>
              e.dataKey === "Market"
                ? setMarketVisible(!marketVisible)
                : setWalletVisible(!walletVisible)
            }
            inactiveColor={theme.checkboxBackground}
            wrapperStyle={{
              bottom: 70,
              left: 10,
              height: 0,
              width: 100,
            }}
          />
        )}
      </AreaChart>
    );
  }, [
    dataSeries,
    fillColor,
    gradientId,
    marketVisible,
    paddedMaxY,
    paddedMinY,
    props.animate,
    props.animationDuration,
    props.colorLess,
    props.compact,
    props.disableTooltip,
    props.isWinning,
    theme,
    walletVisible,
    props.chartWidth,
    props.chartHeight,
  ]);

  return (
    <>
      {props.nonResponsive && dataSeries.length > 0 ? (
        renderAreaChart()
      ) : (
        <>
          {dataSeries.length > 0 && (
            <ResponsiveContainer
              width="99%"
              height={props.heightOverride ?? "99%"}
            >
              {renderAreaChart()}
            </ResponsiveContainer>
          )}
        </>
      )}
    </>
  );
}
