import { useMemo } from "react";
import { PendingOrder } from "../../../interfaces/common-trading/CommonTradingReport";
import { OrderProfileChart } from "../../chart-components/order-profile-chart/OrderProfileChart";
import { OrderProfile } from "../../../interfaces/strategyInterfaces/Strategy";

interface Props {
  activeTheme: string;
  pendingOrder: PendingOrder;
}
export function PendingOrderPopup(props: React.PropsWithChildren<Props>) {
  type OrderProfileVisualResources = {
    orderProfile: OrderProfile;
    bid: number;
    ask: number;
    attempt: number;
    atr_value: number;
  };

  const orderProfileVisualResources: OrderProfileVisualResources | undefined =
    useMemo(() => {
      const params = props.pendingOrder.parameters;
      if (!params) return undefined;
      if (
        !params.bid ||
        !params.ask ||
        !params.atr_value ||
        !props.pendingOrder.attempt ||
        !params.orderProfile
      )
        return undefined;
      return {
        orderProfile: params.orderProfile,
        bid: params.bid,
        ask: params.ask,
        attempt: props.pendingOrder.attempt,
        atr_value: params.atr_value,
      };
    }, [props.pendingOrder]);

  return (
    <>
      {orderProfileVisualResources !== undefined && (
        <div className="pending-order-profile-popup">
          <OrderProfileChart
            activeTheme={props.activeTheme}
            orderProfile={orderProfileVisualResources.orderProfile}
            profileMode={props.pendingOrder.side === "buy" ? "entry" : "exit"}
            ask={orderProfileVisualResources.ask}
            bid={orderProfileVisualResources.bid}
            atr={orderProfileVisualResources.atr_value}
            attempt={orderProfileVisualResources.attempt}
            hideXAxis
            hideYAxis
          />
        </div>
      )}
    </>
  );
}
