import { Popover, Select } from "@mantine/core";
import { useContext, useMemo, useState } from "react";
import { UserItemsContext } from "../../../App";
import {
  OrderProfile,
  DefaultBuyOrderProfile,
  DefaultOptimisticBuyOrderProfile,
  DefaultSellOrderProfile,
  DefaultOptimisticSellOrderProfile,
} from "../../../interfaces/strategyInterfaces/Strategy";
import { OrderProfileChart } from "../../chart-components/order-profile-chart/OrderProfileChart";
import { RiExchangeLine } from "react-icons/ri";
import { TbBellDollar } from "react-icons/tb";
import { getTheme } from "../../../utils/themeUtil";

interface Props {
  activeTheme: string;
  label: string;
  mode: "entry" | "exit";
  tpEnabled?: boolean;
  slEnabled?: boolean;
  readonly?: boolean;
  selectedEntryProfile?: OrderProfile;
  selectedExitProfile?: OrderProfile;
  selectedExitTpProfile?: OrderProfile;
  selectedExitSlProfile?: OrderProfile;
  onSelectEntryProfile?: (profile: OrderProfile) => void;
  onSelectExitProfile?: (profile: OrderProfile) => void;
  onSelectExitTpProfile?: (profile: OrderProfile) => void;
  onSelectExitSlProfile?: (profile: OrderProfile) => void;
}
export function OrderProfileSelect(props: React.PropsWithChildren<Props>) {
  const theme = useMemo(() => {
    return getTheme(props.activeTheme);
  }, [props.activeTheme]);

  const defaultBuyProfiles: OrderProfile[] = [
    DefaultBuyOrderProfile,
    DefaultOptimisticBuyOrderProfile,
  ];
  const defaultSellProfiles: OrderProfile[] = [
    DefaultSellOrderProfile,
    DefaultOptimisticSellOrderProfile,
  ];

  const userItems = useContext(UserItemsContext);
  const _AllBuyProfileEntries = useMemo(() => {
    const entries: any[] = [];
    // Add default buy profiles
    defaultBuyProfiles.forEach((profile) => {
      entries.push({
        value: profile.name,
        label: profile.name,
        group: "Default order profiles",
      });
    });
    // Add user items
    if ((userItems?.order_profiles?.buy?.length ?? 0) > 0) {
      userItems!.order_profiles!.buy.forEach((profile) => {
        entries.push({
          value: profile.name,
          label: profile.name,
          group: "Custom order profiles",
        });
      });
    }

    return entries;
  }, [userItems, defaultBuyProfiles]);

  const _AllSellProfileEntries = useMemo(() => {
    const entries: any[] = [];
    // Add default sell profiles
    defaultSellProfiles.forEach((profile) => {
      entries.push({
        value: profile.name,
        label: profile.name,
        group: "Default order profiles",
      });
    });
    // Add user items
    if ((userItems?.order_profiles?.sell?.length ?? 0) > 0) {
      userItems!.order_profiles!.sell.forEach((profile) => {
        entries.push({
          value: profile.name,
          label: profile.name,
          group: "Custom order profiles",
        });
      });
    }

    return entries;
  }, [userItems, defaultSellProfiles]);

  const allBuyProfiles = useMemo(() => {
    return [...defaultBuyProfiles, ...(userItems?.order_profiles?.buy ?? [])];
  }, [userItems, defaultBuyProfiles]);

  const allSellProfiles = useMemo(() => {
    return [...defaultSellProfiles, ...(userItems?.order_profiles?.sell ?? [])];
  }, [userItems, defaultSellProfiles]);

  const [entryProfileOpened, setEntryProfileOpened] = useState(false);
  const [exitProfileOpened, setExitProfileOpened] = useState(false);
  const [exitTpProfileOpened, setExitTpProfileOpened] = useState(false);
  const [exitSlProfileOpened, setExitSlProfileOpened] = useState(false);

  const popoverClassNames = {
    inner: "order-profile-preview-popover-inner",
    target: "order-profile-preview-popover-target",
    arrow: "order-profile-preview-popover-arrow",
    body: "order-profile-preview-popover-body",
    root: "order-profile-preview-popover-root",
    wrapper: "order-profile-preview-popover-wrapper",
    popover: "order-profile-preview-popover-popover",
  };

  const popoverStyle = {
    width: "200px",
    height: "150px",
  };

  const selectStyle = {
    input: {
      transition: ".2s ease all",
    },
    separator: {
      color: theme.neoton,
    },
    item: {
      fontFamily: "BalooBhaijaan2",
    },
    selected: {
      fontFamily: "BalooBhaijaan2",
      color: theme.neoton,
    },
  };

  const selectClassnames = {
    selected: "order-profile-select-selected",
    input: "order-profile-select-input",
  };

  return (
    <div className={"order-profile-select " + props.mode}>
      <label className="dimmed-label">
        {props.mode === "entry" ? "Entry" : "Exit"} Profiles
      </label>
      <div className="order-profile-select-column">
        {props.mode === "entry" ? (
          <div className="order-profile-select-row">
            <Popover
              opened={entryProfileOpened}
              onClose={() => setEntryProfileOpened(false)}
              withArrow
              clickOutsideEvents={["click", "onClick"]}
              radius={"md"}
              classNames={popoverClassNames}
              target={
                <div
                  onClick={() => setEntryProfileOpened(!entryProfileOpened)}
                  className={
                    "order-profile-preview-target entry " +
                    (entryProfileOpened ? "opened" : "")
                  }
                >
                  <RiExchangeLine />
                  Entry
                </div>
              }
            >
              <div style={popoverStyle}>
                <OrderProfileChart
                  activeTheme={props.activeTheme}
                  orderProfile={
                    props.selectedEntryProfile ?? DefaultBuyOrderProfile
                  }
                  hideXAxis
                  hideYAxis
                  profileMode="entry"
                />
              </div>
            </Popover>
            <Select
              className="order-profile-select"
              disabled={props.readonly}
              classNames={selectClassnames}
              styles={selectStyle}
              clearable={false}
              defaultValue={
                _AllBuyProfileEntries.find(
                  (x) => x.value === props.selectedEntryProfile?.name
                )?.value ?? _AllBuyProfileEntries[0].value
              }
              value={
                _AllBuyProfileEntries.find(
                  (x) => x.value === props.selectedEntryProfile?.name
                )?.value ?? _AllBuyProfileEntries[0].value
              } // Default to first entry
              onChange={(value) => {
                const profile = allBuyProfiles.find((x) => x.name === value);
                if (profile) {
                  props.onSelectEntryProfile &&
                    props.onSelectEntryProfile(profile);
                }
              }}
              size="xs"
              data={_AllBuyProfileEntries}
            />
          </div>
        ) : (
          <>
            <div className="order-profile-select-row">
              <Popover
                opened={exitProfileOpened}
                onClose={() => setExitProfileOpened(false)}
                withArrow
                clickOutsideEvents={["click", "onClick"]}
                radius={"md"}
                classNames={popoverClassNames}
                target={
                  <div
                    onClick={() => setExitProfileOpened(!exitProfileOpened)}
                    className={
                      "order-profile-preview-target exit " +
                      (exitProfileOpened ? "opened" : "")
                    }
                  >
                    <RiExchangeLine />
                    Exit
                  </div>
                }
              >
                <div style={popoverStyle}>
                  <OrderProfileChart
                    activeTheme={props.activeTheme}
                    orderProfile={
                      props.selectedExitProfile ?? DefaultSellOrderProfile
                    }
                    hideXAxis
                    hideYAxis
                    profileMode="exit"
                  />
                </div>
              </Popover>
              <Select
                size="xs"
                className="order-profile-select"
                classNames={selectClassnames}
                styles={selectStyle}
                value={
                  _AllSellProfileEntries.find(
                    (x) => x.value === props.selectedExitProfile?.name
                  )?.value ?? _AllSellProfileEntries[0].value
                }
                defaultValue={
                  _AllSellProfileEntries.find(
                    (x) => x.value === props.selectedExitProfile?.name
                  )?.value ?? _AllSellProfileEntries[0].value
                } // Default to first entry
                clearable={false}
                onChange={(value) => {
                  const profile = allSellProfiles.find((x) => x.name === value);
                  if (profile) {
                    props.onSelectExitProfile &&
                      props.onSelectExitProfile(profile);
                  }
                }}
                disabled={props.readonly}
                placeholder="Exit profile"
                data={_AllSellProfileEntries}
              />
            </div>
            <div className="order-profile-select-row">
              <Popover
                opened={exitTpProfileOpened}
                onClose={() => setExitTpProfileOpened(false)}
                withArrow
                clickOutsideEvents={["click", "onClick"]}
                radius={"md"}
                classNames={popoverClassNames}
                target={
                  <div
                    onClick={() => setExitTpProfileOpened(!exitTpProfileOpened)}
                    className={
                      "order-profile-preview-target exit " +
                      (exitTpProfileOpened ? "opened" : "")
                    }
                  >
                    <TbBellDollar />
                    TP exit
                  </div>
                }
              >
                <div style={popoverStyle}>
                  <OrderProfileChart
                    activeTheme={props.activeTheme}
                    orderProfile={
                      props.selectedExitTpProfile ?? DefaultSellOrderProfile
                    }
                    hideXAxis
                    hideYAxis
                    profileMode="exit"
                  />
                </div>
              </Popover>
              <Select
                disabled={props.readonly || !props.tpEnabled}
                className="order-profile-select"
                classNames={selectClassnames}
                styles={selectStyle}
                clearable={false}
                defaultValue={
                  _AllSellProfileEntries.find(
                    (x) => x.value === props.selectedExitTpProfile?.name
                  )?.value ?? _AllSellProfileEntries[0].value
                }
                value={
                  _AllSellProfileEntries.find(
                    (x) => x.value === props.selectedExitTpProfile?.name
                  )?.value ?? _AllSellProfileEntries[0].value
                } // Default to first entry
                onChange={(value) => {
                  const profile = allSellProfiles.find((x) => x.name === value);
                  if (profile) {
                    props.onSelectExitTpProfile &&
                      props.onSelectExitTpProfile(profile);
                  }
                }}
                size="xs"
                placeholder="Take profit profile"
                data={_AllSellProfileEntries}
              />
            </div>
            <div className="order-profile-select-row">
              <Popover
                opened={exitSlProfileOpened}
                onClose={() => setExitSlProfileOpened(false)}
                withArrow
                clickOutsideEvents={["click", "onClick"]}
                radius={"md"}
                classNames={popoverClassNames}
                target={
                  <div
                    onClick={() => setExitSlProfileOpened(!exitSlProfileOpened)}
                    className={
                      "order-profile-preview-target exit " +
                      (exitSlProfileOpened ? "opened" : "")
                    }
                  >
                    <TbBellDollar />
                    SL exit
                  </div>
                }
              >
                <div style={popoverStyle}>
                  <OrderProfileChart
                    activeTheme={props.activeTheme}
                    orderProfile={
                      props.selectedExitSlProfile ?? DefaultSellOrderProfile
                    }
                    hideXAxis
                    hideYAxis
                    profileMode="exit"
                  />
                </div>
              </Popover>
              <Select
                disabled={props.readonly || !props.slEnabled}
                size="xs"
                className="order-profile-select"
                classNames={selectClassnames}
                styles={selectStyle}
                clearable={false}
                value={
                  _AllSellProfileEntries.find(
                    (x) => x.value === props.selectedExitSlProfile?.name
                  )?.value ?? _AllSellProfileEntries[0].value
                }
                defaultValue={
                  _AllSellProfileEntries.find(
                    (x) => x.value === props.selectedExitSlProfile?.name
                  )?.value ?? _AllSellProfileEntries[0].value
                } // Default to first entry
                onChange={(value) => {
                  const profile = allSellProfiles.find((x) => x.name === value);
                  if (profile) {
                    props.onSelectExitSlProfile &&
                      props.onSelectExitSlProfile(profile);
                  }
                }}
                placeholder="Stoploss profile"
                data={_AllSellProfileEntries}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
