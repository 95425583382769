import "./color-picker.scss";
import { ColorPicker, ColorSwatch } from "@mantine/core";
import { useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";

interface Props {
  activeTheme: string;
  currentColor: string;
  handleColorChange: (color: string) => void;
}
export function CustomColorPicker(props: React.PropsWithChildren<Props>) {
  const [color, setColor] = useState(props.currentColor);

  useEffect(() => {
    if (!color) {
      setColor(props.currentColor);
    }
  }, [color]);

  return (
    <div className="color-picker-container">
      <div className="color-picker-container-row">
        <ColorPicker
          format="hex"
          value={color}
          onChange={(col) => setColor(col)}
        />
      </div>

      <div className="color-picker-container-row">
        <CommonIconButton
          activeTheme={props.activeTheme}
          icon={<FiCheck />}
          flat
          onClick={() => props.handleColorChange(color)}
        />
        <input
          className="indicator-config-input"
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
        <ColorSwatch color={color} size={30} radius="md" />
      </div>
    </div>
  );
}
