import "./tw-chart.scss";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  createChart,
  createSeriesMarkers,
  CrosshairMode,
  IChartApi,
  ISeriesApi,
  SeriesMarker,
  Time,
} from "lightweight-charts";
import { IOHLCData } from "../financial-chart/iOHLCData";
import { getTheme } from "../../../utils/themeUtil";
import { useDebouncedValue } from "@mantine/hooks";
import { CriteriaGenerationPoint } from "../../strategy-body/StrategyChartContainer";
import { TWChartSettingsContext } from "../../../pages/strategy-page/StrategyPage";
import { addVolumeSeries } from "./VolumeSeries";
import { LegendItem } from "./LegendItem";
import { determineDecimals } from "../../../utils/FormattingUtils";
import { TAIndicator } from "../../../interfaces/tw-chart/TWChartSettings";
import { addCandleSeries } from "./CandleSeries";
import { addAroonSeries } from "./indicator-series/AroonSeries";
import { addEMASeries } from "./indicator-series/EMASeries";
import { addMACDSeries } from "./indicator-series/MACDSeries";
import { addRSISeries } from "./indicator-series/RSISeries";
import { addBOPSeries } from "./indicator-series/BOPSeries";
import { addBBSeries } from "./indicator-series/BBSeries";
import { addATRSeries } from "./indicator-series/ATRSeries";
import { addROCSeries } from "./indicator-series/ROCSeries";
import { addStochSeries } from "./indicator-series/StochSeries";

interface Props {
  activeTheme: string;
  candleSize: string;
  data: IOHLCData[];
  height: number;
  setVisibleRange: React.Dispatch<React.SetStateAction<[number, number]>>;
  handleChartClick?: (_dateDataPoint: number) => void;
  criteriaGenerationPoints?: CriteriaGenerationPoint[];
  handleIndicatorClick?: (indicator: TAIndicator) => void;
  handleIndicatorRemove?: (indicator: TAIndicator) => void;
}

export function TWChart(props: React.PropsWithChildren<Props>) {
  const twChartSettings = useContext(TWChartSettingsContext);
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<any>(null);

  // We'll store references to multiple "pane indexes" in a single chart:
  // The top pane is index 0, the second/bottom pane is index 1, etc.
  const seriesRefs = useRef<{
    [key: string]: { [key: string]: ISeriesApi<any> };
  }>({});

  const seriesColorMap = useRef<{ [key: string]: LabelColorMap }>({});

  const [visibleRange, setVisibleRange] = useState<[number, number]>([0, 0]);
  const [debounceVisibleRange] = useDebouncedValue(visibleRange, 250);

  const [legend, setLegend] = useState<LegendGroup>({});

  const [paneHeights, setPaneHeights] = useState<{ [paneId: string]: number }>(
    {}
  );

  /* function applyPaneSizes(
    chart: IChartApi,
    paneSettings: { [id: string]: TWPaneSetting } | undefined,
    totalHeight: number
  ) {
    if (!paneSettings) return;
    // Sort pane settings in visual order (lowest order first)
    const sortedPanes = Object.values(localPaneSettings ?? paneSettings).sort(
      (a, b) => a.order - b.order
    );
    const panes = chart.panes(); // Array of pane objects from the chart
    if (panes.length > 1) return;
    sortedPanes.forEach((paneSetting, index) => {
      if (index === 0) return;
      // Calculate desired height from the stored heightFactor
      const desiredHeight = (paneSetting.heightFactor / 100) * totalHeight;
      // If the pane object supports setHeight, apply the desired height
      if (panes[index] && typeof panes[index].setHeight === "function") {
        panes[index].setHeight(desiredHeight);
      }
    });
  } */

  useEffect(() => {
    // Update only if there's a real change
    props.setVisibleRange([debounceVisibleRange[0], debounceVisibleRange[1]]);
  }, [props.setVisibleRange, debounceVisibleRange]);

  useEffect(() => {
    if (!chartContainerRef.current) return;
    const mainChartPaneIndex =
      twChartSettings?.paneSettings?.["main"].order ?? 0;

    // Create the chart with basic theme settings.
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: props.height,
      layout: {
        background: { color: "transparent" },
        textColor: theme.text,
        attributionLogo: false,
        panes: {
          separatorColor: theme.neotonTransparent,
          separatorHoverColor: theme.neoton,

          enableResize: true,
        },
      },
      grid: {
        vertLines: {
          color: twChartSettings?.main.grid
            ? theme.cardInteriorAlt
            : "transparent",
        },
        horzLines: {
          color: twChartSettings?.main.grid
            ? theme.cardInteriorAlt
            : "transparent",
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
        horzLine: { color: theme.neotonTransparent },
        vertLine: { color: theme.neotonTransparent },
      },
      timeScale: {
        timeVisible: true,
        borderVisible: false,
        rightOffset: 0,
        rightBarStaysOnScroll: true,
        lockVisibleTimeRangeOnResize: true,
        fixLeftEdge: true,
        fixRightEdge: true,
      },
    });
    chartRef.current = chart;

    seriesRefs.current["candlestick"] = addCandleSeries(
      chart,
      props.data,
      theme,
      mainChartPaneIndex
    );

    // Chart addons
    applyAddons(chart, mainChartPaneIndex);

    /* applyPaneSizes(chart, twChartSettings?.paneSettings, props.height); */

    // 4) Manage visible range
    const timeScale = chart.timeScale();
    var range = timeScale.getVisibleRange();
    if (
      visibleRange[0] >= props.data[0].ts &&
      visibleRange[1] <= props.data[props.data.length - 1].ts
    ) {
      timeScale.setVisibleRange({
        from: (visibleRange[0] / 1000) as Time,
        to: (visibleRange[1] / 1000) as Time,
      });
    }
    if (range !== null) {
      setVisibleRange([
        (range.from as number) * 1000,
        (range.to as number) * 1000,
      ]);
    }

    timeScale.subscribeVisibleTimeRangeChange((range) => {
      if (range === null) return; // sometimes range might be null
      const from = range.from as number;
      const to = range.to as number;
      setVisibleRange([from * 1000, to * 1000]);
      if (props.candleSize === "1D") return;
    });

    if ((props.criteriaGenerationPoints?.length ?? 0) > 0 && range !== null) {
      const markers: SeriesMarker<Time>[] | undefined =
        props.criteriaGenerationPoints
          ?.filter(
            (p) =>
              p.timestamp > visibleRange[0] && p.timestamp <= visibleRange[1]
          )
          ?.map((point) => {
            return {
              time: (point.timestamp / 1000) as Time,
              position: point.side === "entry" ? "belowBar" : "aboveBar",
              color:
                point.side === "entry"
                  ? theme.buyOrderStrokeHover
                  : theme.sellOrderStrokeHover,
              shape: point.side === "entry" ? "arrowUp" : "arrowDown",
              text: point.side === "entry" ? "Buy" : "Sell",
            };
          });
      const candlestickSeries =
        seriesRefs.current["candlestick"]["candlestick"];
      createSeriesMarkers(candlestickSeries, markers);
    }

    // 7) Subscribe to crosshair move for legend
    const handleCrosshairMove = (param: any) => {
      const _legendGroup: LegendGroup = {};

      // Ensure the main pane is initialized
      _legendGroup["main"] = {};

      // Candlestick data (OHLC)
      const candlestickSeries =
        seriesRefs.current["candlestick"]["candlestick"];
      const candleData = param.seriesData.get(candlestickSeries);
      if (!candleData) return;
      const decimalCount = determineDecimals(candleData.open);
      const open = candleData.open.toFixed(decimalCount);
      const high = candleData.high.toFixed(decimalCount);
      const low = candleData.low.toFixed(decimalCount);
      const close = candleData.close.toFixed(decimalCount);

      _legendGroup["main"]["OHLC"] = [
        { label: "O", value: open, color: theme.text },
        { label: "H", value: high, color: theme.text },
        { label: "L", value: low, color: theme.text },
        { label: "C", value: close, color: theme.text },
      ];

      // Volume data
      if (twChartSettings?.main.volume) {
        // Ensure a subgroup for volume exists
        _legendGroup["main"]["Volume"] = [];
        const volumeSeries = seriesRefs.current["volume"];
        Object.keys(volumeSeries).forEach((key) => {
          const volume = param.seriesData.get(volumeSeries[key]);
          const decimalCount = determineDecimals(volume.value);
          if (volume) {
            _legendGroup["main"]["Volume"].push({
              label: key,
              value: volume.value.toFixed(decimalCount),
              color: theme.text,
            });
          }
        });
      }

      // Indicators
      twChartSettings?.taIndicators?.forEach((indicator) => {
        // Determine which pane this indicator belongs to
        const legendGroupId = indicator.separatePane ? indicator.id : "main";
        // Initialize the subgroup for this pane if needed
        if (!_legendGroup[legendGroupId]) {
          _legendGroup[legendGroupId] = {};
        }

        const _legendItem: TWLegendItem[] = [];
        const _colorMap = seriesColorMap.current[indicator.id] ?? {};
        const series = seriesRefs.current[indicator.id];
        Object.keys(series).forEach((key) => {
          const indicatorData = param.seriesData.get(series[key]);
          if (indicatorData) {
            const decimalCount = determineDecimals(indicatorData.value);
            _legendItem.push({
              label: key,
              value: indicatorData.value.toFixed(decimalCount),
              color: _colorMap?.[key] ?? indicator["color"] ?? theme.text,
              configurable: true,
              separatePane: indicator.separatePane,
            });
          }
        });
        // Save the indicator’s legend under its own subgroup within its pane
        _legendGroup[legendGroupId][indicator.id] = _legendItem;
      });

      setLegend(_legendGroup);
    };

    // Attach event + store to unsubscribe on cleanup
    chart.subscribeCrosshairMove(handleCrosshairMove);
    // Cleanup on unmount.
    return () => {
      chart.remove();
    };
  }, [
    twChartSettings,
    props.data,
    theme,
    props.height,
    props.candleSize,
    props.setVisibleRange,
    props.criteriaGenerationPoints,
  ]);

  useEffect(() => {
    // Poll every 250ms (adjust as needed) for updated pane sizes
    const interval = setInterval(() => {
      if (chartRef.current && twChartSettings) {
        const chart: IChartApi = chartRef.current;
        const _paneHeights: { [key: number]: number } = {};
        chart.panes().forEach((pane, idx) => {
          _paneHeights[idx] = pane.getHeight() < 0 ? 10 : pane.getHeight();
        });
        setPaneHeights(_paneHeights);
      }
    }, 600);
    return () => clearInterval(interval);
  }, [twChartSettings, setPaneHeights]);

  const handleDoubleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (!chartRef.current || !chartContainerRef.current) return;
      const chart = chartRef.current;
      const timeScale = chart.timeScale();
      const range = timeScale.getVisibleRange();
      if (range !== null) {
        const from = range.from as number;
        const to = range.to as number;
        setVisibleRange([from * 1000, to * 1000]);
      }
      const rect = chartContainerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const timeValue = timeScale.coordinateToTime(x);
      if (timeValue === undefined) return;
      let timestamp: number;
      if (typeof timeValue === "string") {
        timestamp = new Date(timeValue).getTime();
      } else {
        timestamp = timeValue * 1000;
      }
      // Now call your existing logic:
      props.handleChartClick?.(timestamp);
    },
    [props.handleChartClick, setVisibleRange]
  );

  const getPaneOffset = useCallback(
    (paneIdx: number, paneHeights: { [paneId: string]: number }) => {
      let offset = 0;
      for (let i = 0; i < paneIdx; i++) {
        offset += paneHeights[i];
      }
      return offset;
    },
    []
  );

  return (
    <div
      ref={chartContainerRef}
      onDoubleClick={handleDoubleClick}
      style={{ width: "100%", height: props.height }}
    >
      {Object.entries(legend).map(([paneId, subgroups]) => {
        // Use the computed offset for this pane; default to 0 if not yet computed
        const paneOrder = twChartSettings?.paneSettings?.[paneId]?.order ?? 0;
        const topOffset = getPaneOffset(paneOrder, paneHeights);
        return (
          <div
            key={paneId}
            className="tw-legend"
            style={{
              position: "absolute",
              top: topOffset,
              left: 0,
              pointerEvents: "auto",
            }}
          >
            {Object.entries(subgroups).map(([groupId, items]) => (
              <div key={groupId} className="legend-subgroup">
                <LegendItem
                  activeTheme={props.activeTheme}
                  legendItems={items}
                  onSettingsClick={() => {
                    const indicator = twChartSettings?.taIndicators?.find(
                      (indicator) => indicator.id === groupId
                    );
                    if (indicator) {
                      props.handleIndicatorClick?.(indicator);
                    }
                  }}
                  onDeleteClick={() => {
                    const indicator = twChartSettings?.taIndicators?.find(
                      (indicator) => indicator.id === groupId
                    );
                    if (indicator) {
                      props.handleIndicatorRemove?.(indicator);
                    }
                  }}
                />
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );

  function applyAddons(chart: IChartApi, mainChartPaneIndex: number) {
    if (twChartSettings?.main.volume) {
      seriesRefs.current["volume"] = addVolumeSeries(
        chart,
        props.data,
        theme,
        mainChartPaneIndex
      );
    }

    twChartSettings?.taIndicators?.forEach((indicator) => {
      const paneIndex = indicator.separatePane
        ? twChartSettings?.paneSettings?.[indicator.id]?.order
        : mainChartPaneIndex;
      if (indicator.type === "AROON") {
        const { chartData, colorMap } = addAroonSeries(
          chart,
          props.data,
          indicator,
          theme,
          paneIndex
        );
        seriesRefs.current[indicator.id] = chartData;
        seriesColorMap.current[indicator.id] = colorMap;
      }
      if (indicator.type === "ATR") {
        const { chartData, colorMap } = addATRSeries(
          chart,
          props.data,
          indicator,
          null,
          paneIndex
        );
        seriesRefs.current[indicator.id] = chartData;
        seriesColorMap.current[indicator.id] = colorMap;
      }
      if (indicator.type === "BBAND") {
        const { chartData, colorMap } = addBBSeries(
          chart,
          props.data,
          indicator,
          theme,
          paneIndex
        );
        seriesRefs.current[indicator.id] = chartData;
        seriesColorMap.current[indicator.id] = colorMap;
      }
      if (indicator.type === "BOP") {
        const { chartData, colorMap } = addBOPSeries(
          chart,
          props.data,
          indicator,
          theme,
          paneIndex
        );
        seriesRefs.current[indicator.id] = chartData;
        seriesColorMap.current[indicator.id] = colorMap;
      }
      if (indicator.type === "EMA") {
        const { chartData, colorMap } = addEMASeries(
          chart,
          props.data,
          indicator,
          null,
          paneIndex
        );
        seriesRefs.current[indicator.id] = chartData;
        seriesColorMap.current[indicator.id] = colorMap;
      }
      if (indicator.type === "MACD") {
        const { chartData, colorMap } = addMACDSeries(
          chart,
          props.data,
          indicator,
          theme,
          paneIndex
        );
        seriesRefs.current[indicator.id] = chartData;
        seriesColorMap.current[indicator.id] = colorMap;
      }
      if (indicator.type === "ROC") {
        const { chartData, colorMap } = addROCSeries(
          chart,
          props.data,
          indicator,
          theme,
          paneIndex
        );
        seriesRefs.current[indicator.id] = chartData;
        seriesColorMap.current[indicator.id] = colorMap;
      }
      if (indicator.type === "RSI") {
        const { chartData, colorMap } = addRSISeries(
          chart,
          props.data,
          indicator,
          theme,
          paneIndex
        );
        seriesRefs.current[indicator.id] = chartData;
        seriesColorMap.current[indicator.id] = colorMap;
      }
      if (indicator.type === "STOCHASTIC") {
        const { chartData, colorMap } = addStochSeries(
          chart,
          props.data,
          indicator,
          theme,
          paneIndex
        );
        seriesRefs.current[indicator.id] = chartData;
        seriesColorMap.current[indicator.id] = colorMap;
      }
    });
  }
}

export interface TWLegendItem {
  label: string;
  value: string;
  color: string;
  colorMap?: LegendColorMap;
  configurable?: boolean;
  separatePane?: boolean;
}
export interface LegendColorMap {
  [label: string]: string;
}
export interface LegendSubGroup {
  [groupId: string]: TWLegendItem[];
}

export interface LegendGroup {
  [paneId: string]: LegendSubGroup;
}

export interface CustomChartAddon {
  chartData: CustomChartSeries;
  colorMap: LabelColorMap;
}
export interface CustomChartSeries {
  [label: string]: ISeriesApi<any>;
}

export type LabelColorMap = { [label: string]: string };
