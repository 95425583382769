import { HistogramSeries, IChartApi, Time } from "lightweight-charts";
import { IOHLCData } from "../financial-chart/iOHLCData";

export function addVolumeSeries(
  chart: IChartApi,
  data: IOHLCData[],
  theme: any,
  paneIdx: number = 0
) {
  const volumeSeries = chart.addSeries(HistogramSeries, {
    priceFormat: { type: "volume" },
    priceScaleId: "volume", // Overlay on the main chart
  }, paneIdx);

  volumeSeries.priceScale().applyOptions({
    scaleMargins: {
      top: 0.8,
      bottom: 0,
    },
  });

  volumeSeries.setData(
    data.map((item, index, array) => {
      if (index === 0) {
        return {
          time: (item.ts / 1000) as Time,
          value: item.volume,
          color: theme.cardInteriorAlt, // Default color for the first volume bar
        };
      }

      const prevCandle = array[index - 1];
      const currentColor =
        item.close > prevCandle.close
          ? theme.buyOrderAreaY // Green if price increased
          : theme.sellOrderAreaY; // Red if price decreased

      return {
        time: (item.ts / 1000) as Time,
        value: item.volume,
        color: currentColor,
      };
    })
  );

  return { V: volumeSeries };
}
