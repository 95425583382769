import { StrategySelectionTab } from "./CommonStrategyList";

interface Props {
  icon: React.ReactNode;
  label: string;
  extraChild?: React.ReactNode;
  tab: StrategySelectionTab;
  selectedTab: StrategySelectionTab;
  setSelectedTab: (tab: StrategySelectionTab) => void;
  disabled?: boolean;
}
export function CommonStrategyListTabButton(
  props: React.PropsWithChildren<Props>
) {
  return (
    <div
      className={
        "common-strategy-list-header-tab" +
        (props.selectedTab === props.tab ? " selected" : "") +
        (props.disabled ? " disabled" : "")
      }
      onClick={() => !props.disabled && props.setSelectedTab(props.tab)}
    >
      {props.icon}
      <label>{props.label}</label>
      {props.extraChild}
    </div>
  );
}
