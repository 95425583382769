import { IChartApi, LineSeries, Time } from "lightweight-charts";
import { IOHLCData } from "../../financial-chart/iOHLCData";
import { ROC } from "../../../../interfaces/tw-chart/TWChartSettings";
import { roc } from "indicatorts"; // Ensure this is available
import { CustomChartAddon } from "../TWChart";

export function addROCSeries(
  chart: IChartApi,
  data: IOHLCData[],
  indicatorConfig: ROC,
  theme: any = null,
  paneIdx: number = 0
): CustomChartAddon {
  // Add a new LineSeries to represent the ROC
  const ROCSeries = chart.addSeries(LineSeries, {
    priceScaleId: "right", // adjust as needed (you might want a separate scale)
  }, paneIdx);

  // Calculate the ROC values from the closing prices and indicator configuration
  const rocData = roc(
    data.map((item) => item.close),
    indicatorConfig.config
  );

  // Map the calculated ROC values to the data format expected by lightweight-charts
  ROCSeries.setData(
    rocData.map((value, index) => ({
      time: (data[index].ts / 1000) as Time,
      value,
    }))
  );

  // Apply series options (using your indicator's color and other settings)
  ROCSeries.applyOptions({
    color: indicatorConfig.color,
    lineWidth: 1,
    priceLineVisible: false,
  });

  const label = `ROC${indicatorConfig.config.period}`;

  return {
    chartData: {
      [label]: ROCSeries,
    },
    colorMap: {
      [label]: indicatorConfig.color,
    },
  };
}
