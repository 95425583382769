import { motion } from "framer-motion";
import { useCallback, useContext, useMemo, useState } from "react";
import { IsBacktestingContext } from "../../../pages/backtester-page/BacktesterPage";
import { CommonWalletValueSnapshots } from "../../../interfaces/papertrader/PaperwalletPreview";
import { useAuth0 } from "@auth0/auth0-react";
import { TraderType } from "../../../pages/common/TradingDashboard";
import { getBlueprintService } from "../../../utils/serviceUtil";
import { useQuery } from "react-query";
import CountUp from "react-countup";
import { Modal } from "@mantine/core";
import { EquationContext } from "react-equation";

interface Props {
  orderCount: number;
  wins: number;
  losses: number;
  traderType: TraderType;
  toggle?: () => void;
  addOrRemoveFilter: (filter: string) => void;
  orderFilters: string[] | undefined;
  disabled?: boolean;
  compact?: boolean;
  toggled?: boolean;
  walletValueSnapshots?: CommonWalletValueSnapshots;
}
export function CommonOrdersHeader(props: React.PropsWithChildren<Props>) {
  const isRunning = useContext(IsBacktestingContext);
  const [modalOpened, setModalOpened] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const periodDays = useMemo(() => {
    // count the days between the first and last timestamp
    if (!props.walletValueSnapshots) return 0;
    const timestamps = props.walletValueSnapshots.timestamps;
    if (timestamps.length === 0) return 0;
    const firstTimestamp = timestamps[0];
    const lastTimestamp = timestamps[timestamps.length - 1];
    const diff = parseInt(lastTimestamp) - parseInt(firstTimestamp);
    return diff / (1000 * 60 * 60 * 24);
  }, [props.walletValueSnapshots]);

  const enableSharpeSortino = useMemo(() => {
    if (isRunning) return false;
    if (!props.walletValueSnapshots) return false;
    return props.walletValueSnapshots.timestamps.length > 4;
  }, [props.walletValueSnapshots, isRunning]);

  const fetchSharpeSortino = useCallback(async () => {
    if (!enableSharpeSortino) return;
    if (!props.walletValueSnapshots) return;
    const accessToken = await getAccessTokenSilently();
    if (!accessToken) return;
    try {
      const blueprint = getBlueprintService(props.traderType);
      const response = await blueprint.getSharpeSortino(
        accessToken,
        props.walletValueSnapshots
      );
      return response.data;
    } finally {
    }
  }, [
    props.walletValueSnapshots,
    getAccessTokenSilently,
    props.traderType,
    enableSharpeSortino,
  ]);

  const sharpeSortinoQuery = useQuery(
    `${props.traderType.traderType}-sharpe-sortino`,
    fetchSharpeSortino,
    {
      enabled: enableSharpeSortino,
    }
  );

  const equations: { [key: string]: string } = {
    SharpeRatio:
      "Sharpe Ratio = (Mean Return − Risk Free Rate) / (Standard Deviation)",
    SortinoRatio:
      "Sortino Ratio = (Mean Return − Risk Free Rate) / (Downside Deviation)",
  };

  const ratioDescription: { [key: string]: string } = {
    SharpeRatio:
      "The Sharpe Ratio evaluates an investment’s risk-adjusted return by comparing its excess return (return above the risk-free rate) to its total volatility (standard deviation). A higher Sharpe Ratio suggests better risk-adjusted performance. Typically:\nAbove 1 = Good\nAbove 2 = Very Good\nAbove 3 = Excellent",

    SortinoRatio:
      "The Sortino Ratio improves on the Sharpe Ratio by focusing only on downside risk—ignoring upside volatility. It measures how much excess return an investment generates per unit of harmful risk (negative returns). A higher Sortino Ratio indicates better downside protection, making it useful for investors who prioritize minimizing losses. Typically:\nBelow 0 = Poor\nBelow 1 = Weak\nBetween 1 and 2 = Acceptable\nBetween 2 and 3 = Good\nBetween 3 and 5 = Very Good\nAbove 5 = Outstanding",
  };

  const winLossRatio = useMemo(() => {
    return props.wins / (props.wins + props.losses);
  }, [props.wins, props.losses]);

  const winPct = useMemo(() => {
    const pctValue = winLossRatio * 100;
    return pctValue.toFixed(2);
  }, [winLossRatio]);

  const lossPct = useMemo(() => {
    const pctValue = 100 - winLossRatio * 100;
    return pctValue.toFixed(2);
  }, [winLossRatio]);

  const shouldShowLossPct = useCallback(() => {
    if (props.disabled) return false;
    if (props.orderFilters?.includes("wins")) return false;
    if (props.orderFilters?.includes("losses")) return true;
    return (
      !props.orderFilters?.includes("losses") &&
      !props.orderFilters?.includes("wins") &&
      lossPct > winPct
    );
  }, [props.orderFilters, props.disabled, lossPct, winPct]);

  const shouldShowWinPct = useCallback(() => {
    if (props.disabled) return false;
    if (props.orderFilters?.includes("losses")) return false;
    if (props.orderFilters?.includes("wins")) return true;
    return (
      !props.orderFilters?.includes("losses") &&
      !props.orderFilters?.includes("wins") &&
      winPct >= lossPct
    );
  }, [props.orderFilters, props.disabled, lossPct, winPct]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4, delay: 0.2 }}
      onClick={() => {
        if (props.disabled && props.toggle) props.toggle();
      }}
      className="common-orders-header-stats-container"
    >
      {isRunning ? (
        <motion.label
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, delay: 1 }}
          className="dimmed-label"
        >
          Your orders will be listed here once the backtest has finished
        </motion.label>
      ) : props.orderCount === 0 &&
        props.orderFilters &&
        props.orderFilters.length === 0 ? (
        <motion.label
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, delay: 0.5 }}
          className="dimmed-label"
        >
          No orders
        </motion.label>
      ) : (
        <div className="order-stats-column">
          <div className="order-stats-row">
            {props.compact && !props.toggled && (
              <label
                onClick={props.toggle}
                className="orders-stats-main-header"
              >
                {props.orderCount > 0
                  ? `Orders (${props.orderCount})`
                  : "No orders yet"}
              </label>
            )}
            <label
              onClick={() => {
                if (props.disabled) return;
                props.addOrRemoveFilter("wins");
              }}
              className={
                "orders-stats-header wins" +
                (props.orderFilters && props.orderFilters.includes("losses")
                  ? " disabled"
                  : "")
              }
            >
              {props.wins} wins
            </label>

            {props.losses > 0 || props.wins > 0 ? (
              <motion.div layout className="win-loss-bar-container">
                <motion.div
                  className="win-bar"
                  onClick={() => {
                    if (props.disabled) return;
                    props.addOrRemoveFilter("wins");
                  }}
                  initial={{ width: 0 }}
                  animate={{
                    y: props.disabled ? 0 : 5,
                    width:
                      props.orderFilters &&
                      props.orderFilters.includes("losses")
                        ? `0%`
                        : props.orderFilters &&
                          props.orderFilters.includes("wins")
                        ? `100%`
                        : `${winPct}%`,
                  }}
                  transition={{
                    duration: 0.15,
                    delay: 0.3,
                    type: "tween",
                    easings: ["easeInOut"],
                  }}
                >
                  {shouldShowWinPct() ? (
                    <motion.label
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, y: -15 }}
                      transition={{ duration: 0.2, delay: 0.7 }}
                    >
                      {winPct} %
                    </motion.label>
                  ) : undefined}
                </motion.div>
                <motion.div
                  className="loss-bar"
                  onClick={() => {
                    if (props.disabled) return;
                    props.addOrRemoveFilter("losses");
                  }}
                  initial={{ width: 0 }}
                  animate={{
                    y: props.disabled ? 0 : -5,
                    width:
                      props.orderFilters && props.orderFilters.includes("wins")
                        ? `0%`
                        : props.orderFilters &&
                          props.orderFilters.includes("losses")
                        ? `100%`
                        : `${lossPct}%`,
                  }}
                  transition={{
                    duration: 0.15,
                    delay: 0.3,
                    type: "tween",
                    easings: ["easeInOut"],
                  }}
                >
                  {shouldShowLossPct() ? (
                    <motion.label
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, y: 9 }}
                      transition={{ duration: 0.2, delay: 0.7 }}
                    >
                      {lossPct} %
                    </motion.label>
                  ) : undefined}
                </motion.div>
              </motion.div>
            ) : undefined}

            <label
              onClick={() => {
                if (props.disabled) return;
                props.addOrRemoveFilter("losses");
              }}
              className={
                "orders-stats-header losses" +
                (props.orderFilters && props.orderFilters.includes("wins")
                  ? " disabled"
                  : "")
              }
            >
              {props.losses} losses
            </label>
          </div>
          {!props.compact && (
            <motion.div
              initial={{ opacity: 0, scaleY: 0 }}
              animate={{
                opacity: sharpeSortinoQuery.data ? 1 : 0,
                scaleY: sharpeSortinoQuery.data ? 1 : 0,
              }}
              transition={{ duration: 0.2, delay: 0.5 }}
              className={"order-stats-row sharpe-sortino-row"}
              onClick={() => setModalOpened(true)}
            >
              <label className="orders-stats-header">Sharpe Ratio:</label>
              <CountUp
                start={0}
                end={sharpeSortinoQuery.data?.sharpe_ratio || 0}
                duration={1}
                decimals={2}
                className="sharpe-sortino-label"
              />
              <label className="orders-stats-header">Sortino Ratio:</label>
              <CountUp
                start={0}
                end={sharpeSortinoQuery.data?.sortino_ratio || 0}
                duration={1}
                decimals={2}
                className="sharpe-sortino-label"
              />
            </motion.div>
          )}
        </div>
      )}

      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title={
          <label className="dimmed-label">
            Trading session: <strong>{periodDays} Days</strong>
          </label>
        }
        size="xl"
        centered
      >
        <div className="sharpe-sortino-modal">
          <div className="sharpe-container">
            <div className="sharpe-sortino-container-row">
              <label>Sharpe Ratio:</label>
              <CountUp
                start={0}
                end={sharpeSortinoQuery.data?.sharpe_ratio || 0}
                duration={1}
                decimals={2}
                className="sharpe-sortino-label"
              />
            </div>

            <label
              className="dimmed-label"
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {ratioDescription.SharpeRatio}
            </label>

            <EquationContext
              render={(renderEquation) => (
                <div style={{ fontSize: "100%", marginTop: "auto" }}>
                  <div>
                    <div className="equation-wrapper">
                      {renderEquation(equations.SharpeRatio)}
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
          <div className="sortino-container">
            <div className="sharpe-sortino-container-row">
              <label>Sortino Ratio:</label>
              <CountUp
                start={0}
                end={sharpeSortinoQuery.data?.sortino_ratio || 0}
                duration={1}
                decimals={2}
                className="sharpe-sortino-label"
              />
            </div>
            <label
              className="dimmed-label"
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {ratioDescription.SortinoRatio}
            </label>

            <EquationContext
              render={(renderEquation) => (
                <div style={{ fontSize: "100%", marginTop: "auto" }}>
                  <div>
                    <div className="equation-wrapper">
                      {renderEquation(equations.SortinoRatio)}
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </Modal>
    </motion.div>
  );
}
