import { IChartApi, LineSeries, Time } from "lightweight-charts";
import { IOHLCData } from "../../financial-chart/iOHLCData";
import { STOCHASTIC } from "../../../../interfaces/tw-chart/TWChartSettings";
import { stoch } from "indicatorts"; // Ensure this function exists
import { CustomChartAddon } from "../TWChart";

export function addStochSeries(
  chart: IChartApi,
  data: IOHLCData[],
  indicatorConfig: STOCHASTIC,
  theme: any = null,
  paneIdx: number = 0
): CustomChartAddon {
  const stochKSeries = chart.addSeries(
    LineSeries,
    {
      priceScaleId: "right", // adjust as needed
    },
    paneIdx
  );
  const stochDSeries = chart.addSeries(
    LineSeries,
    {
      priceScaleId: "right", // adjust as needed
    },
    paneIdx
  );

  const highs = data.map((d) => d.high);
  const lows = data.map((d) => d.low);
  const closes = data.map((d) => d.close);
  const stochData = stoch(highs, lows, closes, indicatorConfig.config);

  // Map the computed Aroon values to the data format expected by lightweight-charts.
  stochKSeries.setData(
    stochData.k.map((val, index) => ({
      time: (data[index].ts / 1000) as Time,
      value: val,
    }))
  );
  stochDSeries.setData(
    stochData.d.map((val, index) => ({
      time: (data[index].ts / 1000) as Time,
      value: val,
    }))
  );

  // Apply options to style the lines

  stochKSeries.applyOptions({
    color: indicatorConfig.kLine || theme.text,
    lineWidth: 1,
    priceLineVisible: false,
  });
  stochDSeries.applyOptions({
    color: indicatorConfig.dLine || theme.text,
    lineWidth: 1,
    priceLineVisible: false,
  });

  const kLabel = `STOCH_K`;
  const dLabel = `STOCH_D`;

  return {
    chartData: {
      [kLabel]: stochKSeries,
      [dLabel]: stochDSeries,
    },
    colorMap: {
      [kLabel]: indicatorConfig.kLine || theme.text,
      [dLabel]: indicatorConfig.dLine || theme.text,
    },
  };
}
