import { IChartApi, LineSeries, Time } from "lightweight-charts";
import { IOHLCData } from "../../financial-chart/iOHLCData";
import { AROON } from "../../../../interfaces/tw-chart/TWChartSettings";
import { aroon } from "indicatorts"; // Ensure this function exists
import { CustomChartAddon } from "../TWChart";

export function addAroonSeries(
  chart: IChartApi,
  data: IOHLCData[],
  indicatorConfig: AROON,
  theme: any = null,
  paneIdx: number = 0
): CustomChartAddon {
  // Create two series: one for Aroon Up and one for Aroon Down.
  const aroonUpSeries = chart.addSeries(
    LineSeries,
    {
      priceScaleId: "right", // adjust as needed
    },
    paneIdx
  );

  const aroonDownSeries = chart.addSeries(
    LineSeries,
    {
      priceScaleId: "right", // adjust as needed
    },
    paneIdx
  );

  // Calculate the Aroon values from the closing prices.
  // The `aroon` function is assumed to return an array of objects
  // [{ up: number, down: number }, ...] corresponding to each data point.
  const highs = data.map((d) => d.high);
  const lows = data.map((d) => d.low);
  const aroonData = aroon(
    highs,
    lows,
    indicatorConfig.config
  );

  // Map the computed Aroon values to the data format expected by lightweight-charts.
  aroonUpSeries.setData(
    aroonData.up.map((val, index) => ({
      time: (data[index].ts / 1000) as Time,
      value: val,
    }))
  );

  aroonDownSeries.setData(
    aroonData.down.map((val, index) => ({
      time: (data[index].ts / 1000) as Time,
      value: val,
    }))
  );

  // Apply options to style the lines
  aroonUpSeries.applyOptions({
    color: indicatorConfig.up || theme.text,
    lineWidth: 1,
    priceLineVisible: false,
  });

  aroonDownSeries.applyOptions({
    color: indicatorConfig.down || theme.text,
    lineWidth: 1,
    priceLineVisible: false,
  });

  const labelUp = `AROONUP`;
  const labelDown = `AROONDOWN`;

  return {
    chartData: {
      [labelUp]: aroonUpSeries,
      [labelDown]: aroonDownSeries,
    },
    colorMap: {
      [labelUp]: indicatorConfig.up || theme.text,
      [labelDown]: indicatorConfig.down || theme.text,
    },
  }

}
