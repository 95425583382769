import {
  createContext,
  lazy,
  Suspense,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";
import { ApplicationIndicators } from "../../interfaces/Indicators";
import { BacktesterService } from "../../services/BacktesterService";
import { useAuth0 } from "@auth0/auth0-react";
import { ApplicationIndicatorsContext } from "../../shared-service-contexts/IndicatorContext";
import { CurrencyContext } from "../../shared-service-contexts/CurrencyContext";
import { BacktestTradingReport } from "../../interfaces/backtester/BacktestTradingReport";
import { convertToCommonWalletReport } from "../../utils/interfaceUtil";
import { BacktestStageRequest } from "../../interfaces/backtester/BacktestStageRequest";
import {
  GetBacktestStageRequestContext,
  SetBacktestStageRequestContext,
} from "./context/BacktestStageRequestContext";
import RealtimeTradingReportProvider from "./BacktestTradingReportProvider";
import { toast } from "react-toastify";
import { FaCheckCircle } from "react-icons/fa";
import { AppInsightsContext, UpdateCurrencyVibranceContext } from "../../App";
import { getTheme } from "../../utils/themeUtil";
import { TradingDashboard } from "../common/TradingDashboard";
import { useLocation } from "react-router-dom";
import {
  IndicatorInfosContext,
  IndicatorsInfo,
} from "../../shared-service-contexts/IndicatorsInfoContext";
import { CommonWalletReport } from "../../interfaces/common-trading/CommonTradingReport";
import { NeotonLoader } from "../../components/custom-loaders/NeotonLoader";

const BacktestReportOverview = lazy(() => import("./BacktestReportOverview"));

interface Props {
  activeTheme: string;
}
export default function BacktesterPage(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const appInsights = useContext(AppInsightsContext);

  const theme = getTheme(props.activeTheme);
  const [backtestStageRequest, setBacktestStageRequest] = useState<
    BacktestStageRequest | undefined
  >();
  const [backtestTradingReport, setBacktestTradingReport] = useState<
    BacktestTradingReport | undefined
  >();
  const [loadingInitalReport, setLoadingInitalReport] = useState(false);
  const [isStaging, setIsStaging] = useState(false);
  const [cancelBacktest, setCancelBacktest] = useState(false);
  const [hasJoinedGroup, setHasJoinedGroup] = useState(false);

  const location = useLocation();

  const [strategyId, setStrategyId] = useState<string | undefined>(
    location.state?.strategyId
  );
  const [versionId, setVersionId] = useState<string | undefined>(
    location.state?.versionId
  );

  const [waitForLocationStateFetch, setWaitForLocationStateFetch] = useState(
    location.state ? true : false
  );

  const loadBacktestTradingReportFromBlob = useCallback(
    async (blobId: string) => {
      const token = await getAccessTokenSilently();
      if (!token) return;
      try {
        setLoadingInitalReport(true);

        setBacktestTradingReport(undefined);
        const response = await BacktesterService.getBacktestBlobTradingReport(
          token,
          blobId
        );
        if (!response.data) return;
        setBacktestTradingReport(response.data);
        setVersionId(response.data?.version_id);
        setStrategyId(response.data?.strategy_id);
      } catch (error: any) {
        appInsights?.trackException({ error });
      } finally {
        setLoadingInitalReport(false);
      }
    },
    [
      getAccessTokenSilently,
      appInsights,
      setBacktestTradingReport,
      setStrategyId,
      setVersionId,
      setLoadingInitalReport,
    ]
  );

  const fetchCurrencies = useCallback(async () => {
    const _token = await getAccessTokenSilently();
    try {
      return await BacktesterService.blueprint.getCurrencies(_token);
    } catch (error: any) {
      appInsights?.trackException({ error });
    }
  }, [getAccessTokenSilently, appInsights]);
  const updateCurrencyVibrances = useContext(UpdateCurrencyVibranceContext);

  const currenciesQuery = useQuery("Currencies", fetchCurrencies, {
    keepPreviousData: true,
    cacheTime: 120000,
    staleTime: 120000,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    retryDelay: 4000,
    onSuccess: (data) => {
      if (!data || !updateCurrencyVibrances) return;
      updateCurrencyVibrances(data);
    },
  });

  const fetchIndicators = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await BacktesterService.blueprint.getIndicators(token);
      return response.data as ApplicationIndicators;
    } catch (error: any) {
      appInsights?.trackException({ error });
    }
  }, [getAccessTokenSilently, appInsights]);

  const indicatorsQuery = useQuery(["Indicators"], fetchIndicators, {
    keepPreviousData: true,
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const fetchIndicatorInfo = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await BacktesterService.blueprint.getIndicatorsInfo(
        token
      );
      return response.data as IndicatorsInfo;
    } catch (error) {}
  }, [getAccessTokenSilently]);

  const indicatorInfoQuery = useQuery(["IndicatorsInfo"], fetchIndicatorInfo, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const [commonWalletReport, setCommonWalletReport] = useState<
    CommonWalletReport | undefined
  >();

  const tryFetchFullTradingReport = useCallback(async () => {
    if (waitForLocationStateFetch) return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setLoadingInitalReport(true);
      // set timeout
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await BacktesterService.getBacktestTradingReport(token);
      if (!response.data) return;
      setBacktestTradingReport(response.data);
      setVersionId(response.data?.version_id);
      setStrategyId(response.data?.strategy_id);
    } finally {
      setLoadingInitalReport(false);
    }
  }, [
    getAccessTokenSilently,
    setBacktestTradingReport,
    setLoadingInitalReport,
    waitForLocationStateFetch,
    setStrategyId,
    setVersionId,
  ]);

  const status = backtestTradingReport?.status;

  const isValidBacktestStageRequest = useCallback(() => {
    if (!hasJoinedGroup) return false;
    if (!backtestStageRequest) return false;
    if (!backtestStageRequest.strategyId) return false;
    if (!backtestStageRequest.start) return false;
    if (!backtestStageRequest.stop) return false;
    if (!backtestStageRequest.strategyId) return false;
    if (!backtestStageRequest.versionId) return false;
    if (status && !["finished", "cancelled"].includes(status)) return false;
    if (isStaging) return false;
    return true;
  }, [backtestStageRequest, status, isStaging, hasJoinedGroup]);

  const checkIfBacktestIsRunning = useCallback(() => {
    if (isStaging || cancelBacktest) return true;
    if (!backtestTradingReport) return false;
    if (backtestTradingReport?.status === "finished") return false;
    if (backtestTradingReport?.status === "cancelled") return false;
    return true;
  }, [backtestTradingReport, isStaging, cancelBacktest]);

  const stageable = isValidBacktestStageRequest();
  const isRunning = checkIfBacktestIsRunning();

  useQuery(
    ["FullTradingReport", isRunning, backtestStageRequest?.tournamentMode],
    tryFetchFullTradingReport,
    {
      enabled: !isRunning,
      cacheTime: 60000,
      staleTime: 0,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    }
  );

  const fetchStrategy = useCallback(async () => {
    if (!strategyId || !versionId) return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    const response = await BacktesterService.blueprint.loadStrategy(
      token,
      strategyId,
      versionId
    );
    if (!response.payload) return;
    setBacktestStageRequest({
      ...backtestStageRequest,
      strategyId: response.payload.strategy_id,
      versionId: response.payload.version_id,
      dependencyHash: response.payload.dependency_hash,
    });
    setWaitForLocationStateFetch(false);
    return response;
  }, [
    getAccessTokenSilently,
    strategyId,
    versionId,
    backtestStageRequest,
    setBacktestStageRequest,
    setWaitForLocationStateFetch,
  ]);

  const strategyQuery = useQuery(
    ["StrategyFromLocation", strategyId, versionId],
    fetchStrategy,
    {
      cacheTime: 60000,
      staleTime: 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleStageBacktest = useCallback(
    async (_backtestStageRequest: BacktestStageRequest) => {
      const token = await getAccessTokenSilently();
      if (!token) return;
      if (!stageable) return;
      // fixme: remove
      _backtestStageRequest.initialBalance = 1000;
      _backtestStageRequest.fee = 0.002;
      try {
        setIsStaging(true);
        setCommonWalletReport(undefined);
        const response = await toast.promise(
          BacktesterService.stageBacktest(token, _backtestStageRequest),
          {
            pending: "Staging backtest",
            success: {
              render({ data }) {
                return data?.["data"]["message"] ?? "Backtest staged";
              },
              icon: <FaCheckCircle color={theme.buyOrderStrokeHover} />,
            },
            error: "Failed to stage backtest",
          },
          {
            position: "top-center",
            style: {
              fontFamily: "BalooBhaijaan2",
            },
          }
        );

        setBacktestTradingReport({ status: response.data.payload.status });
      } finally {
        setIsStaging(false);
      }
    },
    [
      getAccessTokenSilently,
      stageable,
      setBacktestTradingReport,
      theme,
      setIsStaging,
      setCommonWalletReport,
    ]
  );

  const [simulationInfo, setSimulationInfo] = useState<
    SimulationInfo | undefined
  >();

  useMemo(() => {
    setSimulationInfo({
      progress: backtestTradingReport?.progress,
      execution_count: backtestTradingReport?.execution_count,
      billing_start: backtestTradingReport?.billing_start,
      billing_end: backtestTradingReport?.billing_end,
      status: backtestTradingReport?.status,
      status_message: backtestTradingReport?.status_message,
      download_progress: backtestTradingReport?.download_progress,
    });
    setCommonWalletReport((prev) => {
      return convertToCommonWalletReport(backtestTradingReport, prev);
    });
  }, [backtestTradingReport, setCommonWalletReport, setSimulationInfo]);

  return (
    <>
      <CurrencyContext.Provider value={currenciesQuery.data}>
        <ApplicationIndicatorsContext.Provider value={indicatorsQuery.data}>
          <IndicatorInfosContext.Provider value={indicatorInfoQuery.data}>
            <LoadBacktestReportFromBlobContext.Provider
              value={loadBacktestTradingReportFromBlob}
            >
              <SetBacktestStageRequestContext.Provider
                value={setBacktestStageRequest}
              >
                <GetBacktestStageRequestContext.Provider
                  value={backtestStageRequest}
                >
                  <StageableContext.Provider value={stageable}>
                    <IsStagingContext.Provider value={isStaging}>
                      <IsBacktestingContext.Provider value={isRunning}>
                        <CancelBacktestContext.Provider value={cancelBacktest}>
                          <SetCancelBacktestContext.Provider
                            value={setCancelBacktest}
                          >
                            <BacktestProgressContext.Provider
                              value={backtestTradingReport?.progress}
                            >
                              <StageBacktestContext.Provider
                                value={handleStageBacktest}
                              >
                                <HasJoinedPubSubGroup.Provider
                                  value={hasJoinedGroup}
                                >
                                  <SetHasJoinedPubSubGroup.Provider
                                    value={setHasJoinedGroup}
                                  >
                                    <RealtimeTradingReportProvider
                                      setBacktestTradingReport={
                                        setBacktestTradingReport
                                      }
                                      cancelBacktest={cancelBacktest}
                                    />
                                    <TradingDashboard
                                      activeTheme={props.activeTheme}
                                      traderType={{
                                        traderType: "backtester",
                                      }}
                                      leaderboardContainerChild={
                                        <Suspense fallback={<NeotonLoader />}>
                                          <BacktestReportOverview
                                            activeTheme={props.activeTheme}
                                          />
                                        </Suspense>
                                      }
                                      blankPageChild={
                                        <Suspense fallback={<NeotonLoader />}>
                                          <BacktestReportOverview
                                            activeTheme={props.activeTheme}
                                          />
                                        </Suspense>
                                      }
                                      commonWalletReport={commonWalletReport}
                                      attachedStrategy={
                                        strategyQuery.data?.payload
                                      }
                                      handleAttachStrategy={(
                                        _strategyId,
                                        _versionId
                                      ) => {
                                        setStrategyId(_strategyId);
                                        setVersionId(_versionId);
                                        setBacktestTradingReport(undefined);
                                      }}
                                      loadingInitialReport={
                                        loadingInitalReport ||
                                        strategyQuery.isLoading
                                      }
                                      flushReport={() =>
                                        setBacktestTradingReport(undefined)
                                      }
                                      simulationInfo={simulationInfo}
                                    />
                                  </SetHasJoinedPubSubGroup.Provider>
                                </HasJoinedPubSubGroup.Provider>
                              </StageBacktestContext.Provider>
                            </BacktestProgressContext.Provider>
                          </SetCancelBacktestContext.Provider>
                        </CancelBacktestContext.Provider>
                      </IsBacktestingContext.Provider>
                    </IsStagingContext.Provider>
                  </StageableContext.Provider>
                </GetBacktestStageRequestContext.Provider>
              </SetBacktestStageRequestContext.Provider>
            </LoadBacktestReportFromBlobContext.Provider>
          </IndicatorInfosContext.Provider>
        </ApplicationIndicatorsContext.Provider>
      </CurrencyContext.Provider>
    </>
  );
}

export const StageableContext = createContext<boolean>(false);
export const IsStagingContext = createContext<boolean>(false);
export const IsBacktestingContext = createContext<boolean>(false);
export const BacktestProgressContext = createContext<number | undefined>(
  undefined
);
export const LoadBacktestReportFromBlobContext = createContext<
  (blobId: string) => Promise<void>
>(async () => {});
export const StageBacktestContext = createContext<
  (_backtestStageRequest: BacktestStageRequest) => Promise<void>
>(async () => {});
export const TradingReportContext = createContext<
  BacktestTradingReport | undefined
>(undefined);

export const SetCancelBacktestContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => {});

export const CancelBacktestContext = createContext<boolean>(false);
export const HasJoinedPubSubGroup = createContext<boolean>(false);
export const SetHasJoinedPubSubGroup = createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => {});

export interface SimulationInfo {
  progress?: number;
  execution_count?: number;
  billing_start?: number;
  billing_end?: number;
  status?: string;
  status_message?: string;
  download_progress?: number;
}
