import { IChartApi, LineSeries, Time } from "lightweight-charts";
import { IOHLCData } from "../../financial-chart/iOHLCData";
import { ATR } from "../../../../interfaces/tw-chart/TWChartSettings";
import { atr } from "indicatorts"; // Ensure this is available
import { CustomChartAddon } from "../TWChart";

export function addATRSeries(
  chart: IChartApi,
  data: IOHLCData[],
  indicatorConfig: ATR,
  theme: any = null,
  paneIdx: number = 0
): CustomChartAddon {
  // Add a new LineSeries to represent the RSI
  const ATRSeries = chart.addSeries(LineSeries, {
    priceScaleId: "right", // adjust as needed (you might want a separate scale)
  }, paneIdx);

  // Calculate the RSI values from the closing prices and indicator configuration
  const atrData = atr(
    data.map((item) => item.high),
    data.map((item) => item.low),
    data.map((item) => item.close),
    indicatorConfig.config
  );

  // Map the calculated RSI values to the data format expected by lightweight-charts
  ATRSeries.setData(
    atrData.atrLine.map((value, index) => ({
      time: (data[index].ts / 1000) as Time,
      value,
    }))
  );

  // Apply series options (using your indicator's color and other settings)
  ATRSeries.applyOptions({
    color: indicatorConfig.color,
    lineWidth: 1,
    priceLineVisible: false,
  });

  const label = `ATR`;

  return {
    chartData: {
      [label]: ATRSeries,
    },
    colorMap: {
      [label]: indicatorConfig.color,
    },
  };
}
