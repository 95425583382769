import { IChartApi, LineSeries, Time } from "lightweight-charts";
import { IOHLCData } from "../../financial-chart/iOHLCData";
import { BOP } from "../../../../interfaces/tw-chart/TWChartSettings";
import { bop } from "indicatorts"; // Ensure this function exists
import { CustomChartAddon } from "../TWChart";

export function addBOPSeries(
  chart: IChartApi,
  data: IOHLCData[],
  indicatorConfig: BOP,
  theme: any = null,
  paneIdx: number = 0
): CustomChartAddon {
  // Create two series: one for Aroon Up and one for Aroon Down.
  const BOPSeries = chart.addSeries(
    LineSeries,
    {
      priceScaleId: "right", // adjust as needed (you might want a separate scale)
    },
    paneIdx
  );

  const bopData = bop(
    data.map((item) => item.open),
    data.map((item) => item.high),
    data.map((item) => item.low),
    data.map((item) => item.close)
  );

  // Map the computed Aroon values to the data format expected by lightweight-charts.
  BOPSeries.setData(
    bopData.map((val, index) => ({
      time: (data[index].ts / 1000) as Time,
      value: val,
    }))
  );

  // Apply options to style the lines
  BOPSeries.applyOptions({
    color: indicatorConfig.color || theme.text,
    lineWidth: 1,
    priceLineVisible: false,
  });

  const labelUp = `Balance of Power`;

  return {
    chartData: {
      [labelUp]: BOPSeries,
    },
    colorMap: {
      [labelUp]: indicatorConfig.color || theme.text,
    },
  };
}
