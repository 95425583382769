import { Case } from "../interfaces/strategyInterfaces/Strategy";

export const validateCase = (strategyCase: Case) => {
  // check if the case can trade
  const remarks: string[] = [];
  if (
    (strategyCase.exit_criteria?.candle_size_1D.length ?? 0) === 0 &&
    (strategyCase.exit_criteria?.candle_size_1h.length ?? 0) === 0 &&
    (strategyCase.exit_criteria?.candle_size_5m.length ?? 0) === 0 &&
    (strategyCase.stoploss_type ?? "disabled") === "disabled" &&
    (strategyCase.take_profit_type ?? "disabled") === "disabled"
  ) {
    remarks.push(
      "This case can never exit a trade, please add exit criterias or enable stoploss/take profit"
    );
  }
  if (
    (strategyCase.entry_criteria?.candle_size_1D.length ?? 0) === 0 &&
    (strategyCase.entry_criteria?.candle_size_1h.length ?? 0) === 0 &&
    (strategyCase.entry_criteria?.candle_size_5m.length ?? 0) === 0
  ) {
    remarks.push(
      "This case have no entry criterias and can therefore never enter a trade"
    );
  }
  if (strategyCase.included_currencies.length === 0) {
    remarks.push(
      "This case have no included currencies and can therefore never trade"
    );
  }
  return remarks;
};
