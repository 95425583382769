import React, { useMemo } from "react";
import {
  Heading,
  List,
  ListItem,
  Paragraph,
  Section,
} from "../../pages/legal/Typegraphy";
import "./legal-component.scss";
import { Space } from "@mantine/core";
import { getCurrentYear } from "../../utils/TimeUtils";

interface Props {
  printRef?: React.LegacyRef<HTMLDivElement> | undefined;
}
export function TermsOfService(props: React.PropsWithChildren<Props>) {
  const currentYear = useMemo(() => getCurrentYear(), []);
  const effectiveDate = "September 26, 2024"; // Update this as necessary

  return (
    <div className="tos-container" ref={props.printRef}>
      <Section>
        <Heading>Terms of Service</Heading>
        <Paragraph>Effective Date: {effectiveDate}</Paragraph>
        <Paragraph>
          Please read these Terms of Service ("Terms") carefully before using
          the Neoton Crypto Trading Software (referred to as "Neoton," "we,"
          "us," or "our"). By accessing or using our trading software, you
          ("you" or "user") agree to be bound by these Terms. If you do not
          agree to these Terms, please do not use our services.
        </Paragraph>
        <Paragraph>
          Welcome to Neoton, your premier destination for crypto trading
          solutions. Our software offers a comprehensive suite of tools and
          services designed to enhance your trading experience and maximize your
          potential in the cryptocurrency market. Whether you are a seasoned
          trader or just starting, Neoton provides the resources you need to
          succeed.
        </Paragraph>
        <Paragraph>
          Please take the time to review these Terms carefully, as they govern
          your use of the Neoton Crypto Trading Software. By accessing or using
          our software, you acknowledge that you have read, understood, and
          agreed to these Terms, as well as our Privacy Policy. These Terms
          constitute a legally binding agreement between you and Neoton.
        </Paragraph>
        <Paragraph>
          Thank you for choosing Neoton. We are excited to have you on board,
          and we look forward to helping you achieve your trading goals.
        </Paragraph>
      </Section>

      <Section>
        <Heading>1. Interpretation and Definitions</Heading>
        <Paragraph>
          1.1 Interpretation: In these Terms of Service ("Terms"), unless the
          context otherwise requires:
        </Paragraph>
        <List>
          <ListItem>
            Words importing the singular include the plural and vice versa.
          </ListItem>
          <ListItem>
            References to a person include individuals, companies, corporations,
            partnerships, and other legal entities.
          </ListItem>
          <ListItem>
            Headings are for convenience only and do not affect the
            interpretation of these Terms.
          </ListItem>
          <ListItem>
            References to "including" or "includes" mean "including, but not
            limited to."
          </ListItem>
        </List>
        <Paragraph>
          1.2 Definitions: The following terms shall have the meanings ascribed
          to them below:
        </Paragraph>
        <List>
          <ListItem>
            <strong>Neoton Crypto Trading Software</strong>: Refers to the
            digital software provided by Neoton for trading cryptocurrencies and
            related services.
          </ListItem>
          <ListItem>
            <strong>User</strong>: Any individual or entity accessing or using
            the Neoton Crypto Trading Software.
          </ListItem>
          <ListItem>
            <strong>Subscription Plans</strong>: Options available to users for
            accessing the Neoton Crypto Trading Software, including free and
            paid subscription options.
          </ListItem>
          <ListItem>
            <strong>Subscription Fee</strong>: The fee payable by the user for
            accessing the Neoton Crypto Trading Software under a subscription
            plan.
          </ListItem>
          <ListItem>
            <strong>Automated Trading</strong>: Trading strategies and processes
            executed automatically by algorithms or computer programs.
          </ListItem>
          <ListItem>
            <strong>Proprietary AI Models (AI)</strong>: Artificial intelligence
            models provided by Neoton or developed by users for trading analysis
            and decision-making.
          </ListItem>
          <ListItem>
            <strong>Technical Indicators (TA)</strong>: Analytical tools used to
            forecast price movements and trends in the cryptocurrency market.
          </ListItem>
          <ListItem>
            <strong>Pattern Trading Indicators (PT)</strong>: Indicators
            identifying potential chart patterns to predict future price
            movements in cryptocurrency trading.
          </ListItem>
          <ListItem>
            <strong>GDPR</strong>: The General Data Protection Regulation of the
            European Union, governing the processing and protection of personal
            data.
          </ListItem>
          <ListItem>
            <strong>Data Breach</strong>: Unauthorized access, disclosure, or
            acquisition of personal data, posing a risk to the security or
            integrity of such data.
          </ListItem>
        </List>
      </Section>

      <Section>
        <Heading>2. Acceptance of Terms</Heading>
        <Paragraph>
          <List>
            <ListItem>
              2.1 By accessing or using the Neoton Crypto Trading Software, you
              acknowledge that you have read, understood, and agreed to these
              Terms, as well as our Privacy Policy. These Terms constitute a
              legally binding agreement between you and Neoton.
            </ListItem>
          </List>
        </Paragraph>
      </Section>
      <Section>
        <Heading>3. Subscription and Payment</Heading>
        <List>
          <ListItem>
            3.1 Subscription Plans: Neoton provides a Free tier with no
            subscription required, while the Basic and Pro tiers are available
            for durations of 1 month, 3 months, or 12 months.
          </ListItem>
          <ListItem>
            3.2 Payment: You agree to pay the applicable subscription fee in
            advance for the chosen subscription plan. Payment details and
            methods will be provided during the subscription process. Neoton
            utilizes Stripe, a third-party payment processor, to securely handle
            payment transactions. By using our software and providing payment
            information, you agree to Stripe's Terms of Service and Privacy
            Policy.
          </ListItem>
          <ListItem>
            3.3 Automatic Renewal: Unless you cancel your subscription before
            the end of the current subscription period, your subscription will
            automatically renew for a subsequent period at the current
            subscription rate.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>4. Subscription Cancellation and Refund Policy</Heading>
        <List>
          <ListItem>
            4.1 Right to Cancel: Users may cancel their subscription at any
            time.
          </ListItem>
          <ListItem>
            <strong>
              4.2 Waiver of Right of Withdrawal for Ongoing Subscription
              Periods: Upon the purchase of a subscription, the Neoton Crypto
              Trading Software is considered to be in use immediately, which
              effectively commences the subscription period. As a result, the
              consumer waives the right of withdrawal from the moment of
              purchase. There are no refunds or rights of withdrawal for the
              ongoing subscription periods once the service is activated. By
              purchasing a subscription, customers acknowledge this condition
              and agree that paid subscriptions are non-refundable under these
              circumstances.
            </strong>
          </ListItem>
          <ListItem>
            4.3 Cancellation Process: To cancel your subscription, ensure that
            you do not pay for the next billing cycle. Upon cancellation, Neoton
            is not obligated to perform any further services.
          </ListItem>
          <ListItem>
            4.4 Data Retention Post-Cancellation: Upon the termination of your
            subscription, Neoton is not obligated to store or retain your data
            unless legally required.
          </ListItem>
          <ListItem>
            4.5 Neoton’s Right to Cancel: Neoton reserves the right to terminate
            your subscription with a three-month notice. In cases of significant
            breach of these Terms by the user, Neoton may terminate the
            subscription immediately without notice.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>5. Use of the Software</Heading>
        <List>
          <ListItem>
            5.1 Eligibility: You must be at least 18 years of age or the legal
            age required in your country, whichever is higher, and have the
            legal capacity to enter into this agreement. By accessing or using
            our software, you represent and warrant that you meet these
            requirements.
          </ListItem>
          <ListItem>
            5.2 Prohibited Activities: You agree not to use the Neoton Crypto
            Trading Software for any illegal, unauthorized, or fraudulent
            purposes. This includes, but is not limited to, engaging in market
            manipulation, unauthorized trading, or any other activity that
            violates applicable laws and regulations.
          </ListItem>
          <ListItem>
            5.3 Responsibility for Trades: Neoton is not responsible for any
            losses, damages, or financial consequences resulting from your
            trading activities using trading algorithms or any other product
            originating from our software. All trading decisions are made solely
            by you, and you assume all risks associated with your trades.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>6. Termination</Heading>
        <List>
          <ListItem>
            Neoton reserves the right to terminate or suspend your access to the
            trading software at any time and for any reason, including violation
            of these Terms or misuse of the software.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>7. Disclaimer of Warranties</Heading>
        <List>
          <ListItem>
            7.1 Neoton provides the trading software "as is" and does not make
            any warranties, express or implied, regarding the accuracy,
            reliability, or availability of the software.
          </ListItem>
          <ListItem>
            7.2 You acknowledge that the cryptocurrency market is highly
            volatile, and that trading involves risks. Neoton does not guarantee
            the continuous, uninterrupted, or secure access to our software, and
            we disclaim any responsibility for any loss or damage arising from
            interruptions or outages of our services.
          </ListItem>
          <ListItem>
            7.3 Neoton does not warrant that the software will meet your
            specific requirements, or that any defects or errors will be
            corrected. While we strive to provide a reliable and efficient
            trading software, you acknowledge that the use of our services is at
            your own risk, and that Neoton shall not be liable for any direct,
            indirect, incidental, special, consequential, or punitive damages
            resulting from your use or inability to use the software.
          </ListItem>
          <ListItem>
            7.4 By accessing or using the Neoton Crypto Trading Software, you
            agree to assume all risks associated with cryptocurrency trading and
            acknowledge that Neoton shall not be held responsible for any
            financial losses, damages, or other adverse outcomes resulting from
            your trading activities on our software.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>8. Limitation of Liability</Heading>
        <List>
          <ListItem>
            <strong>
              8.1 To the fullest extent permitted by applicable law, Neoton
              disclaims all liability for any direct, indirect, incidental,
              special, consequential, or punitive damages arising out of or in
              connection with your use of the software.
            </strong>
          </ListItem>
          <ListItem>
            <strong>
              8.2 Neoton shall not be liable for any financial losses, data
              loss, interruption of business, or any other damages resulting
              from:
            </strong>
            <Space h={"xs"} />
            <List>
              <ListItem>
                <strong>
                  Interruption, suspension, or termination of the software's
                  services.
                </strong>
              </ListItem>
              <ListItem>
                <strong>
                  Unauthorized access to or alteration of your data.
                </strong>
              </ListItem>
              <ListItem>
                <strong>
                  Any errors, inaccuracies, or omissions in the content provided
                  on the software.
                </strong>
              </ListItem>
              <ListItem>
                <strong>
                  Any unauthorized use of the software by third parties.
                </strong>
              </ListItem>
              <ListItem>
                <strong>
                  Any other matter relating to the software or its services.
                </strong>
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>
              8.3 You acknowledge that the limitations of liability set forth in
              this section apply even if Neoton has been advised of the
              possibility of such damages.
            </strong>
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>9. Neoton Point Reward Program </Heading>
        <List>
          <ListItem>
            9.1 The Neoton Point Reward Program ("Program") is open to all
            customers signed up with Neoton. Participation in the Program is
            automatic upon signing up and no additional sign-up is required.
          </ListItem>
          <ListItem>
            9.2 Points are earned based on subscription payments, referrals, and
            other promotional activities as specified by Neoton. Points are
            credited to your account upon successful payment or completion of
            the qualifying activity. The rate of earning points may vary by
            subscription tier or promotional offers.
          </ListItem>
          <ListItem>
            9.3 Points can be redeemed for discounts on future subscription
            payments, merchandise, or other rewards as specified on our Point
            Reward page. Points cannot be redeemed for cash or transferred to
            other accounts. Redemption rates and available rewards are subject
            to change without notice.
          </ListItem>
          <ListItem>
            9.4 Neoton reserves the right to modify, suspend, or terminate the
            Program at any time, with or without notice. Changes to the Program
            may affect your ability to earn or redeem points.
          </ListItem>
          <ListItem>
            9.5 Any fraudulent activity, misuse of points, or violation of these
            terms will result in immediate disqualification from the Program,
            forfeiture of points, and possible legal action.
          </ListItem>
          <ListItem>
            9.6 Neoton is not responsible for potential tax or legal
            consequences of your receival or redemption of points, nor for any
            loss, damage, or inconvenience caused by your participation in the
            Program, the suspension or termination of the Program or the
            inability to redeem points.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>10. Changes to the Terms</Heading>
        <List>
          <ListItem>
            10.1 Neoton reserves the right to modify these Terms at any time.
            Changes will be effective upon posting on our website. We may
            provide notice of significant changes via email or other means
            deemed appropriate by Neoton. Your continued use of the software
            after such changes will constitute your acceptance of the revised
            Terms.
          </ListItem>
          <ListItem>
            10.2 Neoton understands the importance of transparency and will
            endeavor to communicate any modifications to these Terms in a clear
            and timely manner. We encourage you to review these Terms
            periodically to stay informed about any updates or revisions.
          </ListItem>
          <ListItem>
            10.3 In the event of any conflict or inconsistency between the
            original Terms and any modified Terms, the modified Terms shall
            prevail to the extent of such conflict or inconsistency.
          </ListItem>
          <ListItem>
            10.4 If you do not agree with any changes to these Terms, you may
            choose to discontinue your use of the software. Your continued use
            of the software following the posting of changes indicates your
            acceptance of the modified Terms.
          </ListItem>
          <ListItem>
            10.5 Neoton reserves the right to modify, suspend, or discontinue
            the software or any part thereof at any time without prior notice.
            We shall not be liable to you or any third party for any
            modification, suspension, or discontinuation of the software.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>
          11. Compliance with Authorities and Information Sharing
        </Heading>
        <List>
          <ListItem>
            11.1 Neoton is committed to complying with all applicable laws and
            regulations, including those related to financial transactions and
            reporting. We prioritize transparency and accountability in our
            operations and strive to uphold the highest standards of legal and
            regulatory compliance.
          </ListItem>
          <ListItem>
            11.2 In cases where legal obligations require us to provide
            information to relevant authorities or regulatory bodies, Neoton
            reserves the right to share user account information, transaction
            details, and other relevant data as necessary to fulfill these
            obligations. We may also cooperate with law enforcement agencies,
            regulatory authorities, or other governmental entities in
            investigations related to fraud, illegal activities, or violations
            of our Terms of Service.
          </ListItem>
          <ListItem>
            11.3 You acknowledge and agree that Neoton may, in its sole
            discretion, disclose information related to your trading activities,
            account details, and other relevant data to comply with legal
            requirements, respond to lawful requests, protect our rights, or
            ensure the safety and security of our users and the software.
          </ListItem>
          <ListItem>
            11.4 Neoton understands the importance of safeguarding user privacy
            and will take appropriate measures to protect the confidentiality
            and integrity of user information in accordance with applicable laws
            and regulations. However, you acknowledge that no system can
            guarantee complete security, and Neoton shall not be liable for any
            unauthorized access to or disclosure of user information resulting
            from factors beyond our control.
          </ListItem>
          <ListItem>
            11.5 We encourage users to familiarize themselves with our Privacy
            Policy, which outlines our practices regarding the collection, use,
            and disclosure of personal information. By using our software, you
            consent to the collection, processing, and sharing of your
            information as described in the Privacy Policy.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>12. Automated Trading and Indicators</Heading>
        <Paragraph>
          Neoton allows users to employ automated trading strategies,
          proprietary AI models (AI), technical indicators (TA), and pattern
          trading indicators (PT) on the software. While we provide these tools
          as a convenience, it is essential to understand the associated risks
          and responsibilities:
        </Paragraph>
        <List>
          <ListItem>
            12.1 Automated Trading: Users may develop and implement automated
            trading strategies, relying on algorithms and predefined rules.
            Neoton is not responsible for any losses incurred due to the use of
            automated trading, as all trading decisions and outcomes are the
            user's sole responsibility.
          </ListItem>
          <ListItem>
            12.2 Proprietary AI Models (AI): Users may utilize proprietary AI
            models provided by Neoton or develop their own. These models are
            meant to assist in decision-making, but their predictions and
            recommendations are not guaranteed. Users should exercise caution
            and judgment when using AI models.
          </ListItem>
          <ListItem>
            12.3 Technical Indicators (TA): Technical indicators may offer
            insights into market trends, but they do not guarantee trading
            success. Users should thoroughly understand the indicators they
            employ and be aware of the inherent limitations.
          </ListItem>
          <ListItem>
            12.4 Pattern Trading Indicators (PT): Pattern trading indicators
            identify potential chart patterns that could suggest future price
            movements. These indicators are not infallible and should be used in
            conjunction with other analysis methods.
          </ListItem>
          <ListItem>
            12.5 User Responsibility: Users are responsible for the strategies,
            indicators, and models they use on the Neoton software. Neoton does
            not provide financial or investment advice and is not liable for any
            financial losses resulting from trading activities.
          </ListItem>
          <ListItem>
            12.6 Risk Disclosure: All trading involves risk, including the use
            of automated strategies and indicators. Users should be aware of
            market volatility, technical glitches, and other factors that may
            impact trading outcomes.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>13. Subscription Cancellation</Heading>
        <Paragraph>
          Users of the Neoton Crypto Trading Software have the option to cancel
          their subscription at any time by accessing their profile on our
          software. The process for subscription cancellation is designed to be
          straightforward and accessible to users, providing them with the
          flexibility to manage their subscription status according to their
          preferences.
        </Paragraph>
        <List>
          <ListItem>
            13.1 Accessing Profile: To initiate the subscription cancellation
            process, users can navigate to their profile settings within the
            Neoton software. Within the profile settings, there will be an
            option or section specifically dedicated to managing subscriptions.
          </ListItem>
          <ListItem>
            13.2 Cancellation Request: Within the subscription management
            section, users will find an option to request cancellation of their
            subscription. By selecting this option, users can indicate their
            intention to terminate their subscription to the Neoton software.
          </ListItem>
          <ListItem>
            13.3 Confirmation: Upon submitting a cancellation request, users may
            receive a confirmation prompt to ensure that they indeed wish to
            proceed with the cancellation. This confirmation step helps prevent
            accidental cancellations and allows users to confirm their decision
            before finalizing the process.
          </ListItem>
          <ListItem>
            13.4 Confirmation Email: Following the submission of a cancellation
            request, users will receive a confirmation email from Neoton
            acknowledging the receipt of their request. This email serves as a
            record of the cancellation request and provides users with further
            instructions or information if necessary.
          </ListItem>
          <ListItem>
            13.5 Effective Date: Users can cancel their subscription all the way
            up until the renewal date of their subscription period. However,
            users will not be refunded for any remaining days or hours of the
            current subscription period upon cancellation. The cancellation will
            take effect at the end of the current subscription term.
          </ListItem>
          <ListItem>
            13.6 Refund Policy: Neoton's refund policy regarding subscription
            cancellations may vary depending on the specific terms and
            conditions applicable to each subscription plan. Users are
            encouraged to review the refund policy or contact customer support
            for further clarification if needed.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>14. GDPR Compliance</Heading>
        <Paragraph>
          Neoton recognizes the importance of protecting the privacy and data
          rights of individuals, particularly in accordance with the General
          Data Protection Regulation (GDPR) of the European Union. As such, the
          following provisions outline Neoton’s commitment to GDPR compliance:
        </Paragraph>
        <List>
          <ListItem>
            14.1 Data Collection and Processing: Neoton collects and processes
            personal data in accordance with the principles of lawfulness,
            fairness, and transparency. We only collect personal data that is
            necessary for the provision of our services and do not retain it for
            longer than is necessary.
          </ListItem>
          <ListItem>
            14.2 Lawful Basis for Processing: Neoton ensures that we have a
            lawful basis for processing personal data under the GDPR. This may
            include obtaining explicit consent from individuals, fulfilling
            contractual obligations, complying with legal obligations,
            protecting vital interests, or pursuing legitimate interests, as
            outlined in Article 6 of the GDPR.
          </ListItem>
          <ListItem>
            14.3 Data Subject Rights: Neoton respects the rights of data
            subjects as outlined in the GDPR, including the right to access,
            rectification, erasure, restriction of processing, data portability,
            and the right to object to processing. Users can exercise these
            rights by contacting Neoton directly at neoton@neoton.io.
          </ListItem>
          <ListItem>
            14.4 Data Security: Neoton implements appropriate technical and
            organizational measures to ensure the security of personal data
            against unauthorized or unlawful processing and against accidental
            loss, destruction, or damage, as required by Article 32 of the GDPR.
          </ListItem>
          <ListItem>
            14.5 International Data Transfers: Neoton ensures that any
            international transfers of personal data comply with the
            requirements of the GDPR, including implementing appropriate
            safeguards such as Standard Contractual Clauses or ensuring the
            receiving country has an adequate level of data protection.
          </ListItem>
          <ListItem>
            14.6 Data Breach Notification: In the event of a data breach
            involving personal data, Neoton will notify the relevant supervisory
            authority and affected individuals in accordance with the
            requirements of the GDPR.
          </ListItem>
          <ListItem>
            14.7 Data Protection Impact Assessments (DPIAs): Neoton conducts
            DPIAs where processing operations are likely to result in a high
            risk to the rights and freedoms of individuals, as required by
            Article 35 of the GDPR.
          </ListItem>
          <ListItem>
            14.8 Data Processing Agreements: Neoton enters into data processing
            agreements with any third-party service providers that process
            personal data on our behalf, ensuring that they also comply with the
            GDPR requirements.
          </ListItem>
        </List>
        <Paragraph>
          By using the Neoton Crypto Trading Software, users acknowledge and
          agree to the processing of their personal data in accordance with the
          GDPR and the terms outlined in our Privacy Policy.
        </Paragraph>
      </Section>
      <Section>
        <Heading>15. User Responsibilities</Heading>
        <List>
          <ListItem>
            15.1 Compliance with Laws: Users are required to comply with all
            applicable laws, regulations, and legal obligations when using the
            Neoton Crypto Trading Software. This includes, but is not limited
            to, laws related to cryptocurrency trading, financial transactions,
            and data protection.
          </ListItem>
          <ListItem>
            15.2 Account Security: Users are responsible for maintaining the
            security of their account credentials and ensuring that unauthorized
            access to their account does not occur. Any suspicious activity or
            unauthorized access should be reported to Neoton immediately for
            investigation.
          </ListItem>
          <ListItem>
            15.3 Prohibited Activities: Users must refrain from engaging in any
            activities that may harm the integrity or functioning of the
            software, including but not limited to hacking, phishing, or any
            other form of cyberattack. Any such activity will result in
            immediate termination of the user's account and may be subject to
            legal action.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>16. Intellectual Property Rights</Heading>
        <List>
          <ListItem>
            16.1 Ownership: All intellectual property rights related to the
            Neoton Crypto Trading Software, including trademarks, copyrights,
            and proprietary technology, are owned by Neoton. Users are granted a
            limited, non-exclusive license to use the software for its intended
            purposes only.
          </ListItem>
          <ListItem>
            16.2 User-Generated Content: Any content, feedback, or suggestions
            provided by users to Neoton may be used by Neoton for the
            improvement or promotion of the software. By submitting such
            content, users grant Neoton a perpetual, irrevocable, royalty-free
            license to use, reproduce, modify, adapt, publish, translate,
            distribute, and display such content.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>17. Governing Law and Dispute Resolution</Heading>
        <List>
          <ListItem>
            17.1 These Terms and any disputes arising out of or relating to
            these Terms, including disputes regarding their validity,
            interpretation, or enforcement, and any disputes regarding use of
            Neoton shall be governed by and construed in accordance with the
            laws of Denmark, without regard to its conflict of law principles
          </ListItem>
          <ListItem>
            17.2 If any provision of these Terms is found to be invalid,
            illegal, or unenforceable, such provision shall be deemed modified
            to the minimum extent necessary to make it valid, legal, and
            enforceable. If such modification is not possible, the provision
            shall be deemed severed from these Terms, and the validity,
            legality, and enforceability of the remaining provisions shall not
            in any way be affected or impaired.
          </ListItem>
          <ListItem>
            17.3 Any legal action or proceeding arising under these Terms shall
            be brought exclusively in the courts located in Copenhagen, Denmark,
            and you hereby consent to the jurisdiction of such courts for such
            purposes. You agree that any legal action or proceedings arising out
            of or in connection with these Terms shall be brought exclusively in
            the competent courts of Denmark. You hereby irrevocably submit to
            the jurisdiction of these courts and waive any objection to the
            laying of venue of any litigation in said courts and any claim that
            such litigation has been brought in an inconvenient forum.
          </ListItem>
          <ListItem>
            17.4 Prior to initiating legal proceedings, the parties agree to
            attempt to resolve any disputes or claims through good-faith
            negotiations.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>18. Data Protection</Heading>
        <List>
          <ListItem>
            18.1 Data Security: Neoton implements industry-standard security
            measures to protect user data from unauthorized access, disclosure,
            alteration, or destruction. These measures include encryption
            protocols, access controls, and regular security audits.
          </ListItem>
          <ListItem>
            18.2 Data Privacy: Neoton respects the privacy rights of its users
            and adheres to all applicable data protection laws and regulations.
            The collection, use, and processing of personal data are governed by
            Neoton's Privacy Policy, which users are encouraged to review for
            more information.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>19. Contact Information</Heading>
        <Paragraph>
          If you have any questions or concerns about these Terms, please
          contact us at{" "}
          <a href="mailto:neoton@neoton.io">
            <strong>neoton@neoton.io</strong>
          </a>
          .
        </Paragraph>
      </Section>
      <Section>
        <Heading>20. Conclusion and Acknowledgement</Heading>
        <Paragraph>
          By using the Neoton Crypto Trading Software, you agree to abide by
          these Terms and our Privacy Policy. Thank you for choosing Neoton to
          empower your crypto trading.
        </Paragraph>
      </Section>
      <Section>
        <Paragraph>Neoton © {currentYear}. All rights reserved.</Paragraph>
      </Section>
      <Section>
        <Space h={"xl"} />
        <Space h={"xl"} />
        <Space h={"xl"} />
      </Section>
    </div>
  );
}
