import * as React from "react";

interface Props {
  color?: string;
  size?: number;
}
export function MixedMarketIcon(props: React.PropsWithChildren<Props>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox="0 0 279 264"
      color={props.color}
      stroke={props.color}
      fill={props.color}
      {...props}
    >
      <path d="M217.1 21c-2.8 3.8-5.1 7.3-5.1 7.6 0 .8-12.9 18.6-14.5 19.9-.6.5-4.4 5.5-8.3 11s-7.7 10.8-8.4 11.7c-1.1 1.6-1.9 1.6-5.8.6-2.5-.6-4.8-1.5-5.1-2-2-3.1-47.9-3-47.9.1 0 .4-2.4 1.3-5.2 2-2.9.7-7.4 2-10 2.9-5.3 1.8-6.8 1.4-12.8-4-7.1-6.3-20.6-10.6-27.1-8.8-6.5 1.9-10.7 5.6-14.1 12.7-2.8 5.9-3.2 7.7-3.2 14.8 0 15.2 7.4 31.3 16.5 36 2.5 1.3 2.7 1.9 2.2 5.2-.3 2.1-1 4.1-1.5 4.4-1.2.8-4 31.1-3.6 39.2.4 6.6.4 6.8 5.4 11.5 8 7.6 13.8 12.6 16.4 14.3l2.4 1.6-8.8 12.1c-4.8 6.7-10.1 13.7-11.7 15.5-1.6 1.7-4.1 5.1-5.4 7.5l-2.4 4.3 4.5-.3c3.8-.3 4.8-.8 6.2-3.3.9-1.6 8.1-11.8 16-22.7 7.8-10.9 14.2-20.1 14.2-20.4 0-.9 10.2-14.8 11.4-15.6.6-.4 4.9-6.1 9.6-12.8 20.7-29.6 29.8-42.1 33.5-46.4 2.2-2.6 4.7-5.9 5.5-7.5.9-1.5 4.2-6.4 7.4-10.8l5.7-8 7.7-.2c29.8-.7 47.3-7.1 59.1-21.6 2.1-2.6 2.6-13.6.7-16-.9-1.1-2.9-3.7-4.5-5.8-2.9-3.8-9.7-9.7-11.1-9.7-.4 0 .2 2.6 1.2 5.7 2.3 6.7 1.8 11-1.8 15.2-3.3 4-10 6-22.1 6.8-11.8.7-12.5.1-7.1-5.7 4-4.3 14.4-18.5 25.4-34.7 6.5-9.5 8-12.3 6.8-12.7-3.7-1.4-5.4-.4-10.3 6.4M77.9 84.1c3.7 2.6 4.2 3.4 3.6 5.7-1 4.1-8.4 16.2-10 16.2-2.8 0-4.8-18.7-2.5-23.1 1.5-2.7 4-2.4 8.9 1.2m44.9 33.2c2.6 2.3 10.2 13.4 10.2 14.8 0 .4-2.8 1-6.2 1.4-11 1.1-20.8-3.5-20.8-9.7 0-4.1 1.6-6.6 5.3-8.3 4-1.8 8.4-1.1 11.5 1.8" />
      <path d="M175.4 117.4c-3.6 1.6-7.3 7-8.1 11.9-.5 3.3-.4 3.4 3.3 4 7.1 1.1 11.5-1.1 14-7 2.2-5.1 1.8-9-.8-9.7-3.3-.8-4.9-.7-8.4.8M158.6 183.4c-2 3.2-2.1 2.8.9 7.2 8.3 12.2-6.4 27.3-18.6 19.1-6.9-4.6-8.6-13.4-3.8-20 1.9-2.6 1.9-3 .5-5.3-2-3-2.7-3-5.4-.1-11.2 11.9-3 33.1 13.3 34.5 14.7 1.2 24.4-8.2 23.3-22.8-.3-4.7-1.1-6.9-3.5-10.2-3.8-5.2-4.6-5.5-6.7-2.4" />
    </svg>
  );
}
