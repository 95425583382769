import "./order-profiles.scss";
import { useCallback, useContext, useMemo, useState } from "react";
import { SegmentedControl, Select, Slider } from "@mantine/core";
import { RefetchUserItemsContext, UserItemsContext } from "../../../App";
import {
  DefaultBuyOrderProfile,
  DefaultSellOrderProfile,
  DefaultOptimisticBuyOrderProfile,
  DefaultOptimisticSellOrderProfile,
  OrderProfile,
  OrderProfileAttempt,
} from "../../../interfaces/strategyInterfaces/Strategy";
import { capitalize } from "../../../utils/CapitalizeString";
import { getTheme } from "../../../utils/themeUtil";
import { OrderProfileChart } from "../../chart-components/order-profile-chart/OrderProfileChart";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";
import { FiTrash2 } from "react-icons/fi";
import { MdSaveAlt } from "react-icons/md";
import CountUp from "react-countup";
import { useAuth0 } from "@auth0/auth0-react";
import { StrategyManagerService } from "../../../services/StrategyManagerService";

interface Props {
  activeTheme: string;
}

export function OrderProfileEditor(props: React.PropsWithChildren<Props>) {
  const [profileMode, setProfileMode] = useState<"entry" | "exit">("entry");
  const { getAccessTokenSilently } = useAuth0();
  const userItems = useContext(UserItemsContext);
  const refetchUserItems = useContext(RefetchUserItemsContext);
  const theme = useMemo(() => {
    return getTheme(props.activeTheme);
  }, [props.activeTheme]);

  const orderProfileTypeDescriptions = {
    buy: "Using ATR multipliers can give you an opportunity to buy in at a lower price.",
    sell: "Using ATR multipliers can give you an opportunity to sell at a higher price.",
  };

  const orderProfileDescription =
    "Take advantage of flexible order profiles to assist with order placement. Customize 5 attempts with ATR multipliers to fit your strategy.";

  const priceFields = ["bid", "ask"];

  const defaultBuyProfiles: OrderProfile[] = [
    DefaultBuyOrderProfile,
    DefaultOptimisticBuyOrderProfile,
  ];
  const defaultSellProfiles: OrderProfile[] = [
    DefaultSellOrderProfile,
    DefaultOptimisticSellOrderProfile,
  ];
  const maxMultiplier = 5;
  const maxOrderProfileCount = 10;

  const [renderInput, setRenderInput] = useState(true);
  const [loading, setLoading] = useState(false);
  const [orderProfileInput, setOrderProfileInput] = useState<OrderProfile>();
  const [error, setError] = useState<string>();

  const _AllBuyProfileEntries = useMemo(() => {
    const entries: any[] = [];
    // Add default buy profiles
    defaultBuyProfiles.forEach((profile) => {
      entries.push({
        value: profile.name,
        label: profile.name,
        group: "Default order profiles",
      });
    });
    // Add user items
    if ((userItems?.order_profiles?.buy?.length ?? 0) > 0) {
      userItems!.order_profiles!.buy.forEach((profile) => {
        entries.push({
          value: profile.name,
          label: profile.name,
          group: "Custom order profiles",
        });
      });
    }

    return entries;
  }, [userItems, defaultBuyProfiles]);

  const _AllSellProfileEntries = useMemo(() => {
    const entries: any[] = [];
    // Add default sell profiles
    defaultSellProfiles.forEach((profile) => {
      entries.push({
        value: profile.name,
        label: profile.name,
        group: "Default order profiles",
      });
    });
    // Add user items
    if ((userItems?.order_profiles?.sell?.length ?? 0) > 0) {
      userItems!.order_profiles!.sell.forEach((profile) => {
        entries.push({
          value: profile.name,
          label: profile.name,
          group: "Custom order profiles",
        });
      });
    }

    return entries;
  }, [userItems, defaultSellProfiles]);

  const allBuyProfiles = useMemo(() => {
    return [...defaultBuyProfiles, ...(userItems?.order_profiles?.buy ?? [])];
  }, [userItems, defaultBuyProfiles]);

  const allSellProfiles = useMemo(() => {
    return [...defaultSellProfiles, ...(userItems?.order_profiles?.sell ?? [])];
  }, [userItems, defaultSellProfiles]);

  const fallbackToOrderProfile = useCallback(() => {
    setRenderInput(false);
    if (profileMode === "entry") {
      setOrderProfileInput(_AllBuyProfileEntries[-1]);
    } else {
      setOrderProfileInput(_AllSellProfileEntries[-1]);
    }
    setTimeout(() => {
      setRenderInput(true);
    }, 500);
  }, [
    profileMode,
    setOrderProfileInput,
    _AllBuyProfileEntries,
    _AllSellProfileEntries,
  ]);

  const handleSaveOrderProfiles = useCallback(async () => {
    if (!orderProfileInput) return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setError(undefined);
      setLoading(true);
      const _updatedUserOrderProfiles = userItems?.order_profiles ?? {
        buy: [],
        sell: [],
      };
      if (
        profileMode === "entry" &&
        _updatedUserOrderProfiles.buy.length >= maxOrderProfileCount
      ) {
        setError("You can only save up to 10 entry profiles.");
        return;
      }
      if (
        profileMode === "exit" &&
        _updatedUserOrderProfiles.sell.length >= maxOrderProfileCount
      ) {
        setError("You can only save up to 10 exit profiles.");
        return;
      }
      if (profileMode === "entry") {
        _updatedUserOrderProfiles.buy.push(orderProfileInput);
      } else {
        _updatedUserOrderProfiles.sell.push(orderProfileInput);
      }
      await StrategyManagerService.updateOrderProfiles(
        token,
        _updatedUserOrderProfiles
      );
      refetchUserItems();
    } finally {
      setLoading(false);
    }
  }, [
    getAccessTokenSilently,
    orderProfileInput,
    profileMode,
    refetchUserItems,
    userItems,
    setLoading,
    fallbackToOrderProfile,
    setError,
    maxOrderProfileCount,
  ]);

  const deletable = useMemo(() => {
    if (!orderProfileInput) return false;
    const _userProfiles =
      profileMode === "entry"
        ? userItems?.order_profiles?.buy
        : userItems?.order_profiles?.sell;
    return _userProfiles?.map((x) => x.name).includes(orderProfileInput.name);
  }, [orderProfileInput, profileMode, userItems]);

  const updateable = useMemo(() => {
    if (!orderProfileInput) return false;
    const _existingProfiles =
      profileMode === "entry" ? allBuyProfiles : allSellProfiles;
    const customProfileCount =
      _existingProfiles.length -
      (profileMode === "entry"
        ? defaultBuyProfiles.length
        : defaultSellProfiles.length);
    if (maxOrderProfileCount - customProfileCount <= 0) {
      return false;
    }
    return !_existingProfiles
      .map((x) => x.name)
      .includes(orderProfileInput.name);
  }, [
    orderProfileInput,
    profileMode,
    allBuyProfiles,
    allSellProfiles,
    defaultBuyProfiles,
    defaultSellProfiles,
    maxOrderProfileCount,
  ]);

  const handleDeleteOrderProfile = useCallback(async () => {
    if (!orderProfileInput) return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setLoading(true);
      const _updatedUserOrderProfiles = userItems?.order_profiles ?? {
        buy: [],
        sell: [],
      };
      if (profileMode === "entry") {
        _updatedUserOrderProfiles.buy = _updatedUserOrderProfiles.buy.filter(
          (profile) => profile.name !== orderProfileInput.name
        );
      } else {
        _updatedUserOrderProfiles.sell = _updatedUserOrderProfiles.sell.filter(
          (profile) => profile.name !== orderProfileInput.name
        );
      }
      await StrategyManagerService.updateOrderProfiles(
        token,
        _updatedUserOrderProfiles
      );
      refetchUserItems();
      fallbackToOrderProfile();
    } finally {
      setLoading(false);
    }
  }, [
    orderProfileInput,
    profileMode,
    setLoading,
    getAccessTokenSilently,
    userItems,
    refetchUserItems,
    fallbackToOrderProfile,
  ]);

  const selectStyle = {
    input: {
      transition: ".2s ease all",
    },
    separator: {
      color: theme.neoton,
    },
    item: {
      fontFamily: "BalooBhaijaan2",
    },
    selected: {
      fontFamily: "BalooBhaijaan2",
      color: theme.neoton,
    },
  };

  return (
    <div className="order-profiles-editor-container">
      <label
        className="dimmed-label"
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {orderProfileDescription}
      </label>

      <SegmentedControl
        title="Order profile type"
        size="xs"
        data={[
          { label: "Entry profiles", value: "entry" },
          { label: "Exit profiles", value: "exit" },
        ]}
        value={profileMode}
        onChange={(value) => {
          setProfileMode(value as any);
          setOrderProfileInput(undefined);
          fallbackToOrderProfile();
        }}
      />
      <label
        className="dimmed-label"
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {orderProfileTypeDescriptions[profileMode]}
      </label>

      {renderInput && (
        <div className="order-profiles-row order-profiles-container">
          <div
            className="order-profiles-column"
            style={{
              gap: 4,
            }}
          >
            <div
              className="order-profiles-row"
              style={{
                gap: 4,
              }}
            >
              <Select
                zIndex={1000}
                styles={selectStyle}
                label={
                  <>
                    <label>{capitalize(profileMode)} profiles | </label>
                    {error ? (
                      <label className="error-label">{error}</label>
                    ) : (
                      <label className="dimmed-label">
                        You can save up to {maxOrderProfileCount} {profileMode}{" "}
                        profiles
                      </label>
                    )}
                  </>
                }
                data={
                  profileMode === "entry"
                    ? _AllBuyProfileEntries
                    : _AllSellProfileEntries
                }
                size="xs"
                placeholder={`Select ${profileMode} profile`}
                nothingFound="Profile not found"
                clearable
                onChange={(value) => {
                  const _profile =
                    profileMode === "entry"
                      ? allBuyProfiles.find((profile) => profile.name === value)
                      : allSellProfiles.find(
                          (profile) => profile.name === value
                        );
                  if (!_profile) return;
                  setOrderProfileInput(_profile);
                }}
                style={{
                  width: "100%",
                }}
              />
              <CommonIconButton
                activeTheme={props.activeTheme}
                label="Delete"
                borderTheme="red-accent"
                icon={<FiTrash2 />}
                disabled={!deletable}
                style={{
                  alignSelf: "flex-end",
                }}
                loading={loading}
                onClick={handleDeleteOrderProfile}
              />
            </div>

            {orderProfileInput && (
              <>
                {Array.from(
                  { length: orderProfileInput.attempts },
                  (_, i) => i
                ).map((_, index) => {
                  const attempt = orderProfileInput[
                    `attempt_${index + 1}`
                  ] as OrderProfileAttempt;
                  return (
                    <div className="order-profile-attempt-row" key={index}>
                      <label className="dimmed-label">
                        Attempt {index + 1}
                      </label>
                      <SegmentedControl
                        title={`Attempt ${index + 1}`}
                        size="xs"
                        data={priceFields.map((field) => ({
                          label: field,
                          value: field,
                        }))}
                        value={attempt.field}
                        onChange={(value) => {
                          setOrderProfileInput({
                            ...orderProfileInput,
                            [`attempt_${index + 1}`]: {
                              ...attempt,
                              field: value,
                            },
                          });
                        }}
                      />
                      <div className="atr-multiplier-slider">
                        <Slider
                          min={0}
                          max={maxMultiplier}
                          step={0.1}
                          value={attempt.atr_multiplier}
                          onChange={(value) => {
                            const roundedValue = Math.round(value * 10) / 10; // Keep as number
                            setOrderProfileInput((prevProfile) => ({
                              ...prevProfile!,
                              [`attempt_${index + 1}`]: {
                                ...prevProfile![`attempt_${index + 1}`],
                                atr_multiplier: roundedValue,
                              },
                            }));
                          }}
                          showLabelOnHover={false}
                          styles={{
                            thumb: {
                              height: 14,
                              width: 4,
                              borderWidth: 1,
                              borderRadius: 2,
                              borderColor:
                                profileMode === "exit"
                                  ? theme.sellOrderAreaY
                                  : theme.buyOrderAreaY,
                              filter: "grayScale(0) !important",
                              backgroundColor:
                                profileMode === "exit"
                                  ? theme.sellOrderAreaX
                                  : theme.buyOrderAreaX,
                            },
                            bar: { backgroundColor: "transparent" },
                            track: {
                              backgroundColor: theme.cardInterior,
                              borderRadius: "20%",
                              border:
                                "1px solid " +
                                (profileMode === "exit"
                                  ? theme.sellOrderAreaY
                                  : theme.buyOrderAreaY),
                              filter: "grayScale(0.6)",
                            },
                          }}
                        />
                      </div>
                      <CountUp
                        className="sharpe-sortino-label"
                        end={attempt.atr_multiplier}
                        preserveValue
                        duration={0.5}
                        decimals={1}
                        prefix="ATR * "
                      />
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="order-profiles-column chart-column">
            {orderProfileInput && (
              <>
                <div
                  className="order-profiles-row"
                  style={{
                    paddingTop: 5,
                    paddingBottom: 5,
                    marginRight: 15,
                    gap: 5,
                    justifyContent: "flex-end",
                    maxHeight: 300,
                  }}
                >
                  <input
                    className={"order-profile-name-input " + profileMode}
                    placeholder="Enter case name"
                    value={orderProfileInput.name}
                    onChange={(e) => {
                      setOrderProfileInput({
                        ...orderProfileInput,
                        name: e.target.value,
                      });
                    }}
                  />

                  <CommonIconButton
                    activeTheme={props.activeTheme}
                    label="Save"
                    icon={<MdSaveAlt />}
                    disabled={!updateable}
                    loading={loading}
                    onClick={handleSaveOrderProfiles}
                  />
                </div>
                <OrderProfileChart
                  hideLabel
                  profileMode={profileMode}
                  activeTheme={props.activeTheme}
                  orderProfile={orderProfileInput}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
