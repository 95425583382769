import "./common-strategy-list.scss";
import { TraderType } from "../../../pages/common/TradingDashboard";
import React, {
  lazy,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CommonStrategyListTabButton } from "./CommonStrategyListTabButton";
import { RiUserCommunityFill } from "react-icons/ri";
import { FaChartLine, FaUser } from "react-icons/fa6";
import { NeotonLoader } from "../../custom-loaders/NeotonLoader";
import { getBlueprintService } from "../../../utils/serviceUtil";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { UserStrategyItem } from "../../../interfaces/common/UserStrategies";
import CountUp from "react-countup";
import { GetPapertraderSeasonContext } from "../../../pages/papertrader-page/PapertraderPage";
import { useDebouncedValue } from "@mantine/hooks";
import { IoIosSearch } from "react-icons/io";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";
import { MdClear } from "react-icons/md";
import { Menu } from "@mantine/core";
import { motion } from "framer-motion";
import { IoTime } from "react-icons/io5";
import { GiBearFace, GiBull } from "react-icons/gi";
import { GrAscend, GrDescend } from "react-icons/gr";
import { TbSortAZ } from "react-icons/tb";
import { getTheme } from "../../../utils/themeUtil";
import { MixedMarketIcon } from "../../../style/custom-assets/MixedMarket";
import { NeotonSpinner } from "../../custom-loaders/NeotonSpinner";
import { FeaturedButton } from "../../sidemenu/search-results/StrategyOwnerMenu";

const CommonStrategySeries = lazy(() => import("./CommonStrategySeries"));
interface Props {
  activeTheme: string;
  traderType: TraderType;
  height?: number;
  onVersionSelect?: (strategyId: string, versionId: string) => void;
  compact?: boolean;
  featuredButton?: FeaturedButton;
}

export function CommonStrategyList(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);
  const selectedSeasonKeyCtx = useContext(GetPapertraderSeasonContext);
  const [selectedSeasonKey, setSelectedSeasonKey] = useState<
    string | undefined
  >(selectedSeasonKeyCtx);
  const [selectedTab, setSelectedTab] = useState<StrategySelectionTab>("user");
  const [loading, setLoading] = useState(false);
  const [loadingPapertraderStats, setLoadingPapertraderStats] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (props.traderType.traderType === "papertrader") {
      setSelectedSeasonKey(selectedSeasonKeyCtx);
    }
  }, [props.traderType, selectedSeasonKeyCtx]);

  const [strategyCollapseMap, setStrategyCollapseMap] = useState<{
    [key: string]: boolean;
  }>({});

  const [versionIds, setVersionIds] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 50);
  const [selectedSort, setSelectedSort] = useState<StrategySort>(
    props.traderType.traderType === "papertrader" ? "Performance" : "Latest"
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const [
    strategySeriesVersionSelectionMap,
    setStrategySeriesVersionSelectionMap,
  ] = useState<{ [key: string]: string[] }>({});

  const fetchUserStrategies = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      // delay loading to prevent flickering
      setLoading(true);
      const blueprintService = getBlueprintService(props.traderType);
      const response = await blueprintService.getUserStrategies(token);
      return response.data;
    } finally {
      setLoading(false);
    }
  }, [setLoading, getAccessTokenSilently, props.traderType]);

  const userStrategiesQuery = useQuery(
    ["UserStrategies"],
    fetchUserStrategies,
    {
      keepPreviousData: false,
      cacheTime: 0,
      staleTime: 12000,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      enabled: selectedTab === "user",
      onSuccess(data) {
        if (!data?.payload) return;
        const _allVersionIds: string[] = [];
        Object.keys(data.payload).forEach((strategyId) => {
          data.payload?.[strategyId].forEach((strategy) => {
            _allVersionIds.push(strategy.version_id);
          });
        });
        setVersionIds(_allVersionIds);
        setInitialLoad(true);
      },
    }
  );

  const getSeasons = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const blueprintService = getBlueprintService(props.traderType);
      const response = await blueprintService.getSeasons(token);
      return response.data;
    } catch (error) {}
  }, [getAccessTokenSilently, props.traderType]);

  const seasonsQuery = useQuery(["Seasons"], getSeasons, {
    cacheTime: 0,
    staleTime: 120000,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const fetchPaperwalletStats = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setLoadingPapertraderStats(true);
      // delay loading to prevent flickering
      const blueprintService = getBlueprintService(props.traderType);
      const response = await blueprintService.getStrategyPaperwallets(
        token,
        versionIds,
        !seasonsQuery.data
          ? undefined
          : seasonsQuery.data.current_season === selectedSeasonKey
          ? undefined
          : selectedSeasonKey
      );
      return response.data;
    } finally {
      setLoadingPapertraderStats(false);
    }
  }, [
    getAccessTokenSilently,
    props.traderType,
    versionIds,
    selectedSeasonKey,
    seasonsQuery,
    setLoadingPapertraderStats,
  ]);

  const paperwalletStatsQuery = useQuery(
    ["PaperwalletStats", selectedSeasonKey],
    fetchPaperwalletStats,
    {
      //keepPreviousData: true,
      cacheTime: 60000,
      staleTime: 60000,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: versionIds.length > 0,
    }
  );

  const fetchRatings = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const blueprintService = getBlueprintService(props.traderType);
      const response = await blueprintService.getStrategyRatings(
        token,
        versionIds
      );
      return response.data;
    } finally {
    }
  }, [getAccessTokenSilently, props.traderType, versionIds]);

  const ratingsQuery = useQuery(["Ratings"], fetchRatings, {
    keepPreviousData: true,
    cacheTime: 60000,
    staleTime: 60000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: versionIds.length > 0,
  });

  const fetchLiveUserStrategies = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;

    const blueprintService = getBlueprintService(props.traderType);
    const response = await blueprintService.getLiveStrategies(token);
    return response.data;
  }, [getAccessTokenSilently, props.traderType]);

  const liveStrategiesQuery = useQuery(
    ["LiveStrategies"],
    fetchLiveUserStrategies,
    {
      cacheTime: 0,
      staleTime: 60000,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const strategySeries: StrategySeries[] | undefined = useMemo(() => {
    // 1) Early exit if there's no data
    if (!userStrategiesQuery.data?.payload) return [];

    const _initialLoad = initialLoad;

    // 2) Create lookups for ratings & performance
    const bullRatingsMap: Record<string, number> = {};
    const bearRatingsMap: Record<string, number> = {};
    const mixedRatingsMap: Record<string, number> = {};
    const performanceMap: Record<string, number> = {};

    // Fill bull/bear/mixed rating maps
    if (ratingsQuery.data?.payload) {
      Object.entries(ratingsQuery.data.payload).forEach(
        ([versionId, ratingObj]) => {
          bullRatingsMap[versionId] = ratingObj?.overall_rating?.[99] ?? 0;
          bearRatingsMap[versionId] = ratingObj?.overall_rating?.[-99] ?? 0;
          mixedRatingsMap[versionId] = ratingObj?.overall_rating?.[0] ?? 0;
        }
      );
    }

    // Fill performance map
    if (paperwalletStatsQuery.data?.payload) {
      setInitialLoad(false);
      Object.entries(paperwalletStatsQuery.data.payload).forEach(
        ([versionId, statsObj]) => {
          const snapshots = statsObj?.wallet_value_snapshots?.valueSnapshots;
          if (snapshots && snapshots.length > 0) {
            // for example, the last snapshot in the array
            performanceMap[versionId] = snapshots[snapshots.length - 1] ?? 0;
          } else {
            performanceMap[versionId] = 0;
          }
        }
      );
    }

    // 3) Build up StrategySeries objects
    const versionFiltered = versionIds.includes(debouncedSearchQuery);
    const _series: StrategySeries[] = [];

    Object.keys(userStrategiesQuery.data.payload).forEach((strategyId) => {
      const items = userStrategiesQuery.data?.payload?.[strategyId];
      if (!items || items.length === 0) return;

      // 3a) Filter items by version ID
      const filteredItems = items.filter((item) => {
        if (versionFiltered) {
          return item.version_id === debouncedSearchQuery;
        }
        return true; // otherwise keep all
      });

      if (filteredItems.length === 0) return;

      // 3b) Sort the items themselves by the same logic as the outer sort
      filteredItems.sort((a, b) => {
        let compareVal = 0;
        switch (selectedSort) {
          case "Latest":
            compareVal = a._ts - b._ts;
            break;
          case "Name":
            compareVal = a.name.localeCompare(b.name);
            break;
          case "Bear rating":
            compareVal =
              (bearRatingsMap[a.version_id] ?? 0) -
              (bearRatingsMap[b.version_id] ?? 0);
            break;
          case "Bull rating":
            compareVal =
              (bullRatingsMap[a.version_id] ?? 0) -
              (bullRatingsMap[b.version_id] ?? 0);
            break;
          case "Mixed rating":
            compareVal =
              (mixedRatingsMap[a.version_id] ?? 0) -
              (mixedRatingsMap[b.version_id] ?? 0);
            break;
          case "Performance":
            compareVal =
              (performanceMap[a.version_id] ?? 0) -
              (performanceMap[b.version_id] ?? 0);
            break;
          default:
            compareVal = a._ts - b._ts;
            break;
        }
        return sortDirection === "asc" ? compareVal : -compareVal;
      });

      // 3c) Compute highest rating/performance among the filtered items
      const name = filteredItems[0].name;
      const latestTimestamp = Math.max(...filteredItems.map((it) => it._ts));

      let maxBull = 0;
      let maxBear = 0;
      let maxMixed = 0;
      let maxPerf = 0;

      filteredItems.forEach((item) => {
        maxBull = Math.max(maxBull, bullRatingsMap[item.version_id] || 0);
        maxBear = Math.max(maxBear, bearRatingsMap[item.version_id] || 0);
        maxMixed = Math.max(maxMixed, mixedRatingsMap[item.version_id] || 0);
        maxPerf = Math.max(maxPerf, performanceMap[item.version_id] || 0);
      });

      // 3d) Insert the new StrategySeries
      _series.push({
        name,
        strategyId,
        latestTimestamp,
        strategies: filteredItems,
        // Store highest ratings for the entire series
        bullRatingValue: maxBull,
        bearRatingValue: maxBear,
        mixedRatingValue: maxMixed,
        performanceValue: maxPerf,
      });
    });

    // 4) (Optional) Filter entire series by name if not versionFiltered
    let finalSeries = _series;
    if (debouncedSearchQuery.length > 0 && !versionFiltered) {
      finalSeries = _series.filter((series) =>
        series.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      );
    }

    // 5) Sort the list of StrategySeries
    finalSeries.sort((a, b) => {
      let compareVal = 0;
      switch (selectedSort) {
        case "Latest":
          compareVal = a.latestTimestamp - b.latestTimestamp;
          break;
        case "Name":
          compareVal = a.name.localeCompare(b.name);
          break;
        case "Bear rating":
          compareVal = (a.bearRatingValue ?? 0) - (b.bearRatingValue ?? 0);
          break;
        case "Bull rating":
          compareVal = (a.bullRatingValue ?? 0) - (b.bullRatingValue ?? 0);
          break;
        case "Mixed rating":
          compareVal = (a.mixedRatingValue ?? 0) - (b.mixedRatingValue ?? 0);
          break;
        case "Performance":
          compareVal = (a.performanceValue ?? 0) - (b.performanceValue ?? 0);
          break;
        default:
          compareVal = a.latestTimestamp - b.latestTimestamp;
          break;
      }
      return sortDirection === "asc" ? compareVal : -compareVal;
    });

    // 6) Create/update the collapse map
    const _collapseMap: { [key: string]: boolean } = {};
    finalSeries.forEach((series, idx) => {
      _collapseMap[series.strategyId] = initialLoad ? idx === 0 : false;
    });
    if (finalSeries.length === 1) {
      _collapseMap[finalSeries[0].strategyId] = true;
    }
    setStrategyCollapseMap(_collapseMap);

    return finalSeries;
  }, [
    userStrategiesQuery.data,
    ratingsQuery.data,
    paperwalletStatsQuery.data,
    versionIds,
    debouncedSearchQuery,
    selectedSort,
    sortDirection,
    setStrategyCollapseMap,
  ]);

  const renderEmptyState = useCallback(() => {
    return (
      <div className="common-strategy-list-centered-container">
        {debouncedSearchQuery.length > 0 ? (
          <label className="dimmed-label">No results found</label>
        ) : (
          <label className="dimmed-label">You dont have any strategies</label>
        )}
      </div>
    );
  }, [debouncedSearchQuery]);

  const renderStrategySeriesList = useCallback(() => {
    if (!strategySeries) return null;
    return strategySeries.map((series, index) => {
      const collapsed = strategyCollapseMap[series.strategyId];
      return (
        <CommonStrategySeries
          activeTheme={props.activeTheme}
          series={series}
          collapsed={collapsed}
          setStrategyCollapseMap={setStrategyCollapseMap}
          strategySeriesVersionSelectionMap={strategySeriesVersionSelectionMap}
          setStrategySeriesVersionSelectionMap={
            setStrategySeriesVersionSelectionMap
          }
          key={index}
          paperwalletStats={paperwalletStatsQuery.data?.payload}
          ratings={ratingsQuery.data?.payload}
          refetchStrategies={userStrategiesQuery.refetch}
          onVersionSelect={props.onVersionSelect}
          compact={props.compact}
          liveStrategies={liveStrategiesQuery.data?.payload}
          featuredButton={props.featuredButton}
        />
      );
    });
  }, [
    strategySeries,
    setStrategyCollapseMap,
    strategyCollapseMap,
    strategySeriesVersionSelectionMap,
    setStrategySeriesVersionSelectionMap,
    props.activeTheme,
    ratingsQuery.data,
    paperwalletStatsQuery.data,
    userStrategiesQuery.refetch,
    props.onVersionSelect,
    props.compact,
    liveStrategiesQuery.data,
    props.featuredButton,
  ]);

  const renderSearchAndSortRow = useCallback(() => {
    const sortIcons = {
      Latest: <IoTime />,
      Name: <TbSortAZ />,
      Performance: <FaChartLine />,
      "Bear rating": <GiBearFace color={theme.sellOrderStrokeHover} />,
      "Mixed rating": (
        <MixedMarketIcon size={14} color={theme.mediumWarningColor} />
      ),
      "Bull rating": <GiBull color={theme.buyOrderStrokeHover} />,
    };

    return (
      <div className="common-strategy-list-section-container">
        <CommonIconButton
          activeTheme={props.activeTheme}
          icon={sortDirection === "asc" ? <GrAscend /> : <GrDescend />}
          onClick={() => {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
          }}
        />
        <Menu
          classNames={{
            arrow: "notifications-popover-arrow",
            body: "notifications-popover-body",
            root: "notifications-popover-root",
            label: "custom-menu-item-label",
          }}
          control={
            <motion.div
              initial={{ opacity: 0.25 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="common-button-container compact flat subtle-accent"
              onClick={(e) => e.stopPropagation()}
            >
              <motion.div
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.2 }}
                className="left-icon-container"
              >
                {sortIcons[selectedSort]}
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.2 }}
                className="label-container"
              >
                <label>{selectedSort}</label>
              </motion.div>
            </motion.div>
          }
        >
          <Menu.Label>Sort by</Menu.Label>
          <Menu.Item
            icon={sortIcons.Latest}
            onClick={() => setSelectedSort("Latest")}
          >
            Latest
          </Menu.Item>
          <Menu.Item
            icon={sortIcons.Name}
            onClick={() => setSelectedSort("Name")}
          >
            Name
          </Menu.Item>
          <Menu.Item
            icon={sortIcons.Performance}
            disabled={!paperwalletStatsQuery.data?.payload}
            onClick={() => setSelectedSort("Performance")}
          >
            Performance
          </Menu.Item>
          <Menu.Item
            icon={sortIcons["Bear rating"]}
            disabled={!ratingsQuery.data?.payload}
            onClick={() => setSelectedSort("Bear rating")}
          >
            Bear rating
          </Menu.Item>
          <Menu.Item
            icon={sortIcons["Mixed rating"]}
            disabled={!ratingsQuery.data?.payload}
            onClick={() => setSelectedSort("Mixed rating")}
          >
            Mixed rating
          </Menu.Item>
          <Menu.Item
            icon={sortIcons["Bull rating"]}
            disabled={!ratingsQuery.data?.payload}
            onClick={() => setSelectedSort("Bull rating")}
          >
            Bull rating
          </Menu.Item>
        </Menu>

        <IoIosSearch style={{ marginLeft: "auto" }} />
        <input
          style={{
            minWidth: 250,
          }}
          className="case-name-input"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          placeholder={"Search for name or paste version ID"}
        />
        <CommonIconButton
          activeTheme={props.activeTheme}
          flat
          icon={<MdClear />}
          onClick={() => {
            setSearchQuery("");
          }}
          disabled={searchQuery.length === 0}
        />
      </div>
    );
  }, [
    searchQuery,
    setSearchQuery,
    props.activeTheme,
    selectedSort,
    setSelectedSort,
    ratingsQuery.data,
    paperwalletStatsQuery.data,
    sortDirection,
    setSortDirection,
    theme,
  ]);

  const renderPapertraderSeasonRow = useCallback(() => {
    return (
      <div className="common-strategy-list-section-container">
        <label className="dimmed-label">Papertrader season: </label>
        <label>{selectedSeasonKey}</label>
        {loadingPapertraderStats ? (
          <NeotonSpinner
            style={{ marginLeft: "auto" }}
            activeTheme={props.activeTheme}
          />
        ) : (
          <motion.label
            style={{ marginLeft: "auto" }}
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            transition={{ duration: 2.2 }}
          >
            Fetched {versionIds.length} paperwallet(s)
          </motion.label>
        )}
      </div>
    );
  }, [
    props.activeTheme,
    selectedSeasonKey,
    loadingPapertraderStats,
    versionIds,
  ]);

  return (
    <div
      className="common-strategy-list-container"
      style={{ height: props.height ?? "70vh" }}
    >
      <div className="common-strategy-list-header">
        <CommonStrategyListTabButton
          icon={<FaUser />}
          label="Your strategies"
          tab="user"
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          extraChild={
            strategySeries && strategySeries.length > 0 ? (
              <CountUp
                className="count-up dimmed-label"
                start={0}
                end={strategySeries.length}
                preserveValue
                prefix="("
                suffix=")"
              />
            ) : null
          }
        />
        <CommonStrategyListTabButton
          icon={<RiUserCommunityFill />}
          label="Community strategies"
          tab="community"
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          disabled
        />
      </div>

      {/* Add optional sections here */}
      {renderSearchAndSortRow()}
      {props.traderType.traderType === "papertrader" &&
        renderPapertraderSeasonRow()}

      <div className="common-strategy-list-scrollable-container">
        {loading ? (
          <div className="common-strategy-list-centered-container">
            <NeotonLoader />
          </div>
        ) : (
          <>
            {strategySeries && strategySeries.length === 0
              ? renderEmptyState()
              : renderStrategySeriesList()}
          </>
        )}
      </div>
    </div>
  );
}

export interface StrategySeries {
  name: string;
  strategyId: string;
  latestTimestamp: number;
  strategies: UserStrategyItem[];
  performanceValue?: number;
  bearRatingValue?: number;
  bullRatingValue?: number;
  mixedRatingValue?: number;
}

export type StrategySelectionTab = "user" | "community";
export type StrategySort =
  | "Latest"
  | "Name"
  | "Bear rating"
  | "Bull rating"
  | "Mixed rating"
  | "Performance";
