export const getTaIndicatorDescription = (indicator: string) => {
  switch (indicator) {
    case "AROON":
      return "The Aroon indicator measures the time since the highest high and lowest low over a set period to identify trend strength and potential reversals. High Aroon Up with low Aroon Down suggests an uptrend, while the reverse indicates a downtrend.";
    case "MACD":
      return "The MACD (Moving Average Convergence Divergence) is a momentum indicator that shows the relationship between two moving averages of a price. It consists of the MACD line, signal line, and histogram to identify trend direction, strength, and potential buy or sell signals based on crossovers and divergence.";
    case "BOP":
      return "The Balance of Power (BOP) indicator measures the strength of buyers versus sellers by comparing the close price to the trading range. Values oscillate around zero, where positive values suggest buying pressure and negative values indicate selling pressure, helping to spot potential trend shifts.";
    case "STOCHASTIC":
      return "The Stochastic indicator compares a security's closing price to its price range over a set period to identify overbought and oversold conditions. Values range from 0 to 100, with readings above 80 suggesting overbought conditions and below 20 indicating oversold levels, often signaling potential reversals.";
    case "ULTIMATE_OSCILLATOR":
      return "The Ultimate Oscillator is a momentum indicator that combines short, medium, and long-term price action into a single value to reduce false signals. It ranges from 0 to 100, with values above 70 indicating overbought conditions and below 30 suggesting oversold levels, helping to spot potential reversals.";
    case "RSI":
      return "The Relative Strength Index (RSI) is a momentum oscillator that measures the speed and change of price movements, ranging from 0 to 100. Readings above 70 typically indicate overbought conditions, while readings below 30 suggest oversold conditions, signaling possible trend reversals.";
    case "ATR":
      return "The Average True Range (ATR) is a volatility indicator that measures the average range between high and low prices over a set period. Higher ATR values indicate increased volatility, while lower values suggest a more stable or consolidating market. It doesn't show trend direction, only price movement intensity.";
    case "ROC":
      return "The Rate of Change (ROC) indicator measures the percentage change in price over a set period to identify momentum and potential trend reversals. Positive values suggest upward momentum, while negative values indicate downward momentum, helping to spot potential buy or sell signals.";
    case "EMA":
      return "The Exponential Moving Average (EMA) is a type of moving average that gives more weight to recent prices, making it more responsive to price changes. It helps identify the current trend direction and potential reversals based on crossovers with other moving averages.";
    case "BBAND":
      return "Bollinger Bands (BBAND) are a volatility indicator consisting of a moving average with upper and lower bands set two standard deviations away. Prices near the upper band suggest overbought conditions, while prices near the lower band suggest oversold conditions, helping to identify potential breakouts or reversals.";
    default:
      return undefined;
  }
};
