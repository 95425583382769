import "./strategy-microtester.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { Currency } from "../../interfaces/Currency";
import { PricesOverviewRequestPayload } from "../../pages/common/TradingDashboard";
import {
  MicrotestPayload,
  MicrotestResults,
  StrategyManagerService,
} from "../../services/StrategyManagerService";
import { useCallback, useContext, useMemo, useState } from "react";
import {
  increment5Min,
  incrementDay,
  incrementHour,
} from "../../utils/CandleCountUtil";
import { useQuery } from "react-query";
import TradingCardChart from "../chart-components/interactive-chart/tradingcard-chart/TradingCardChart";
import { CriteriaGenerationPoint } from "./StrategyChartContainer";
import { ApplicationIndicatorsContext } from "../../shared-service-contexts/IndicatorContext";
import { CriteriaPrecisionScore } from "./StrategyHelperModal";
import { getCriteriaDependencies } from "../../utils/ParseCriteriaUtil";
import { motion } from "framer-motion";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { TfiLineDashed } from "react-icons/tfi";
import { getTheme } from "../../utils/themeUtil";

interface Props {
  activeTheme: string;
  currency: Currency;
  candleSize: string;
  entryCriteria?: string;
  exitCriteria?: string;
  criteriaGenerationPoints?: CriteriaGenerationPoint[];
  start: number | undefined;
  stop: number | undefined;
  setEntryCriteriaPrecisionScore?: React.Dispatch<
    React.SetStateAction<CriteriaPrecisionScore | undefined>
  >;
  setExitCriteriaPrecisionScore?: React.Dispatch<
    React.SetStateAction<CriteriaPrecisionScore | undefined>
  >;
}
export function StrategyMicrotester(props: React.PropsWithChildren<Props>) {
  const indicators = useContext(ApplicationIndicatorsContext);
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);

  const microtestDependencies = useMemo(() => {
    if (!indicators) return;
    const concatedCriteria = `${props.entryCriteria} ; ${props.exitCriteria}`;
    return getCriteriaDependencies(concatedCriteria, indicators);
  }, [indicators, props.entryCriteria, props.exitCriteria]);

  const [entryLegendToggle, setEntryLegendToggle] = useState(true);
  const [exitLegendToggle, setExitLegendToggle] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const maxDate = useMemo(() => new Date().getTime(), []);
  const maxCandles = 1800;
  const minDate = useMemo(() => {
    if (props.candleSize === "1D") {
      return maxDate - incrementDay() * maxCandles;
    }
    if (props.candleSize === "1h") {
      return maxDate - incrementHour() * maxCandles;
    }
    return maxDate - increment5Min() * maxCandles;
  }, [maxDate, maxCandles, props.candleSize]);

  const fetchOverviewData = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const _payload: PricesOverviewRequestPayload = {
      start: props.start || minDate,
      stop: props.stop || maxDate,
      currencies: [props.currency.currency_name],
      candle_size: props.candleSize,
    };
    const response = await StrategyManagerService.blueprint.getPricesOverview(
      token,
      _payload
    );
    return response.data;
  }, [
    props.currency,
    props.candleSize,
    getAccessTokenSilently,
    minDate,
    maxDate,
    props.start,
    props.stop,
  ]);

  const priceOverviewQuery = useQuery(
    ["priceOverviewMicrotest", props.candleSize, props.start, props.stop],
    fetchOverviewData,
    {
      cacheTime: 60 * 60 * 1000 * 24,
      staleTime: 60 * 60 * 1000 * 24,
      keepPreviousData: true,
    }
  );

  const artificialCurrencyOverviewOrders = useMemo(() => {
    if (!props.criteriaGenerationPoints) return undefined;
    const _currencyOverviewOrders: any = {};
    for (let i = 0; i < props.criteriaGenerationPoints.length; i++) {
      const point = props.criteriaGenerationPoints[i];
      const side = point.side === "entry" ? "buy" : "sell";
      _currencyOverviewOrders[point.timestamp] = [`mt.${side}_${i}`];
    }
    return _currencyOverviewOrders;
  }, [props.criteriaGenerationPoints]);

  const microtestPayload: MicrotestPayload | undefined = useMemo(() => {
    if (!microtestDependencies) return;
    return {
      currency_slug: props.currency.slug,
      candle_size: props.candleSize,
      start: props.start || minDate,
      stop: props.stop || maxDate,
      microtest_dependencies: microtestDependencies,
      entry_criteria: props.entryCriteria,
      exit_criteria: props.exitCriteria,
    };
  }, [
    microtestDependencies,
    props.currency,
    props.candleSize,
    props.start,
    props.stop,
    props.entryCriteria,
    props.exitCriteria,
  ]);

  const [microtestInProgress, setMicrotestInProgress] = useState(false);

  const runMicrotest = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token || !microtestPayload) return;
    try {
      props.setEntryCriteriaPrecisionScore?.(undefined);
      props.setExitCriteriaPrecisionScore?.(undefined);
      setMicrotestInProgress(true);
      const response = await StrategyManagerService.runMicrotest(
        token,
        microtestPayload
      );
      return response.payload;
    } finally {
      setMicrotestInProgress(false);
    }
  }, [
    microtestPayload,
    getAccessTokenSilently,
    setMicrotestInProgress,
    props.setEntryCriteriaPrecisionScore,
    props.setExitCriteriaPrecisionScore,
  ]);

  const microtestQuery = useQuery(
    ["microtest", microtestPayload],
    runMicrotest,
    {
      cacheTime: 0,
      staleTime: 60 * 60 * 1000 * 24,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: !!microtestPayload,
      onSuccess(data) {
        if (data && props.criteriaGenerationPoints) {
          const entryCriteriaTimestamps = props.criteriaGenerationPoints
            .filter((point) => point.side === "entry")
            .map((point) => point.timestamp);
          const exitCriteriaTimestamps = props.criteriaGenerationPoints
            .filter((point) => point.side === "exit")
            .map((point) => point.timestamp);
          const entryHits = data.entry_timestamps.map((timestamp) =>
            entryCriteriaTimestamps.includes(timestamp)
          );
          const exitHits = data.exit_timestamps.map((timestamp) =>
            exitCriteriaTimestamps.includes(timestamp)
          );
          const entryPrecision =
            entryHits.filter((hit) => hit).length / entryHits.length;
          const exitPrecision =
            exitHits.filter((hit) => hit).length / exitHits.length;
          props.entryCriteria &&
            props.setEntryCriteriaPrecisionScore?.({
              precision: entryPrecision,
              candleSize: props.candleSize,
              criteria: props.entryCriteria,
            });
          props.exitCriteria &&
            props.setExitCriteriaPrecisionScore?.({
              precision: exitPrecision,
              candleSize: props.candleSize,
              criteria: props.exitCriteria,
            });
          //
        }
      },
    }
  );

  const microtestResults: MicrotestResults | undefined = useMemo(() => {
    if (!microtestQuery.data) return;
    return {
      entry_timestamps: entryLegendToggle
        ? microtestQuery.data.entry_timestamps
        : [],
      exit_timestamps: exitLegendToggle
        ? microtestQuery.data.exit_timestamps
        : [],
    };
  }, [microtestQuery.data, entryLegendToggle, exitLegendToggle]);

  return (
    <>
      <div className="legend-row">
        {props.entryCriteria && (
          <CommonButton
            compact
            leftIcon={
              <TfiLineDashed
                color={
                  !entryLegendToggle ? theme.text : theme.buyOrderStrokeHover
                }
                style={{
                  transform: "rotate(90deg)",
                }}
              />
            }
            loading={microtestInProgress}
            borderTheme={!entryLegendToggle ? undefined : "green-accent"}
            activeTheme={props.activeTheme}
            onClick={() => setEntryLegendToggle(!entryLegendToggle)}
            label={
              microtestQuery.data
                ? `Entry-zones (${microtestQuery.data.entry_timestamps.length})`
                : "Entry-zones"
            }          />
        )}
        {props.exitCriteria && (
          <CommonButton
            leftIcon={
              <TfiLineDashed
                color={
                  !exitLegendToggle ? theme.text : theme.sellOrderStrokeHover
                }
                style={{
                  transform: "rotate(90deg)",
                }}
              />
            }
            loading={microtestInProgress}
            compact
            borderTheme={!exitLegendToggle ? undefined : "red-accent"}
            activeTheme={props.activeTheme}
            onClick={() => setExitLegendToggle(!exitLegendToggle)}
            label={
              microtestQuery.data
                ? `Exit-zones (${microtestQuery.data.exit_timestamps.length})`
                : "Exit-zones"
            }
          />
        )}
      </div>
      <div className="microtest-chart-container">
        <motion.div
          className="loading-overlay"
          initial={{ backdropFilter: "blur(0px)" }}
          animate={{
            backdropFilter: microtestInProgress ? "blur(10px)" : "blur(0px)",
            width: microtestInProgress ? "100%" : "0%",
          }}
          exit={{ scale: 0 }}
          transition={{ duration: 0.8 }}
        >
          {microtestInProgress && (
            <label className="dimmed-label">Testing criteria...</label>
          )}
        </motion.div>
        {priceOverviewQuery.data && (
          <TradingCardChart
            activeTheme={props.activeTheme}
            priceData={priceOverviewQuery.data?.currencyPrices[0]}
            candleSize={props.candleSize}
            currencyOverviewOrders={artificialCurrencyOverviewOrders}
            currentOverviewTimestamp={undefined}
            filteredOrders={undefined}
            microtester
            microtestResults={microtestResults}
            showFirstAndLastDate
          />
        )}
      </div>
    </>
  );
}
