import { IChartApi, LineSeries, Time } from "lightweight-charts";
import { IOHLCData } from "../../financial-chart/iOHLCData";
import { EMA } from "../../../../interfaces/tw-chart/TWChartSettings";
import { ema } from "indicatorts";
import { CustomChartAddon } from "../TWChart";

export function addEMASeries(
  chart: IChartApi,
  data: IOHLCData[],
  indicatorConfig: EMA,
  theme: any = null,
  paneIdx: number = 0
): CustomChartAddon {
  const EMASeries = chart.addSeries(
    LineSeries,
    {
      priceScaleId: "right", // Overlay on the main chart
    },
    paneIdx
  );

  const emaData = ema(
    data.map((item) => item.close),
    indicatorConfig.config
  );

  EMASeries.setData(
    emaData.map((item, index) => ({
      time: (data[index].ts / 1000) as Time,
      value: item,
    }))
  );

  EMASeries.applyOptions({
    color: indicatorConfig.color,
    lineWidth: 1,
    priceLineVisible: false,
  });

  const label = `EMA${indicatorConfig.config.period}`;

  return {
    chartData: {
      [label]: EMASeries,
    },
    colorMap: {
      [label]: indicatorConfig.color,
    },
  };
}
