import {
  AROON,
  ATR,
  BBAND,
  BOP,
  EMA,
  MACD,
  ROC,
  RSI,
  STOCHASTIC,
  TAIndicator,
  TWPaneSettings,
} from "../interfaces/tw-chart/TWChartSettings";

export function getRandomColors(n: number): string[] {
  // Each color is a random 6-digit hex string (0x000000 to 0xFFFFFF)
  // Convert to string(16) and pad with zeros to ensure 6 digits.
  const colors: string[] = [];
  for (let i = 0; i < n; i++) {
    const randomHex = Math.floor(Math.random() * 0xffffff);
    const color = `#${randomHex.toString(16).padStart(6, "0")}`;
    colors.push(color);
  }
  return colors;
}

const DefaultAroon: AROON = {
  id: "",
  type: "AROON",
  config: { period: 14 },
  up: "#00ff00",
  down: "#ff0000",
  separatePane: true,
};

const DefaultATR: ATR = {
  id: "",
  type: "ATR",
  config: { period: 14 },
  color: "#eb3b5a",
  /* atrLine: "#cccccc",
  trLine: "#cccccc", */
  separatePane: true,
};

const DefaultBBAND: BBAND = {
  id: "",
  type: "BBAND",
  config: { period: 20 }, // add more config as needed
  upper: "#fc5c65",
  middle: "#fd9644",
  lower: "#fed330",
  separatePane: false,
};

const DefaultBOP: BOP = {
  id: "",
  type: "BOP",
  config: {}, // no built-in config, so empty
  color: "#eb3b5a",
  separatePane: true,
};

const DefaultEMA: EMA = {
  id: "",
  type: "EMA",
  config: { period: 20 },
  color: "#fa8231",
  separatePane: false,
};

const DefaultMACD: MACD = {
  id: "",
  type: "MACD",
  config: { fast: 12, slow: 26, signal: 9 },
  macdLine: "#20bf6b",
  signalLine: "#0fb9b1",
  separatePane: true,
};

const DefaultRSI: RSI = {
  id: "",
  type: "RSI",
  config: { period: 14 },
  color: "#8854d0",
  separatePane: true,
};

const DefaultROC: ROC = {
  id: "",
  type: "ROC",
  config: { period: 10 },
  color: "#8854d0",
  separatePane: true,
};

const DefaultStochasticOscillator: STOCHASTIC = {
  id: "",
  type: "STOCHASTIC",
  config: { kPeriod: 5, dPeriod: 3 },
  kLine: "#eb3b5a",
  dLine: "#00ff00",
  separatePane: true,
};
/*
 * Master record of default indicators, keyed by "type".
 * We'll use this in our getDefaultTAIndicator function.
 */
const DEFAULT_INDICATOR_MAP = {
  AROON: DefaultAroon,
  ATR: DefaultATR,
  BBAND: DefaultBBAND,
  BOP: DefaultBOP,
  EMA: DefaultEMA,
  MACD: DefaultMACD,
  RSI: DefaultRSI,
  ROC: DefaultROC,
  STOCHASTIC: DefaultStochasticOscillator,
} as const;

export function getDefaultTAIndicator<T extends TAIndicator["type"]>(
  type: T
): Extract<TAIndicator, { type: T }> {
  // 1) Get your base object
  const base = DEFAULT_INDICATOR_MAP[type] as unknown as Extract<
    TAIndicator,
    { type: T }
  >;

  // 2) Spread + override fields as needed
  return {
    ...base,
    id: `${type}-${Math.random().toString(36).substring(2, 9)}`,
  };
}

export const getDefaultTAIndicators = (
  taIndicatorType: TAIndicator["type"]
) => {
  if (taIndicatorType === "EMA") {
    const periods = [20, 50, 100, 200];
    const defaultEmaIndicators = periods.map((period) => {
      const indicator = getDefaultTAIndicator("EMA") as EMA;
      indicator.config = { period: period };
      indicator.color = getRandomColors(1)[0];
      indicator.id = `${taIndicatorType}-default${period}`;
      return indicator;
    });
    return defaultEmaIndicators;
  }
  const defaultIndicator = getDefaultTAIndicator(taIndicatorType);
  defaultIndicator.id = `${taIndicatorType}-default`;
  return [defaultIndicator];
};

export const flattenPaneOrder = (paneSettings: TWPaneSettings) => {
  // adjust the orders to so there are no gaps in the order
  const panes = Object.values(paneSettings);
  panes.sort((a, b) => a.order - b.order);
  panes.forEach((pane, index) => {
    pane.order = index;
  });
  return paneSettings;
};

export const ensurePaneHeightFactors = (paneSettings: TWPaneSettings) => {
  // ensure that all pane height factors are set and their sum is 100
  // always respect the height factor of the non-main panes
  // and adjust the main pane height factor to make the sum 100
  const panes = Object.values(paneSettings);
  const mainPane = panes.find((pane) => pane.id === "main");
  if (!mainPane) {
    return paneSettings;
  }
  const totalHeightFactor = panes.reduce((acc, pane) => {
    return acc + pane.heightFactor;
  }, 0);
  const heightFactorDiff = 100 - totalHeightFactor;
  mainPane.heightFactor += heightFactorDiff;
  return paneSettings;
};

export const ensurePaneSettingsCorrectness = (paneSettings: TWPaneSettings) => {
  return ensurePaneHeightFactors(flattenPaneOrder(paneSettings));
};

export const getDefaultHeightForIndicator = (indicator: TAIndicator) => {
  switch (indicator.type) {
    default:
      return 30;
  }
};

export const getTaIndicatorLongName = (indicator: string) => {
  switch (indicator) {
    case "ATR":
      return "Average True Range";
    case "ROC":
      return "Rate of Change";
    case "STOCHASTIC":
      return "Stochastic Oscillator";
    case "ULTIMATE_OSCILLATOR":
      return "Ultimate Oscillator";
    case "BOP":
      return "Balance of Power";
    case "AROON":
      return "Aroon";
    case "RSI":
      return "Relative Strength Index";
    case "MACD":
      return "Moving Average Convergence Divergence";
    case "EMA":
      return "Exponential Moving Average";
    case "SMA":
      return "Simple Moving Average";
    case "BBAND":
      return "Bollinger Bands";
    default:
      return indicator;
  }
};
