import "./indicator-control-button.scss";
import { TAIndicator } from "../../../interfaces/tw-chart/TWChartSettings";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";
import { FiX } from "react-icons/fi";

interface Props {
  activeTheme: string;
  indicator?: TAIndicator;
  indicatorLabel?: string;
  leftChild?: React.ReactNode;
  rightChild?: React.ReactNode;
  handleRemoveIndicator?: () => void;
  handleSelect?: () => void;
  disabled?: boolean;
  tier?: string;
}
export function IndicatorControlButton(props: React.PropsWithChildren<Props>) {
  const tier = props.tier || "ta";
  const label = props.indicator?.type || props.indicatorLabel;
  const labelSuffix = props.indicator?.config?.["period"]
    ? ` (${props.indicator.config?.["period"]})`
    : "";

  return (
    <div
      className={
        "indicator-control-button " + tier + (props.disabled ? " disabled" : "")
      }
      style={{
        borderColor: props.indicator?.["color"],
        paddingRight: props.handleRemoveIndicator ? 0 : 4,
      }}
      onClick={props.handleSelect}
    >
      {props.leftChild}
      <label>
        {label}
        {labelSuffix}
      </label>
      {props.handleRemoveIndicator && (
        <CommonIconButton
          icon={<FiX />}
          onClick={props.handleRemoveIndicator}
          activeTheme={props.activeTheme}
          flat
        />
      )}
      {props.rightChild}
    </div>
  );
}
