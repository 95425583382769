import React, { Component, ErrorInfo, ReactNode } from "react";
import { TbFaceIdError } from "react-icons/tb";

interface Props {
  children: ReactNode;
  tryReload?: boolean;
  onExceptionCallback?: () => void;
}

interface State {
  hasError: boolean;
  reloadKey: number;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    reloadKey: 0,
  };

  // Use a class property arrow function to capture `this`
  public componentDidCatch = async (error: Error, errorInfo: ErrorInfo) => {
    // Dynamically import AppInsightsContext
    // Use App Insights to track the exception
    const appInsights = this.context as any;

    appInsights?.trackException({ exception: error });
    if (this.props.tryReload) {
      this.setState({ reloadKey: this.state.reloadKey + 1 });
    }
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true } as State;
  }

  public componentDidMount(): void {
    if (this.props.onExceptionCallback && this.state.hasError) {
      this.props.onExceptionCallback && this.props.onExceptionCallback();
    }
  }

  public render() {
    if (this.state.hasError && !this.props.tryReload) {
      return (
        <div className="error-box">
          <TbFaceIdError size={50} />{" "}
          <label>It appears that an error has occurred...</label>
          <br />
          <label
            className="reload-page-label"
            onClick={() => {
              window.location.reload();
            }}
          >
            Click here to reload the page
          </label>
        </div>
      );
    }

    return <div key={this.state.reloadKey}>{this.props.children}</div>;
  }
}

export default ErrorBoundary;
