import { IoIosSettings } from "react-icons/io";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";
import { TWLegendItem } from "./TWChart";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { LiaTrashAlt } from "react-icons/lia";

interface Props {
  activeTheme: string;
  legendItems: TWLegendItem[];
  onSettingsClick?: () => void;
  onDeleteClick?: () => void;
  onPaneChange?: (direction: "up" | "down") => void;
}
export function LegendItem(props: React.PropsWithChildren<Props>) {
  const firstChild = props.legendItems[0];
  return (
    <div className="tw-legend-item">
      {props.legendItems.map((item, idx) => {
        return (
          <div key={idx} className="legend-item">
            <label>{item.label}</label>
            <label
              className="dimmed-label"
              style={{ color: item?.colorMap?.[item.label] ?? item.color }}
            >
              {item.value}
            </label>
          </div>
        );
      })}

      {firstChild.configurable && (
        <div className="reveal-settings">
          <CommonIconButton
            style={{
              maxHeight: 10,
              maxWidth: 10,
              fontSize: "smaller",
            }}
            onClick={props.onSettingsClick}
            icon={<IoIosSettings />}
            activeTheme={props.activeTheme}
            flat
          />
        </div>
      )}
      {props.onDeleteClick && firstChild.configurable && (
        <div className="reveal-settings">
          <CommonIconButton
            style={{
              maxHeight: 10,
              maxWidth: 10,
              fontSize: "smaller",
            }}
            onClick={props.onDeleteClick}
            icon={<LiaTrashAlt />}
            activeTheme={props.activeTheme}
            flat
          />
        </div>
      )}

      {firstChild.separatePane && props.onPaneChange && (
        <div
          className="reveal-settings scale"
          style={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          <CommonIconButton
            style={{
              maxHeight: 10,
              maxWidth: 10,
              fontSize: "smaller",
            }}
            onClick={() => props.onPaneChange?.("up")}
            icon={<FiArrowUp />}
            activeTheme={props.activeTheme}
            flat
          />
          <CommonIconButton
            style={{
              maxHeight: 10,
              maxWidth: 10,
              fontSize: "smaller",
            }}
            onClick={() => props.onPaneChange?.("down")}
            icon={<FiArrowDown />}
            activeTheme={props.activeTheme}
            flat
          />
        </div>
      )}
    </div>
  );
}
