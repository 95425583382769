import { motion } from "framer-motion";
import { HoldingItem } from "../../../interfaces/backtester/BacktestTradingReport";
import { memo, useContext, useMemo, useRef, useState } from "react";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { Avatar } from "@mantine/core";
import { usePalette } from "react-palette";
import { getTheme } from "../../../utils/themeUtil";

import { PoolFunds } from "../../../interfaces/backtester/WalletFunds";
import {
  BaseCurrencyType,
  getBaseCurrencyUrl,
  getCurrencyUrl,
} from "../../../utils/cdnUtil";
import {
  FocusedOrderEntryContext,
  SetFocusedOrderEntryContext,
} from "../../../pages/common/TradingDashboard";

interface Props {
  activeTheme: string;
  pctWidth: number;
  holdingItem?: HoldingItem;
  poolValue?: number;
  poolFunds?: PoolFunds;
  label?: string;
  baseCurrency?: BaseCurrencyType;
}

const CommonMarketCapPoolHeaderProgress = memo(function CommonTradingCard(
  props: React.PropsWithChildren<Props>
) {
  const theme = getTheme(props.activeTheme);
  const containerRef = useRef<HTMLDivElement>(null); // Create a ref
  const setFocusedOrderEntry = useContext(SetFocusedOrderEntryContext);
  const focusedOrderEntry = useContext(FocusedOrderEntryContext);
  const currencies = useContext(CurrencyContext);
  const currency = props.holdingItem
    ? currencies?.find(
        (currency) =>
          currency.currency_name === props.holdingItem?.currency_name
      )
    : undefined;

  const currencyUrl = useMemo(() => {
    if (props.baseCurrency) return getBaseCurrencyUrl(props.baseCurrency);
    if (currency) return getCurrencyUrl(currency);
  }, [currency, props.baseCurrency]);

  const logoSize = 22;

  const { data } = usePalette(currencyUrl ?? "/assets/neoton/neotonLogo.png");

  const fillColor = useMemo(() => {
    if (data && currencyUrl) return data;
  }, [data, currencyUrl]);

  const [renderChildren, setRenderChildren] = useState(false);

  return (
    <motion.div
      ref={containerRef}
      initial={{ borderColor: "transparent" }}
      animate={{
        maxWidth: `${props.pctWidth}%`,
        width: `${props.pctWidth-1}%`,
        minWidth: "fit-content",
        border: props.children
          ? props.holdingItem &&
            focusedOrderEntry &&
            focusedOrderEntry.orderIds.includes(props.holdingItem?.id ?? "")
            ? `2px solid ${fillColor?.vibrant}`
            : `2px solid ${theme.cardInteriorAlt}`
          : "2px solid transparent",
        filter: `contrast(${props.children ? 1 : 0.5})`,
        borderRadius: "14px",
      }}
      onAnimationComplete={() => {
        setRenderChildren(true);
      }}
      whileHover={{
        border: props.children
          ? `2px solid ${fillColor?.vibrant}`
          : "2px solid transparent",
      }}
      className="common-market-cap-pool-progress"
      onClick={() => {
        if (!props.holdingItem) return;
        setFocusedOrderEntry({
          currencyName: props.holdingItem?.currency_name,
          orderIds: [props.holdingItem?.id ?? ""],
        });
      }}
    >
      {currencyUrl && <Avatar src={currencyUrl} size={logoSize} radius="xl" />}
      {renderChildren && (
        <motion.div
          initial={{
            borderColor: "transparent",
            opacity: 0,
            scale: 0,
            borderRadius: "14px",
          }}
          animate={{
            border: "0px solid transparent",
            height: "100%",
            borderRadius: "14px",
            opacity: 0.8,
            scale: 1,
          }}
          style={{
            paddingLeft: props.children ? "5px" : "0px",
            paddingRight: props.children ? "5px" : "0px",
          }}
          transition={{ duration: 0.4, delay: 0.2 }}
          className={
            "common-market-cap-pool-progress-filler" +
            (!currencyUrl ? " base-currency" : "")
          }
        >
          {props.children}
        </motion.div>
      )}
    </motion.div>
  );
});

export default CommonMarketCapPoolHeaderProgress;
