export interface StrategyVersion {
  version_id: string;
  strategy_id: string;
  name: string;
  body: Body;
  body_hash: string;
  compatability: string;
  created_on: number;
  dependency_hash: string;
  enable_simulation: boolean;
  enabled_since?: number;
  header: Header;
  header_hash: string;
  included_currencies: string[];
  min_permission: string;
  shared: boolean;
  tags: string[];
  tier: string;
}

export interface Header {
  currencies: string[];
  currency_pair: string;
  dependency_hash: string;
  mc_distribution: MCDistribution;
  min_permission: string;
  price_columns: string[];
  price_head: string;
  ta_indicators: string[];
  ai_indicators?: string[];
  pool_composition_type?: string; // 'fixed' | 'dynamic';
  fixed_pool_composition?: FixedPoolComposition;
  rank_distribution: MarketCapRankThreshold;
}

export interface MCDistribution {
  high: number;
  low: number;
  medium: number;
}

export interface MarketCapRankThreshold {
  high: number;
  medium: number;
}

export interface FixedPoolComposition {
  high: string[];
  medium: string[];
  low: string[];
}

export interface StrategyBody {
  body: Body;
  body_checksum: string;
}

export interface Body {
  cases: Case[];
  dependency_hash: string;
}

export interface EntryCriteria {
  candle_size_1D: string[];
  candle_size_1h: string[];
  candle_size_5m: string[];
}

export interface ExitCriteria {
  candle_size_1D: string[];
  candle_size_1h: string[];
  candle_size_5m: string[];
}

export interface Case {
  daily_frequency_limit: number | null;
  entry_criteria?: EntryCriteria;
  exit_criteria?: ExitCriteria;
  include_all: boolean;
  included_currencies: string[];
  name: string;
  weight: number;
  position_type: string;
  rank_specific?: string;
  stop_profit?: number; // rename to take_profit
  stop_profit_candle_size?: string; // rename to take_profit_candle_size
  stoploss?: number;
  stoploss_candle_size?: string;
  disabled?: boolean;
  trailing_stoploss?: boolean;
  stoploss_type?: StoplossType;
  take_profit_type?: TakeProfitType;
  atr_take_profit_multiplier?: number;
  atr_stoploss_multiplier?: number;
  editable?: boolean;
  isAICase?: boolean;
  constraints?: orderOptions;
  buy_order_profile?: OrderProfile;
  tp_order_profile?: OrderProfile;
  sl_order_profile?: OrderProfile;
  sell_order_profile?: OrderProfile;
}

export interface OrderProfile {
  name: string;
  attempts: number;
  attempt_1: Attempt;
  attempt_2: Attempt;
  attempt_3: Attempt;
  attempt_4: Attempt;
  attempt_5: Attempt;
}
export interface Attempt {
  field: string;
  atr_multiplier: number;
}

export interface orderOptions {
  sell: SellConstraints;
}

export interface SellConstraints {
  min_holding_period: number;
}

export type StoplossType =
  | "disabled"
  | "static"
  | "trailing"
  | "atr"
  | "trailing_atr";
export type TakeProfitType = "disabled" | "static" | "atr";

export interface OrderProfileAttempt {
  field: string;
  atr_multiplier: number;
}

export interface OrderProfile {
  name: string;
  attempts: number;
  attempt_1: OrderProfileAttempt;
  attempt_2: OrderProfileAttempt;
  attempt_3: OrderProfileAttempt;
  attempt_4: OrderProfileAttempt;
  attempt_5: OrderProfileAttempt;
}

export const DefaultBuyOrderProfile = {
  name: "default_buy",
  attempts: 5,
  attempt_1: { field: "bid", atr_multiplier: 0 },
  attempt_2: { field: "bid", atr_multiplier: 0 },
  attempt_3: { field: "bid", atr_multiplier: 0 },
  attempt_4: { field: "bid", atr_multiplier: 0 },
  attempt_5: { field: "ask", atr_multiplier: 0 },
};
export const DefaultOptimisticBuyOrderProfile = {
  name: "optimistic_buy",
  attempts: 5,
  attempt_1: { field: "bid", atr_multiplier: 2 },
  attempt_2: { field: "bid", atr_multiplier: 1.5 },
  attempt_3: { field: "bid", atr_multiplier: 1 },
  attempt_4: { field: "bid", atr_multiplier: 0.5 },
  attempt_5: { field: "ask", atr_multiplier: 0 },
};

export const DefaultSellOrderProfile = {
  name: "default_sell",
  attempts: 5,
  attempt_1: { field: "ask", atr_multiplier: 0 },
  attempt_2: { field: "ask", atr_multiplier: 0 },
  attempt_3: { field: "ask", atr_multiplier: 0 },
  attempt_4: { field: "ask", atr_multiplier: 0 },
  attempt_5: { field: "bid", atr_multiplier: 0 },
};

export const DefaultOptimisticSellOrderProfile = {
  name: "optimistic_sell",
  attempts: 5,
  attempt_1: { field: "ask", atr_multiplier: 2 },
  attempt_2: { field: "ask", atr_multiplier: 1.5 },
  attempt_3: { field: "ask", atr_multiplier: 1 },
  attempt_4: { field: "ask", atr_multiplier: 0.5 },
  attempt_5: { field: "bid", atr_multiplier: 0 },
};
