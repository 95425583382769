import React, { JSX } from "react";
import { Navigate } from "react-router-dom";
import { UserPermissions } from "../../interfaces/UserPermissions";
import { NeotonLoader } from "../custom-loaders/NeotonLoader";
import { LoadingPage } from "../../pages/loading-page/LoadingPage";

interface Props {
  children: JSX.Element;
  isAuthenticated: boolean;
  isLoading: boolean; // Added isLoading prop
  requiredUserPermission: string;
  userPermissions: UserPermissions;
}

export const ProtectedRoute: React.FC<Props> = ({
  children,
  isAuthenticated,
  isLoading,
  requiredUserPermission,
  userPermissions,
}) => {
  // Render loading screen if auth is still loading
  if (isLoading) {
    return <LoadingPage />; // Replace with your loading component if needed
  }

  // Redirect if not authenticated or permission is missing
  if (
    !isAuthenticated ||
    !userPermissions.includedPermissions.includes(requiredUserPermission)
  ) {
    return <Navigate to="/" replace />;
  }

  // Render children if authenticated and permission is present
  return children;
};
